import { SetIsAdminToolACReturnType } from './is-admin-tool.actions';
import { SET_IS_ADMIN_TOOL } from './is-admin-tool.types';

export const isAdminToolReducer = (state = false, action: SetIsAdminToolACReturnType): boolean => {
  switch (action.type) {
    case SET_IS_ADMIN_TOOL:
      return action.payload;
    default:
      return state;
  }
};
