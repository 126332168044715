import { Reducer } from 'redux';
import { GET_WORKFLOWS, State } from './workflow.types';

const initialState: State = {
  workFlows: []
};

export const workFlowReducer: Reducer<any, any> = (state: any = initialState, action: any) => {
  switch (action.type) {
    case GET_WORKFLOWS: {
      return { ...state, workFlows: action.data };
    }

    default:
      return state;
  }
};
