import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { generatePath, useHistory } from 'react-router-dom';
import { toastScanFailed } from '../../utils/toast.utils';
import { ROUTES } from '../../constants';
import { FileScanWSMessageDto } from '../../models/models.dl';
import { selectCurrentUser } from '../../store/selectors';
import { selectIsAdminTool } from '../../store/is-admin-tool';
import { moduleUIDSearchParamKey } from './moduleFileScanListener';
import { fileIdSearchParamKey } from './IpoScannerSettingsFileScanListener';
import AuthorizationService from '../../services/bl/authorization.service';
import SignalRService from '../../services/bl/signalr.service';

type History = ReturnType<typeof useHistory>;

const goTo = ({
  newPath,
  searchParam,
  history
}: {
  newPath: string;
  searchParam: Record<'key' | 'value', string>;
  history: History;
  state?: Record<string, any>;
}) => {
  const currentPath = history.location.pathname;
  if (currentPath !== newPath) {
    const newPathWithId = `${newPath}?${searchParam.key}=${searchParam.value}`;
    const isAdminTool = localStorage.getItem('fromAdminAccess') === 'true';
    if (isAdminTool) {
      history.push(newPathWithId);
    } else {
      window.open(newPathWithId, '_self');
    }
  } else {
    const searchParams = new URLSearchParams(history.location.search);
    searchParams.set(searchParam.key, searchParam.value);
    history.replace({ search: searchParams.toString() });
  }
};

const goToScanFailedModule = (msg: FileScanWSMessageDto, history: History) => {
  if (!msg) return;
  const newPath = generatePath(ROUTES.casetypeDetails, { caseId: `${msg.CaseTypeId}` });
  goTo({ newPath, searchParam: { key: moduleUIDSearchParamKey, value: msg.ModuleUId }, history });
};

const goToScanFailedFiles = (msg: FileScanWSMessageDto, history: History) => {
  if (!msg) return;
  const newPath = ROUTES.SelfAssessmentAssets;
  goTo({
    newPath,
    searchParam: { key: fileIdSearchParamKey, value: `${msg.FileMetadataId}` },
    history
  });
};

export const useGlobalFileScanListener = () => {
  const history = useHistory();
  const { user } = useSelector(selectCurrentUser);
  const isAdminTool = useSelector(selectIsAdminTool);
  const isAuthorized = AuthorizationService.isAuthorized(user);
  const isAdmin = AuthorizationService.isAdmin(user);
  const canListenFileScan = isAuthorized && isAdmin && isAdminTool;

  useEffect(() => {
    let connection: ReturnType<typeof SignalRService.getConnection>;

    if (canListenFileScan) {
      connection = SignalRService.getConnection({
        url: 'fileScan'
      });

      // INFO: Just for toaster testing
      /* toastScanFailed(FileScanStatus.SERVICE_UNAVAILABLE, () => {
        const mocMsg: FileScanWSMessageDto = {
          CaseTypeId: 49, // set real id
          ModuleUId: 'fe6c77ca-ac79-44fc-8086-7a30c855dc43', // set real uid from mocMsg.CaseTypeId caseType
          FileMetadataId: 2,
          FileName: 'asd.pdf',
          ModuleType: ModuleType.TEMPLATE,
          ScanStatus: FileScanStatus.SERVICE_UNAVAILABLE
        };
        goToScanFailedModule(mocMsg, history);
      }); */
      // INFO: Just for contact photo toaster testing
      /* toastScanFailed(FileScanStatus.SERVICE_UNAVAILABLE, () => {
        goToScanFailedContactPhoto(
          {
            FileMetadataId: 628, // set real id
            FileName: '',
            ScanStatus: FileScanStatus.SERVICE_UNAVAILABLE
          },
          history
        );
      }); */
      //

      const start = async () => {
        try {
          await connection.start();
          connection.on('GetNotificationMessage', (msg: FileScanWSMessageDto) => {
            toastScanFailed(msg.ScanStatus, () => {
              goToScanFailedModule(msg, history);
            });
          });
          connection.on('GetIpoFileNotificationMessage', (msg: FileScanWSMessageDto) => {
            toastScanFailed(msg.ScanStatus, () => {
              goToScanFailedFiles(msg, history);
            });
          });
          await connection.invoke('Subscribe').catch(() => {});
        } catch (err) {
          console.error('Connection failed: ', err);
        }
      };
      start();
    }

    return () => {
      const stop = async () => {
        if (connection) {
          try {
            await connection.stop();
          } catch (err) {
            console.error('Disconnection failed: ', err);
          }
        }
      };
      stop();
    };
  }, [history, canListenFileScan]);

  return {
    me: user,
    history,
    isAuthorized
  };
};
