import { StoreName } from '../store';
import currentUserReducer from './current-user.reducer';
import loaderReducer from './loader.reducer';
import lookupsReducer from './lookup.reducer';
import languageLookupsReducer from './language-lookup.reducer';
import redirectToReducer from './redirect-to.reducer';
import { isAdminToolReducer } from '../is-admin-tool';
import activeLanguageReducer from './current-language.reducer';
import languageUnavailReducer from './language-unavailable.reducer';
import emailSentReducer from './mail-sent.reducer';
import { selfAssessmentAssetsReducer } from '../self-assessment-assets/self-assessment-assets.reducer';

export const reducers = {
  [StoreName.CurrentUser]: currentUserReducer,
  [StoreName.Loader]: loaderReducer,
  [StoreName.Lookups]: lookupsReducer,
  [StoreName.RedirectTo]: redirectToReducer,
  [StoreName.IsAdminTool]: isAdminToolReducer,
  [StoreName.AllLanguages]: languageLookupsReducer,
  [StoreName.CurrentLanguage]: activeLanguageReducer,
  [StoreName.NoLanguage]: languageUnavailReducer,
  [StoreName.MailSent]: emailSentReducer,
  [StoreName.SelfAssessmentAssets]: selfAssessmentAssetsReducer
};
