import { emailValidator, phoneValidator } from '../../../helpers/validators.helper';
import { SelfAssesFieldTypeE } from '../../enums';
import {
  GeneralInformationGroupGetDTO,
  GeneralInformationSectionGetDTO,
  QuestionnaireQuestionAnswerDTO,
  SaveQuestionnaireAnswersDTO
} from '../../interfaces';
import {
  CustomValidationErrorKey,
  GeneralInfoConfigStateI,
  SectionSelectorData,
  SelectAnswerActionI
} from './general-info-config.interface';

export const getCustomValidationErrorKey = ({
  stepIdx,
  groupIdx,
  sectionIdx
}: SectionSelectorData): CustomValidationErrorKey => `${stepIdx}.${groupIdx}.${sectionIdx}`;

/**
 * Used to avoid error text overflowing
 */
export const getCustomValidationErrorFieldLabel = (groupTitle: string) => (groupTitle.length > 20 ? '' : groupTitle);

export const checkIsAnswerSelected = ({
  questionType,
  answer
}: {
  questionType: SelfAssesFieldTypeE;
  answer: QuestionnaireQuestionAnswerDTO;
}) => {
  switch (questionType) {
    case SelfAssesFieldTypeE.Input:
    case SelfAssesFieldTypeE.TextArea:
    case SelfAssesFieldTypeE.DatePicker: {
      return !!answer.TextBoxValue?.trim();
    }
    case SelfAssesFieldTypeE.Radio:
    case SelfAssesFieldTypeE.SingleSelect: {
      return answer.IsCheckBoxChecked;
    }
    case SelfAssesFieldTypeE.Checkbox: {
      return answer.IsSelected;
    }
    default: {
      return false;
    }
  }
};

export const checkSectionIsAnswered = (section: GeneralInformationSectionGetDTO) =>
  section.Questions.some((answer) => checkIsAnswerSelected({ questionType: section.QuestionType, answer }));

const getQuestionAnswer = ({
  group,
  answer
}: {
  group: GeneralInformationGroupGetDTO;
  answer: QuestionnaireQuestionAnswerDTO;
}): SaveQuestionnaireAnswersDTO['questionAnswers'][number] => ({
  questionUID: group.QuestionId,
  answerOptionUID: answer.AnswerId
});

export const prepareConfigForSave = (data: GeneralInfoConfigStateI['data']): SaveQuestionnaireAnswersDTO | null => {
  if (!data.length) {
    return null;
  }

  return {
    caseUID: data[0].CaseUID,
    questionnaireUId: data[0].QuestionaireId,
    questionAnswers: data.reduce<SaveQuestionnaireAnswersDTO['questionAnswers']>((acc, questionnaire) => {
      questionnaire.Question.QuestionGroups.forEach((group) => {
        group.QuestionSections.forEach((section) => {
          section.Questions.forEach((answer) => {
            if (checkIsAnswerSelected({ questionType: section.QuestionType, answer })) {
              acc.push(getQuestionAnswer({ group, answer }));
            }
          });
        });
      });
      return acc;
    }, []),
    data: data as SaveQuestionnaireAnswersDTO['data'],
    // INFO: BE not uses it
    moduleUID: '00000000-0000-0000-0000-000000000000',
    questionnaireStepUID: '00000000-0000-0000-0000-000000000000',
    stepNumber: 0,
    nextStepNumber: 0
    //
  };
};

export const getCustomFieldErrors = ({
  prevErrors,
  value = '',
  fieldTitle = '',
  payload
}: {
  prevErrors: GeneralInfoConfigStateI['customValidationErrors'];
  value: string | undefined;
  fieldTitle: string | undefined;
  payload: SelectAnswerActionI['payload'];
}): GeneralInfoConfigStateI['customValidationErrors'] => {
  const valueToValidate = value.trim();
  const title = fieldTitle.trim();
  const isEmail = /^email$/i.test(title.trim());
  const isPhone = !isEmail && /^(phone|telephone)$/i.test(title.trim());

  let newErrors = prevErrors;

  if (isEmail || isPhone) {
    const errorKey = getCustomValidationErrorKey(payload);
    if (valueToValidate) {
      const validator = isEmail ? emailValidator : phoneValidator;
      const error = validator({ value: valueToValidate, label: getCustomValidationErrorFieldLabel(title) });
      const errorKey = getCustomValidationErrorKey(payload);
      if (error) {
        newErrors = newErrors[errorKey] !== error ? { ...newErrors, [errorKey]: error } : newErrors;
      } else if (newErrors[errorKey]) {
        newErrors = { ...newErrors };
        delete newErrors[errorKey];
      }
    } else if (newErrors[errorKey]) {
      newErrors = { ...newErrors };
      delete newErrors[errorKey];
    }
  }

  return newErrors;
};
