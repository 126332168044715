import axios, { AxiosPromise } from 'axios';
import * as Models from '../../shared.models';
import ApiUrlsService from '../bl/api-urls.service';
import {
  AvailableCaseTypes,
  IModuleTypeDTO,
  IPOReadinessFeedbackReport,
  SelfAssessTypeDTO,
  Statistic
} from '../../interface';
import { getServiceAreaId } from './../../components/layout/landing/service-area-blocks/ServiceArea.helpers';
import GlobalInterceptor from '../../interceptors/global.interceptor';
import { toastError } from '../../utils/toast.utils';

export default class CaseDetailService {
  static getMemberFirmsDataByMultiGeo(): Promise<Omit<Models.DM.CaseFilterDto, 'CaseStatus'>> {
    const { urls } = ApiUrlsService.getMemberFirmsDataByMultiGeo();
    const serviceAreaId = getServiceAreaId();
    return Promise.allSettled(
      urls.map((url) => axios.post<Models.DM.CaseFilterDto>(url, { CaseTypeCategory: serviceAreaId }))
    ).then((results) => {
      let selectedMemberFirmId = 0;
      let hasFulfilled = false;
      let error: any = null;

      const uniqueMemberFirms = new Set<string>();

      results.forEach((result) => {
        if (result.status === 'fulfilled') {
          hasFulfilled = true;
          selectedMemberFirmId = result.value.data.SelectedMemberFirmId;
          result.value.data.MemberFirms.forEach((memberFirm) => {
            uniqueMemberFirms.add(JSON.stringify(memberFirm));
          });
        } else {
          error = result.reason;
        }
      });

      if (hasFulfilled) {
        if (error) {
          toastError('Not all sources are available');
        }
        return {
          SelectedMemberFirmId: selectedMemberFirmId,
          MemberFirms: Array.from(uniqueMemberFirms, (memberFirm) => JSON.parse(memberFirm))
        };
      }

      return GlobalInterceptor.errorResolver(error);
    });
  }

  static getCaseData(): AxiosPromise<Models.DM.CaseCreateDto> {
    return axios.get(ApiUrlsService.getCaseData());
  }

  static saveCaseDetail(caseDetailDto: Models.DM.CaseDetailDto): AxiosPromise<string> {
    return axios.post(ApiUrlsService.saveCaseDetail(), caseDetailDto);
  }

  static getAvailableCaseTypes(): AxiosPromise<AvailableCaseTypes> {
    return axios.get(ApiUrlsService.availableCaseTypeDetail());
  }

  static createCaseType(caseTypeDetailDto: Models.DM.CreateCaseTypeDto): AxiosPromise<string> {
    return axios.post(ApiUrlsService.createCaseType(), caseTypeDetailDto);
  }

  static updateCaseType(caseTypeDetailDto: Models.DM.UpdateCaseTypeDto): AxiosPromise<string> {
    return axios.put(ApiUrlsService.updateCaseType(), caseTypeDetailDto);
  }

  static getStatusUpdate(caseId: string, id: number): AxiosPromise<string> {
    return axios.post(`${ApiUrlsService.getStatusUpdate()}/${caseId}/${id}`);
  }

  static getModuleTypes(): AxiosPromise<IModuleTypeDTO[]> {
    return axios.get(ApiUrlsService.getModuleTypes());
  }

  static saveModuleType(saveModuleDto: Models.DM.SaveModuleDto): AxiosPromise<any> {
    return axios.post(ApiUrlsService.saveModuleType(), saveModuleDto);
  }

  static saveQuestionnaire(saveQuestionnaireDto: Models.DM.SaveQuestionnaireDto): AxiosPromise<any> {
    return axios.post(ApiUrlsService.saveQuestionnaire(), saveQuestionnaireDto);
  }

  static removeModule(data: any): AxiosPromise<any> {
    return axios.post(ApiUrlsService.removeModuleType(), data);
  }

  static removeQuestionnaire(data: any): AxiosPromise<any> {
    return axios.post(ApiUrlsService.removeQuestionnaire(), data);
  }

  static renameQuestionnaire(data: any): AxiosPromise<any> {
    return axios.post(ApiUrlsService.renameQuestionnaire(), data);
  }

  static removePractitionerQuestionnaire({
    caseUID,
    questionnaireUid
  }: {
    caseUID: string;
    questionnaireUid: string;
  }): AxiosPromise<any> {
    return axios.delete(ApiUrlsService.removePractitionerQuestionnaire(caseUID, questionnaireUid));
  }

  static renamePractitionerQuestionnaire({
    caseUID,
    questionnaireUid,
    QuestionnaireTitle
  }: {
    caseUID: string;
    questionnaireUid: string;
    QuestionnaireTitle: string;
  }): AxiosPromise<any> {
    return axios.put(ApiUrlsService.renamePractitionerQuestionnaire(caseUID, questionnaireUid), { QuestionnaireTitle });
  }

  static createPractitionerQuestionnaire({
    caseUId,
    QuestionnaireTitle,
    ModuleUId
  }: {
    caseUId: string;
    QuestionnaireTitle: string;
    ModuleUId: string;
  }): AxiosPromise<any> {
    return axios.post(ApiUrlsService.createPractitionerQuestionnaire(caseUId), { QuestionnaireTitle, ModuleUId });
  }

  static downloadSummaryOrHedgeReport(data: any, type: 'summary' | 'hedge'): AxiosPromise<Blob> {
    return axios.post(
      type === 'summary' ? ApiUrlsService.downloadWordReport() : ApiUrlsService.downloadHedgeDocumentationReport(),
      data,
      { responseType: 'blob' }
    );
  }

  static downloadExcelSapReport(data: any): AxiosPromise<Blob> {
    return axios.post(ApiUrlsService.downloadWordReport(), data, { responseType: 'blob' });
  }

  static getStatisticsReportFile(apiUrl: string, params: { from: string; to: string }): Promise<Statistic[]> {
    return axios.get<Statistic[]>(ApiUrlsService.getStatisticsReportFile(apiUrl), { params }).then(({ data }) => data);
  }

  static sendConsolidatedReportFile(from: string, to: string, countryCode: string, apiUrl: string): AxiosPromise<void> {
    return axios.get(ApiUrlsService.sendConsolidatedReportFile(countryCode, apiUrl), {
      params: {
        from,
        to
      }
    });
  }

  static getIPOReadinessFeedbackReport(
    apiUrl: string,
    params: {
      countryCodes: string[];
      dateFrom: string;
      dateTo: string;
      selfAssessTypeId: SelfAssessTypeDTO['Id'];
    }
  ) {
    return axios
      .post<IPOReadinessFeedbackReport>(ApiUrlsService.getIPOReadinessFeedbackReport(apiUrl), params)
      .then(({ data }) => data);
  }
}
