import axios from 'axios';
import { ApiEntityE } from '../shared/enums';
import { DemoEnvDTO, GeneralInformationConfigPostDTO, GeneralInformationPostDTO } from '../shared/interfaces';
import { MainApiService } from './main-api-service';
import { withTimeZoneOffset } from './services.helpers';

class IPOAppLinkService extends MainApiService {
  constructor() {
    super({ useMemberFirmInUrl: true });
  }

  sendIPOSecureLinkToEmail(
    request: GeneralInformationPostDTO & { receiveCommunication: boolean }
  ): Promise<DemoEnvDTO | null | undefined> {
    return axios
      .post(
        `${this.defaultNewRegionApi.replace(`/${ApiEntityE.Types}`, `/${ApiEntityE.Types}/ipo`)}/${
          ApiEntityE.Requests
        }`,
        withTimeZoneOffset(request)
      )
      .then(({ data }) => data);
  }

  sendNotIPOSecureLinkToEmail(request: GeneralInformationConfigPostDTO): Promise<DemoEnvDTO | null | undefined> {
    return axios.post(`${this.defaultNewRegionApi}/${ApiEntityE.Requests}`, request).then(({ data }) => data);
  }

  sendRestoredSecureLinkToEmail(emailAddress: string): Promise<void> {
    return axios
      .post(`${this.defaultNewRegionApi}/newLink`, withTimeZoneOffset({ emailAddress }))
      .then(({ data }) => data);
  }
}

export default new IPOAppLinkService();
