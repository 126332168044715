import { Option } from '../../../../components/ThemedSelect/ThemedSelect';
import { SelfAssessCountryDTO } from '../../../interface';
import { SetAssetsCountriesAction } from '../self-assessment-assets.actions';
import { SelfAssessmentAssetsReducer, SelfAssessmentAssetsState } from '../self-assessment-assets.interfaces';
import { setAssetsCountriesFetchingReducer } from './setPendingState.reducer';

export const getSelfAssesCountryOption = (country: SelfAssessCountryDTO): Option => {
  const label = country.Name || country.Code;
  return {
    value: country.Code,
    label,
    customValue: country,
    searchValue: label
  };
};

const normalizeList = (list: SelfAssessCountryDTO[]) =>
  list.reduce<SelfAssessmentAssetsState['assetsCountries']>(
    (acc, item) => {
      if (item.Code) {
        acc.codes.push(item.Code);
        acc.selectorOptions.push(getSelfAssesCountryOption(item));
        acc.data[item.Code] = item;
      }
      return acc;
    },
    { codes: [], selectorOptions: [], data: {} }
  );

export const setAssetCountriesReducer: SelfAssessmentAssetsReducer<SetAssetsCountriesAction> = (state, payload) => {
  let newState = setAssetsCountriesFetchingReducer(state, false);

  if (Object.keys(newState.assetsCountries).length || payload.length) {
    if (newState === state) {
      newState = { ...state };
    }
    newState.assetsCountries = normalizeList(payload);
  }

  return newState;
};
