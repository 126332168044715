import {
  GET_SETTINGS_FGA_FILE_BY_ID_FULFILLED,
  GET_SETTINGS_FGA_FILE_BY_ID_PENDING,
  GET_SETTINGS_FGA_FILE_BY_ID_REJECTED,
  GET_SETTINGS_FILES_LIST_FULFILLED,
  GET_SETTINGS_FILES_LIST_PENDING,
  GET_SETTINGS_FILES_LIST_REJECTED,
  GetSettingsFGAFilesAction,
  SettingAction,
  SettingsState
} from './settings.types';
import { Reducer } from 'redux';

const initialState: SettingsState = {
  filesList: [],
  filesListTotalRecords: 0,
  filesListLoading: false,
  fileLoading: false
};

export const settingsReducer: Reducer<SettingsState, SettingAction> = (state = initialState, action) => {
  switch (action.type) {
    case GET_SETTINGS_FILES_LIST_PENDING: {
      return { ...state, filesListLoading: true };
    }
    case GET_SETTINGS_FILES_LIST_FULFILLED: {
      const { payload } = action as GetSettingsFGAFilesAction;
      return {
        ...state,
        filesListLoading: false,
        filesList: payload.Files,
        filesListTotalRecords: payload.TotalRecords
      };
    }
    case GET_SETTINGS_FILES_LIST_REJECTED: {
      return { ...state, filesListLoading: false };
    }

    case GET_SETTINGS_FGA_FILE_BY_ID_PENDING: {
      return { ...state, fileLoading: true };
    }
    case GET_SETTINGS_FGA_FILE_BY_ID_FULFILLED: {
      return {
        ...state,
        fileLoading: false
      };
    }
    case GET_SETTINGS_FGA_FILE_BY_ID_REJECTED: {
      return { ...state, fileLoading: false };
    }
    default: {
      return state;
    }
  }
};
