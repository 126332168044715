import classNames from 'classnames';
import { FC, PropsWithChildren, ReactNode } from 'react';
import { components, ValueContainerProps } from 'react-select';

import { fixTooltipBlinkingScrollStyle, ThemedTooltip } from '../../../shared/components/themed-tooltip';
import { useTruncatedHook } from '../../../shared/hooks/useTruncated.hook';
import { Option } from '../ThemedSelect';

import './ValueContainer.scss';

type CommonProps = ValueContainerProps<Option, boolean>;

export interface ValueContainerPropsI extends CommonProps {
  renderValue?: (
    selected: CommonProps['options'],
    all: CommonProps['options'],
    defaultItem: ReactNode | ReactNode[]
  ) => ReactNode | ReactNode[];
  valueTooltipClassName?: string;
}

const filterSelectAllOption = (options: CommonProps['options']) => options.filter(({ value }) => value !== '*');

const ValueContainer: FC<PropsWithChildren<ValueContainerPropsI>> = (props) => {
  let currentValues = props.getValue();
  let toBeRendered = (props.children as any)?.[0];

  if (toBeRendered?.slice && currentValues.some((item: any) => item.value === '*')) {
    toBeRendered = toBeRendered.slice(1);
  }

  if (props.renderValue) {
    toBeRendered = props.renderValue(
      filterSelectAllOption(currentValues),
      filterSelectAllOption(props.options),
      toBeRendered
    );
  }

  const { titleRef, isTruncated } = useTruncatedHook(toBeRendered);

  const valuesContainer = (
    <span ref={titleRef} className="value-container__values">
      {toBeRendered}
    </span>
  );

  return (
    <components.ValueContainer {...props} className="value-container">
      {isTruncated ? (
        <ThemedTooltip
          placement="bottom"
          tooltip={toBeRendered}
          className={classNames(props.valueTooltipClassName, 'sidebar__module-button-tooltip')}
          tooltipStyle={fixTooltipBlinkingScrollStyle}
        >
          {valuesContainer}
        </ThemedTooltip>
      ) : (
        valuesContainer
      )}
      {(props.children as ReactNode[])?.[1]}
    </components.ValueContainer>
  );
};

export default ValueContainer;
