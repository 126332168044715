import React, { FC } from 'react';

import ErrorIcon from '../../../assets/icons/ErrorIcon';
import ToastSuccessIcon from '../../../assets/icons/ToastSuccessIcon';

interface Props {
  type: 'success' | 'error';
  message: string;
}

const iconTypeMap = {
  success: <ToastSuccessIcon />,
  error: <ErrorIcon />
};

const Toast: FC<Props> = ({ type, message }) => {
  return (
    <div className="custom-toast__body">
      <div className="custom-toast__icon">{iconTypeMap[type]}</div>
      {message}
    </div>
  );
};

export default Toast;
