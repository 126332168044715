import { SetSelectedAssetIdAction } from '../self-assessment-assets.actions';
import { SelfAssessmentAssetsReducer } from '../self-assessment-assets.interfaces';

export const setSelectedAssetIdReducer: SelfAssessmentAssetsReducer<SetSelectedAssetIdAction> = (state, payload) => {
  if (state.selectedAssetId === payload) {
    return state;
  }

  return {
    ...state,
    selectedAssetId: payload
  };
};
