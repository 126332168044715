import { SelfAssessTypesSettingsLandingDTO } from '../interface';

export const IPO_DIAGNOSTIC_PRODUCT_NAME = 'IPO SelfAssess';

export const IPO_DEFAULT_LANDING_SETTINGS: SelfAssessTypesSettingsLandingDTO = {
  Title: `Launch ${IPO_DIAGNOSTIC_PRODUCT_NAME}`,
  Subtitle: 'The beginning of your IPO readiness journey',
  Text: `Every IPO has a unique journey, and the ${IPO_DIAGNOSTIC_PRODUCT_NAME} tool is here to help you paint yours. Without planning, the journey can become chaotic and erratic. With proper planning, a company can identify its ideal components to create a roadmap for completing its IPO in an orderly fashion.\n\nThe ${IPO_DIAGNOSTIC_PRODUCT_NAME} tool addresses key topics, ranging from financial reporting to investor relations. Once you have submitted your self-assessment, which should take less than 30 minutes to complete, you can expect your tailored results within two business days. This deliverable provides color on how to navigate your IPO readiness journey, such as your company’s indicative readiness score, common pitfalls, and leading practices.`,
  ButtonText: `Initiate ${IPO_DIAGNOSTIC_PRODUCT_NAME}`
};

export const ASSET_DEFAULT_LANDING_SETTINGS: SelfAssessTypesSettingsLandingDTO = {
  Title: '',
  Subtitle: '',
  Text: '',
  ButtonText: 'Initiate Self-Assessment'
};
