import { ACTION_TYPES, currentUserStorage, CurrentUserStoreType, StoreName } from '../store';
import * as Models from '../../shared.models';

const initialState: Models.BM.UserProfile = JSON.parse(
  currentUserStorage.getItem(StoreName.CurrentUser) || JSON.stringify(Models.BM.UserProfile.createDefault())
);

export default function currentUserReducer(
  state: Models.BM.UserProfile = initialState,
  action: CurrentUserStoreType
): Models.BM.UserProfile {
  let result = {};

  switch (action.type) {
    case ACTION_TYPES.SET_CURRENT_USER:
      result = {
        ...state,
        user: action.profile.user
      };
      break;
    case ACTION_TYPES.SET_CURRENT_USER_TOKEN:
      result = {
        ...state,
        tokenObj: action.profile.tokenObj
      };
      break;
    default:
      result = state;
  }

  if (action.type === ACTION_TYPES.SET_CURRENT_USER || action.type === ACTION_TYPES.SET_CURRENT_USER_TOKEN) {
    currentUserStorage.setItem(StoreName.CurrentUser, JSON.stringify(result));
    // localStorage.setItem(StoreName.CurrentUser, JSON.stringify(result));
  }

  return result;
}
