// eslint-disable-next-line
import { AxiosRequestConfig } from 'axios';
import { store } from '../../store';
import { onLoaderChanged } from '../../store/actions/loader.actions';

const requestList: any[] = [];

class BlackListItem {
  public methods: Array<string>;
  public url?: string;
  public regex?: RegExp;

  constructor(methods: Array<string>, url?: string, regex?: RegExp) {
    this.methods = methods;
    this.url = url;
    this.regex = regex;
  }
}

const loaderBlackList: Array<BlackListItem> = [
  new BlackListItem(['POST']),
  new BlackListItem(['get'], '', /\/admin\/search/)
];

export default class LoaderService {
  static request(request: AxiosRequestConfig, debounce = 400): void {
    requestList.push(request);

    if (!this.canChangeLoaderStatus(request)) {
      if (!store.getState().loader.isLoading && requestList.length >= 1) {
        store.dispatch(onLoaderChanged(true));
      }
    }
  }

  static response(): void {
    requestList.splice(0, 1);

    if (requestList.length === 0) {
      store.dispatch(onLoaderChanged(false));
    }
  }

  private static canChangeLoaderStatus(request: AxiosRequestConfig): boolean {
    return loaderBlackList.some((item: BlackListItem): boolean => {
      return this.findUrl(item, request) && this.findMethod(item, request);
    });
  }

  private static findMethod(item: BlackListItem, request: AxiosRequestConfig): boolean {
    return item.methods.some((method: string) => {
      return method === request.method;
    });
  }

  private static findUrl(item: BlackListItem, request: AxiosRequestConfig): boolean {
    if (!request.url) return true;
    if (!item.url && !item.regex) return true;
    if (item.url && item.regex) return request.url.includes(item.url) && item.regex.test(request.url);
    if (item.url) return request.url.includes(item.url);
    return !!item.regex?.test(request.url);
  }
}
