import { useEffect, useState } from 'react';
import { generatePath, useHistory } from 'react-router-dom';
import { IPOGlobalInterceptor } from '../interceptors';
import { ROUTE, getAssetNameAndCountryCodeFromUnknownPathname } from '../routes';
import { IPOAuthenticationService, IPOConfigService } from '../services';
import { useOnLoadAppCoreData } from '../shared/store/app-core/app-core.hooks';

export const useIpoScannerAppInit = () => {
  const [initializing, setInitializing] = useState(true);
  const history = useHistory();
  const loadAppCoreData = useOnLoadAppCoreData();

  useEffect(() => {
    IPOGlobalInterceptor.init();
    return () => {
      IPOAuthenticationService.stopTokenAutoRefresh();
    };
  }, []);

  useEffect(() => {
    const { assetName, countryCode, pathname } = getAssetNameAndCountryCodeFromUnknownPathname();

    if (countryCode.current && (!countryCode.normalized || countryCode.current !== countryCode.normalized)) {
      // INFO: redirect with same countryCode if exists but invalid
      history.replace(generatePath(ROUTE.WrongCountry, { assetName: assetName, countryCode: countryCode.current }));
      setInitializing(false);
      return;
    }

    IPOConfigService.countryCode = countryCode.normalized;
    IPOConfigService.assetName = assetName;

    (async () => {
      try {
        await loadAppCoreData();
        const isWrongCountryPage = (() => {
          const parts = ROUTE.WrongCountry.split('/');
          const name = parts[parts.length - 1];
          return pathname.endsWith(name);
        })();
        if (isWrongCountryPage) {
          history.replace(
            generatePath(ROUTE.Home, {
              assetName: IPOConfigService.assetName,
              countryCode: IPOConfigService.countryCode
            })
          );
        }
      } catch {
        history.replace(
          generatePath(ROUTE.WrongCountry, {
            assetName: assetName || IPOConfigService.assetName,
            countryCode: countryCode.current || IPOConfigService.countryCode
          })
        );
      } finally {
        setInitializing(false);
      }
    })();
  }, [history, loadAppCoreData]);

  return { initializing };
};
