import { Reducer } from 'redux';
import { GET_FG_DETAILS, RE_SET_REDUCER, fgAnalysisState } from './fg-analysis.types';

const initialState: fgAnalysisState = {
  fgAnalysisData: []
};

export const fgAnalysisReducer: Reducer<any, any> = (state: fgAnalysisState = initialState, action: any) => {
  switch (action.type) {
    case GET_FG_DETAILS: {
      return { ...state, fgAnalysisData: action.data };
    }

    case RE_SET_REDUCER: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
