import { combineReducers } from 'redux';
import { appCoreReducer } from '../app-core/app-core.reducer';
import { generalInfoConfigReducer } from '../general-info-config';
import { questionnaireReducer } from '../questionnaire';
import { questionnaireResultReducer } from '../questionnaire-result';
import { settingsReducer } from '../settings';
import { StoreEntityE } from '../store-entity.enum';
import { IpoScannerRootStateT } from '../store.types';

const rootReducer = combineReducers<IpoScannerRootStateT>({
  [StoreEntityE.AppCore]: appCoreReducer,
  [StoreEntityE.Settings]: settingsReducer,
  [StoreEntityE.Questionnaire]: questionnaireReducer,
  [StoreEntityE.QuestionnaireResult]: questionnaireResultReducer,
  [StoreEntityE.GeneralInfoConfig]: generalInfoConfigReducer
});

export default rootReducer;
