import { FC, useEffect } from 'react';
import { generatePath, Redirect, Route, RouteProps } from 'react-router-dom';
import { IPOAuthenticationService, IPOConfigService } from '../services';
import { useTypedDispatch } from '../shared/store/hooks';
import { clearQuestionnaireResultState } from '../shared/store/questionnaire-result';
import { ROUTE } from './constants';

const PrivateRoute: FC<RouteProps> = ({ ...props }) => {
  const dispatch = useTypedDispatch();

  useEffect(() => {
    if (props.path !== ROUTE.QuestionnaireResults) {
      dispatch(clearQuestionnaireResultState());
    }
  }, [dispatch, props.path]);

  if (!IPOAuthenticationService.isAuthenticated) {
    return (
      <Redirect
        to={generatePath(IPOAuthenticationService.accessToken ? ROUTE.LinkExpired : ROUTE.Home, {
          assetName: IPOConfigService.assetName,
          countryCode: IPOConfigService.countryCode
        })}
      />
    );
  }

  return <Route {...props} />;
};

export default PrivateRoute;
