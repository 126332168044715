import { Reducer } from 'redux';
import { IPOConfigService } from '../../../services';
import { SelfAssesFieldTypeE } from '../../enums';
import { checkSectionIsAnswered, getCustomFieldErrors } from './general-info-config.helpers';
import { GeneralInfoConfigStateActionT, GeneralInfoConfigStateI } from './general-info-config.interface';
import { SELECT_ANSWER, SELECT_COUNTRY_STATE_ID, SET_CONFIG, SET_CONFIG_LOADING } from './general-info-config.types';

const initialState: GeneralInfoConfigStateI = {
  data: [],
  countryStateId: null,
  customValidationErrors: {},
  assetName: '',
  assetId: 0,
  loading: false
};

export const generalInfoConfigReducer: Reducer<GeneralInfoConfigStateI, GeneralInfoConfigStateActionT> = (
  state = initialState,
  action
): GeneralInfoConfigStateI => {
  switch (action.type) {
    case SET_CONFIG: {
      return {
        ...state,
        data: action.payload,
        loading: false,
        assetName: IPOConfigService.assetName,
        assetId: IPOConfigService.assetId
      };
    }

    case SET_CONFIG_LOADING: {
      // INFO: if we load new config - reset state to default one
      const neededState = action.payload ? initialState : state;
      return neededState.loading !== action.payload
        ? {
            ...neededState,
            loading: action.payload
          }
        : neededState;
    }

    case SELECT_COUNTRY_STATE_ID: {
      return state.countryStateId !== action.payload
        ? {
            ...state,
            countryStateId: action.payload
          }
        : state;
    }

    case SELECT_ANSWER: {
      const { payload } = action;

      const step = state.data[payload.stepIdx];
      if (!step) return state;

      const group = step.Question.QuestionGroups[payload.groupIdx];
      if (!group) return state;

      const section = group.QuestionSections[payload.sectionIdx];
      if (!section) return state;

      const answer = section.Questions[payload.answerIdx];
      if (!answer) return state;

      const newSteps = [...state.data];
      const newStep = {
        ...step,
        Question: { ...step.Question, QuestionGroups: [...step.Question.QuestionGroups] },
        QuestionAnswerDetails: [...step.QuestionAnswerDetails]
      };
      const newGroup = { ...group, QuestionSections: [...group.QuestionSections] };
      const newSection = { ...section, Questions: [...section.Questions] };
      const newAnswer = { ...answer };

      newSection.Questions[payload.answerIdx] = newAnswer;
      newGroup.QuestionSections[payload.sectionIdx] = newSection;
      newStep.Question.QuestionGroups[payload.groupIdx] = newGroup;
      newSteps[payload.stepIdx] = newStep;

      switch (newSection.QuestionType) {
        case SelfAssesFieldTypeE.Input:
        case SelfAssesFieldTypeE.TextArea:
        case SelfAssesFieldTypeE.DatePicker: {
          newAnswer.TextBoxValue = payload.TextBoxValue || '';
          break;
        }
        case SelfAssesFieldTypeE.SingleSelect:
        case SelfAssesFieldTypeE.Radio: {
          newAnswer.IsCheckBoxChecked = true;
          newAnswer.TextBoxValue = payload.TextBoxValue;
          newSection.Questions = newSection.Questions.map((item) =>
            item === newAnswer
              ? item
              : {
                  ...item,
                  IsCheckBoxChecked: false,
                  TextBoxValue: ''
                }
          );
          break;
        }
        case SelfAssesFieldTypeE.Checkbox: {
          newAnswer.IsSelected = !newAnswer.IsSelected;
          newAnswer.TextBoxValue = newAnswer.IsSelected ? payload.TextBoxValue : '';
          break;
        }
      }

      const newQuestionAnswerDetail = { ...newStep.QuestionAnswerDetails[payload.groupIdx] };
      newQuestionAnswerDetail.IsAnswered = false;

      newQuestionAnswerDetail.QuestionSectionAnswerDetails = newGroup.QuestionSections.map((item) => {
        const data = {
          QuestionType: item.QuestionType,
          Order: item.Order,
          IsAnswered: checkSectionIsAnswered(item)
        };
        if (data.IsAnswered) {
          newQuestionAnswerDetail.IsAnswered = true;
        }
        return data;
      });

      newStep.QuestionAnswerDetails[payload.groupIdx] = newQuestionAnswerDetail;

      return {
        ...state,
        data: newSteps,
        customValidationErrors: getCustomFieldErrors({
          prevErrors: state.customValidationErrors,
          payload,
          value: payload.TextBoxValue,
          fieldTitle: group.QuestionText
        })
      };
    }

    default: {
      return state;
    }
  }
};
