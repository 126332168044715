import { Option } from '../../components/ThemedSelect/ThemedSelect';
import { UserRole } from '../models/enums.dl';

export interface UserRoleOptionI extends Option {
  customValue: UserRole;
}

export const ADMIN_ROLE_OPTIONS: UserRoleOptionI[] = [
  { label: 'Global Admin', value: `${UserRole.GlobalAdmin}`, customValue: UserRole.GlobalAdmin },
  { label: 'Content Admin', value: `${UserRole.Admin}`, customValue: UserRole.Admin }
];
