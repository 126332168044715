import { FC, PropsWithChildren } from 'react';

import Loader from '../../shared/components/loader/loader';
import { useIpoScannerAppInit } from './use-ipo-scanner-app-init';

import 'react-toastify/dist/ReactToastify.css';
import '../styles/common.scss';

const IPOScannerAppInitializationContainer: FC<PropsWithChildren<{}>> = ({ children }) => {
  const { initializing } = useIpoScannerAppInit();

  return initializing ? <Loader /> : <>{children}</>;
};

export default IPOScannerAppInitializationContainer;
