import axios from 'axios';
import { IPO_DEFAULT_LANDING_SETTINGS } from '../../shared/constants/ipo-diagnostic.constant';
import { ApiEntityE } from '../shared/enums';
import { SettingsGetDTO } from '../shared/interfaces';
import { MainApiService } from './main-api-service';

class IPOSettingsService extends MainApiService {
  constructor() {
    super({ apiEntity: ApiEntityE.Settings, useMemberFirmInUrl: true });
  }

  getSettings(): Promise<SettingsGetDTO> {
    return axios.get<SettingsGetDTO>(this.defaultNewRegionApi).then(({ data }) => {
      if (this.isIPO && !data.HeadingText) {
        data.HeadingText = IPO_DEFAULT_LANDING_SETTINGS;
      }

      return data;
    });
  }
}

export default new IPOSettingsService();
