import { FC } from 'react';

import { components, OptionProps } from 'react-select';

import { Option } from '../ThemedSelect';
import { ThemedCheckbox } from '../../ThemedCheckbox/ThemedCheckbox';
import { stopPropagation } from '../../../shared/utils/stop-propagation';
import './OptionWithChekbox.scss';

interface Props extends OptionProps<Option, boolean> {
  allOptionLabel?: string;
}

const OptionWithCheckbox: FC<Props> = (props) => {
  const { isSelected, allOptionLabel } = props;
  let checked = isSelected;
  let indeterminate = false;
  const value = props.selectProps.value as Option[];

  if (props.label === allOptionLabel) {
    if (props.options.length === value.length + 1) {
      checked = true;
    }
    if (props.options.length !== value.length + 1 && value.length !== 0) {
      indeterminate = true;
    }
  }

  return (
    <components.Option {...props} className="option-with-checkbox">
      <span className="option-with-checkbox__label">
        <ThemedCheckbox
          checked={checked}
          onChange={stopPropagation}
          onClick={stopPropagation}
          indeterminate={indeterminate}
          label={props.label}
        />
      </span>
    </components.Option>
  );
};

export default OptionWithCheckbox;
