import { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { UseIpoReadinessSettingsReturnType } from '../../../../pages/SelfAssessmentAssets/components/Content/components/IPOReadinessSetting/hooks/useIpoReadinessSettings.hook';

export const fileIdSearchParamKey = 'fileId';

interface UseScrollToSettingsFileByFileScanResultPropsI
  extends Pick<UseIpoReadinessSettingsReturnType, 'handleUpdateGeneralInformationFile'> {
  isFilesLoaded: boolean;
  isIpoSettingsOpened: boolean;
  openIPOSettings: VoidFunction;
}

export const generateIPOFileBlockId = (name: string | number) => `ipo-settings-file-${name.toString()}`;

export const useScrollToSettingsFileByFileScanResult = ({
  isFilesLoaded,
  isIpoSettingsOpened,
  openIPOSettings
}: UseScrollToSettingsFileByFileScanResultPropsI) => {
  const history = useHistory();
  const location = useLocation();
  const openIPOSettingsRef = useRef(openIPOSettings);
  openIPOSettingsRef.current = openIPOSettings;

  useEffect(() => {
    if (location.search) {
      const searchParams = new URLSearchParams(location.search);
      const searchParamsFileId = searchParams.get(fileIdSearchParamKey);

      if (searchParamsFileId) {
        if (!isIpoSettingsOpened) {
          openIPOSettingsRef.current();
        } else if (isFilesLoaded) {
          const element = document.getElementById(generateIPOFileBlockId(searchParamsFileId));
          if (element) {
            element.scrollIntoView({ behavior: 'smooth', block: 'center' });
          }
          searchParams.delete(fileIdSearchParamKey);
          const search = searchParams.toString();
          history.replace({ search });
        }
      }
    }
  }, [location, history, isFilesLoaded, isIpoSettingsOpened]);
};
