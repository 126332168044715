import { SelfAssessmentAssetsState } from './self-assessment-assets.interfaces';

export const selfAssessmentAssetsState: SelfAssessmentAssetsState = {
  assets: { ids: [], data: {}, selectorOptions: [] },
  selectedAssetId: 0,
  selectedAssetConfigIdx: null,
  assetsCountries: {
    codes: [],
    selectorOptions: [],
    data: {}
  },
  assetsCountriesStates: {},
  sidebarAccordionOpened: {},
  assetsCaseTypesPrimarySectors: {},
  pendingState: {
    assetsFetching: true,
    countriesFetching: true,
    countryStatesFetching: true,
    assetCreating: false,
    assetUpdating: false,
    assetDeleting: false,
    primarySectorsFetching: false
  }
};
