import { Reducer } from 'redux';
import { DefaultItemsPerPage, GridColumnConfig } from '../../configs/grid.config';
import { AdminListActionT } from './admins.actions';
import { AdminListStateT, AdminSortFieldT } from './admins.types';
import {
  ADMIN_LIST_SET_ALL,
  ADMIN_LIST_SET_SEARCH,
  ADMIN_LIST_SET_PAGE_DATA,
  ADMIN_LIST_SET_SORT_DATA,
  ADMIN_LIST_SET_DELETING,
  ADMIN_LIST_SET_ROLE_CHANGING,
  ADMIN_LIST_RESET_STATE,
  CONTENT_ADMIN_LIST_SET_ALL
} from './admins.action-types';
import {
  chooseCorrectArrayForState,
  filterAdminList,
  getFilteredAdminListAndTotalGlobalAdmins,
  setAdminsPageDataToNewState,
  setAdminsSortDataToNewState
} from './admins.helpers';

const initialState: Readonly<AdminListStateT> = {
  allList: [],
  contentAdmins: [],
  totalGlobalAdmins: 0,
  filteredList: [],
  totalRecords: 0,
  displayList: [],
  search: '',
  sortColumnIdx: 0,
  sortColumnName: GridColumnConfig.UserAdministration[0].sortName as AdminSortFieldT,
  sortDirection: 'asc',
  pageIdx: 0,
  pageSize: DefaultItemsPerPage,
  deleting: false,
  roleChanging: false
};

export const adminsListReducer: Reducer<AdminListStateT, AdminListActionT> = (state = initialState, action) => {
  switch (action.type) {
    case ADMIN_LIST_SET_ALL: {
      if (!action.payload.length && !state.allList.length) {
        return state;
      }

      const newState = { ...state, allList: action.payload };

      const { filteredList, totalGlobalAdmins } = getFilteredAdminListAndTotalGlobalAdmins(
        newState.allList,
        newState.search
      );

      newState.totalGlobalAdmins = totalGlobalAdmins;

      newState.filteredList = chooseCorrectArrayForState({ new: filteredList, old: newState.filteredList });

      newState.totalRecords = newState.filteredList.length;

      setAdminsSortDataToNewState(newState, {
        sortColumnIdx: newState.sortColumnIdx,
        sortColumnName: newState.sortColumnName,
        sortDirection: newState.sortDirection
      });

      setAdminsPageDataToNewState(newState, {
        pageIdx: newState.pageIdx,
        pageSize: newState.pageSize
      });

      return newState;
    }

    case CONTENT_ADMIN_LIST_SET_ALL: {
      return {
        ...state,
        contentAdmins: action.payload
      };
    }

    case ADMIN_LIST_SET_SEARCH: {
      const newSearch = action.payload.trim();

      if (newSearch === state.search) return state;

      const newState = { ...state, search: newSearch, pageIdx: 0 };

      newState.filteredList = chooseCorrectArrayForState({
        new: filterAdminList(newState.allList, newState.search),
        old: newState.filteredList
      });

      newState.totalRecords = newState.filteredList.length;

      setAdminsSortDataToNewState(newState, {
        sortColumnIdx: newState.sortColumnIdx,
        sortColumnName: newState.sortColumnName,
        sortDirection: newState.sortDirection
      });

      setAdminsPageDataToNewState(newState, {
        pageIdx: newState.pageIdx,
        pageSize: newState.pageSize
      });

      return newState;
    }

    case ADMIN_LIST_SET_SORT_DATA: {
      const newState = { ...state };
      setAdminsSortDataToNewState(newState, action.payload);
      setAdminsPageDataToNewState(newState, {
        pageIdx: newState.pageIdx,
        pageSize: newState.pageSize
      });
      return newState;
    }

    case ADMIN_LIST_SET_PAGE_DATA: {
      const newState = { ...state };
      setAdminsPageDataToNewState(newState, action.payload);
      return newState;
    }

    case ADMIN_LIST_SET_DELETING: {
      return { ...state, deleting: action.payload };
    }

    case ADMIN_LIST_SET_ROLE_CHANGING: {
      return { ...state, roleChanging: action.payload };
    }

    case ADMIN_LIST_RESET_STATE: {
      return initialState;
    }

    default:
      return state;
  }
};
