import {
  ClearQuestionnaireResultStateActionI,
  SelectQuestionnaireResultTopicActionI,
  SetQuestionnaireResultActionI,
  SetQuestionnaireResultContactPhotoActionI,
  SetQuestionnaireResultLoadingActionI
} from './questionnaire-result.interface';
import {
  SET_QUESTIONNAIRE_RESULT,
  SET_LOADING,
  CLEAR_STATE,
  SET_PHOTO,
  SELECT_TOPIC
} from './questionnaire-result.types';

export const setQuestionnaireResult = (
  payload: SetQuestionnaireResultActionI['payload']
): SetQuestionnaireResultActionI => ({
  type: SET_QUESTIONNAIRE_RESULT,
  payload
});

export const setQuestionnaireResultLoading = (
  payload: SetQuestionnaireResultLoadingActionI['payload']
): SetQuestionnaireResultLoadingActionI => ({
  type: SET_LOADING,
  payload
});

export const setQuestionnaireResultContactPhoto = (
  payload: SetQuestionnaireResultContactPhotoActionI['payload']
): SetQuestionnaireResultContactPhotoActionI => ({
  type: SET_PHOTO,
  payload
});

export const selectQuestionnaireResultTopic = (
  payload: SelectQuestionnaireResultTopicActionI['payload']
): SelectQuestionnaireResultTopicActionI => ({
  type: SELECT_TOPIC,
  payload
});

export const clearQuestionnaireResultState = (): ClearQuestionnaireResultStateActionI => ({
  type: CLEAR_STATE
});
