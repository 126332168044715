import { SessionStorageKeysE } from '../shared/enums';

class SessionStorageService {
  private RequestId: string | number = '';

  constructor() {
    this.RequestId = sessionStorage.getItem(SessionStorageKeysE.RequestId) || '';
  }

  get requestId() {
    return this.RequestId;
  }

  set requestId(value) {
    this.RequestId = value;
    sessionStorage.setItem(SessionStorageKeysE.RequestId, value.toString());
  }
}

export default new SessionStorageService();
