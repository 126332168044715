import classNames from 'classnames';
import React, { FC } from 'react';

import './Spinner.scss';

interface SpinnerPropsI {
  className?: string;
  size?: number;
}

const Spinner: FC<SpinnerPropsI> = ({ className, size = 24 }) => {
  return (
    <svg
      className={classNames('Deloitte-spinner', className)}
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4333_45495)">
        <path
          d="M12 4C13.5823 4 15.129 4.46919 16.4446 5.34824C17.7602 6.22729 18.7855 7.47672 19.391 8.93853C19.9965 10.4003 20.155 12.0089 19.8463 13.5607C19.5376 15.1126 18.7757 16.538 17.6569 17.6569C16.538 18.7757 15.1126 19.5376 13.5607 19.8463C12.0089 20.155 10.4003 19.9965 8.93853 19.391C7.47672 18.7855 6.22729 17.7602 5.34824 16.4446C4.46919 15.129 4 13.5823 4 12L5.6 12C5.6 13.2658 5.97535 14.5032 6.67859 15.5556C7.38184 16.6081 8.38138 17.4284 9.55083 17.9128C10.7203 18.3972 12.0071 18.524 13.2486 18.277C14.4901 18.0301 15.6304 17.4205 16.5255 16.5255C17.4205 15.6304 18.0301 14.4901 18.277 13.2486C18.524 12.0071 18.3972 10.7203 17.9128 9.55083C17.4284 8.38138 16.6081 7.38184 15.5556 6.67859C14.5032 5.97535 13.2658 5.6 12 5.6L12 4Z"
          fill="currentColor"
        />
      </g>
      <defs>
        <clipPath id="clip0_4333_45495">
          <rect width="16" height="16" fill="white" transform="translate(4 4)" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Spinner;
