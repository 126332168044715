import { ApiEntityE } from '../shared/enums';
import IPOApiUrlsService from './api-urls.service';
import IPOConfigService from './config.service';

export class MainApiService {
  private ApiEntity: ApiEntityE | '';
  private useMemberFirmInUrl = false;
  private useShortName = false;

  private get apiEntityUrlPart() {
    return this.ApiEntity ? `/${this.ApiEntity}` : '';
  }
  private get countryCodeUrlPart() {
    return this.useMemberFirmInUrl && this.countryCode ? `/${this.countryCode}` : '';
  }
  private get assetIdUrlPart() {
    const assetId = this.useShortName ? this.assetName : this.assetId;
    return this.useMemberFirmInUrl && assetId ? `/${assetId}` : '';
  }
  private getApiUrl(type: keyof typeof this.apiOrigin) {
    const apiUrl = this.apiOrigin[type].old;
    return `${apiUrl}${this.countryCodeUrlPart}${this.apiEntityUrlPart}`;
  }
  private getNewApiUrl(type: keyof typeof this.apiOrigin) {
    const apiUrl = this.apiOrigin[type].new;
    return `${apiUrl}${this.assetIdUrlPart ? `/${ApiEntityE.Types}${this.assetIdUrlPart}` : ''}${
      this.countryCodeUrlPart ? `/countries${this.countryCodeUrlPart}` : ''
    }${this.apiEntityUrlPart}`;
  }
  protected get defaultRegionApi() {
    return this.getApiUrl('default');
  }
  protected get mainRegionApiUrl() {
    return this.getApiUrl('main');
  }
  protected get defaultNewRegionApi() {
    return this.getNewApiUrl('default');
  }
  protected get mainNewRegionApiUrl() {
    return this.getNewApiUrl('main');
  }
  protected get apiOrigin() {
    return {
      default: {
        new: IPOApiUrlsService.defaultNewRegionApi,
        old: IPOApiUrlsService.defaultRegionApi
      },
      main: {
        new: IPOApiUrlsService.mainNewRegionApiUrl,
        old: IPOApiUrlsService.mainRegionApiUrl
      }
    } as const;
  }

  protected get countryCode() {
    return IPOConfigService.countryCode;
  }

  protected get assetId() {
    return IPOConfigService.assetId;
  }

  protected get isIPO() {
    return IPOConfigService.isIPO;
  }

  protected get assetName() {
    return IPOConfigService.assetName;
  }

  constructor(settings: { apiEntity?: ApiEntityE; useMemberFirmInUrl: boolean; useShortName?: boolean }) {
    this.ApiEntity = settings?.apiEntity ?? '';
    this.useMemberFirmInUrl = settings.useMemberFirmInUrl;
    this.useShortName = !!settings.useShortName;
  }
}
