import { ROUTES } from '../../constants';
import { store } from '../../store';
import { redirectTo } from '../../store/actions/redirect-to.actions';

export default class RouteService {
  static get loginRoute(): string {
    return ROUTES.login;
  }

  static get landingPageRoute(): string {
    return ROUTES.landing;
  }

  static get unAuthorizedPage(): string {
    return ROUTES.unAuthorized;
  }

  static setRoute(route: string): void {
    store.dispatch(redirectTo(route));
  }
}
