import React, { FC } from 'react';
import { WarningIcon } from '../../../assets/icons/WarningIcon';
import { CrossIcon } from '../../../assets/icons/CrossIcon';

import './CustomToastForSettings.scss';

interface CustomToastForSettingsPropsI {
  errorMessage: string;
  errorTitle: string;
}

export const CustomToastForSettings: FC<CustomToastForSettingsPropsI> = ({ errorMessage, errorTitle }) => (
  <div className="toast">
    <div className="toast__header">
      <div className="toast__header__text">
        <WarningIcon />
        <span>{errorTitle}</span>
      </div>

      <CrossIcon size={32} />
    </div>

    <div className="toast__content">
      <span className="toast__content__message">{errorMessage.trim()}</span>
    </div>
  </div>
);
