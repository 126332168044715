import { HttpTransportType, HubConnectionBuilder } from '@microsoft/signalr';
import Services from '../../shared.services';

export default class SignalRService {
  private static getHubsUrl(url: string) {
    return url.replace(/api$/, 'hubs');
  }

  static get DefaultRegionUrl() {
    return this.getHubsUrl(Services.BL.ApiUrlsService.DefaultRegionUrl);
  }

  static get GeoRelatedUrl() {
    return this.getHubsUrl(Services.BL.ApiUrlsService.GeoRelatedUrl);
  }

  static getConnection = ({ url = '' }) =>
    new HubConnectionBuilder()
      .withUrl(`${this.GeoRelatedUrl}/${url}`, {
        accessTokenFactory: () => Services.BL.AuthenticationService.getToken()
      })
      .withAutomaticReconnect()
      .build();
}
