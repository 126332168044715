export const VALIDATION_LIMITS = {
  TEXTAREA_LENGTH: 3000,
  STATEMENT_LENGTH: 20000,
  PDF_TEMPLATE_LENGTH: 10000,
  YOUR_JOURNEY_LENGTH: 1500,
  YOUR_JOURNEY_FOOTNOTE_LENGTH: 1500,
  SUMMARY_OF_YOUR_ASSESSMENT: 2000,
  GLOSSARY_TERM_LENGTH: 100,
  GLOSSARY_DEFINITION_LENGTH: 2000,
  TITLE_LENGTH: 1000,
  QUESTION_LENGTH: 1000,
  ANSWER_LENGTH: 1000,
  IPO_QUESTION_GUIDANCE_LENGTH: 1600,
  QUESTION_GUIDANCE_LENGTH: 1000,
  VARIABLE_LENGTH: 50,
  QUESTIONNAIRE_NAME_LENGTH: 100,
  STEP_SHORT_TITLE: 30,
  STEP_HEADLINE: 300,
  COMMON_PITFALLS: 1000,
  REFLECTION: 300,
  BASELINE_COMMENTARY: 1000,
  RADARGRAPH_QUESTIONARE_WARNING: 10,
  RADARGRAPH_QUESTIONARE: 15
};

export enum VariableErrorType {
  MAX_LENGTH,
  WRONG_CHARACTERS,
  NOT_UNIQUE,
  WRONG_FORMULA,
  WRONG_VARIABLE_USING,
  VARIABLE_IS_NOT_EXIST
}

const VariableErrorText: Record<VariableErrorType, string> = {
  [VariableErrorType.MAX_LENGTH]: '',
  [VariableErrorType.WRONG_CHARACTERS]:
    'Variable name must contain latin characters, digits, dash, and underscore without spaces.',
  [VariableErrorType.NOT_UNIQUE]: 'Variable name must be unique.',
  [VariableErrorType.WRONG_FORMULA]: 'The formula is invalid. Please correct the formula to save the questionnaire.',
  [VariableErrorType.WRONG_VARIABLE_USING]: 'Variable name must start and end with # character.',
  [VariableErrorType.VARIABLE_IS_NOT_EXIST]: 'Variable name not found. Please note variables are case-sensitive.'
};

interface VariableErrorDataI {
  message: string;
  errorType: VariableErrorType;
}

export const getVariableErrorData = (type: VariableErrorType): VariableErrorDataI => ({
  message: VariableErrorText[type],
  errorType: type
});

export interface IQuestionValidationData extends Partial<VariableErrorDataI> {
  valid: boolean;
}

export interface IAnswerValidation {
  [key: string]: {
    message: string;
    show: boolean;
  };
}

const CORRECT_VARIABLE_TEST = '[a-zA-Z0-9_-]+';
const CORRECT_VARIABLE_WITH_VALUE_TEST = `${CORRECT_VARIABLE_TEST}(.Value)?`;
export const VARIABLE_USING_SYMBOL = '#';
const CORRECT_VARIABLE_USING_TEST = `${VARIABLE_USING_SYMBOL}(${CORRECT_VARIABLE_WITH_VALUE_TEST})${VARIABLE_USING_SYMBOL}`;
export const CORRECT_VARIABLE_REGEX = (settings?: { withValue?: boolean }) =>
  new RegExp(`^${settings?.withValue ? CORRECT_VARIABLE_WITH_VALUE_TEST : CORRECT_VARIABLE_TEST}$`, 'gi');
export const CORRECT_VARIABLE_USING_REGEX = () => new RegExp(CORRECT_VARIABLE_USING_TEST, 'gi');

export const checkIsVariableValid = (isUnique: boolean, value?: string | null): IQuestionValidationData => {
  if (!value) return { valid: true };

  if (value.length > VALIDATION_LIMITS.VARIABLE_LENGTH)
    return { valid: false, ...getVariableErrorData(VariableErrorType.MAX_LENGTH) };

  if (!CORRECT_VARIABLE_REGEX().test(value))
    return {
      valid: false,
      ...getVariableErrorData(VariableErrorType.WRONG_CHARACTERS)
    };

  if (!isUnique)
    return {
      valid: false,
      ...getVariableErrorData(VariableErrorType.NOT_UNIQUE)
    };

  return { valid: true };
};
