import { useEffect } from 'react';

const events = [
  'mousemove',
  'keydown',
  'DOMMouseScroll',
  'mousewheel',
  'mousedown',
  'touchstart',
  'touchmove',
  'scroll'
];

export const useEventsTracking = (actionCallback: VoidFunction) => {
  useEffect(() => {
    events.forEach((item) => document.addEventListener(item, actionCallback));

    return () => events.forEach((item) => document.removeEventListener(item, actionCallback));
  }, [actionCallback]);
};
