import React, { FunctionComponent, memo } from 'react';
import * as Models from '../../shared.models';
import './highlight.scss';

export interface IHighlightProps extends Models.BM.IPropsBase {
  text: string;
  mask: string;
}

const Highlight: FunctionComponent<IHighlightProps> = ({ mask = '', text = '' }) => {
  if (!mask || !text) return <span>{text} 33</span>;

  const matchedTextStartIdx: number = text.toLowerCase().indexOf(mask.toLowerCase());

  if (matchedTextStartIdx < 0) return <span>{text}</span>;

  const matchedTextEndIdx: number = matchedTextStartIdx + mask.length;

  return (
    <span>
      {text.substring(0, matchedTextStartIdx)}
      <span className="highlight">{text.substring(matchedTextStartIdx, matchedTextEndIdx)}</span>
      {text.substring(matchedTextEndIdx)}
    </span>
  );
};

export default memo(Highlight);
