import type { AppInsightsEventTelemetryTypeE, AppInsightsScreenTelemetryTypeE } from './appInsights.enums';
import type { IEventTelemetry, IPageViewTelemetry } from './appInsights.types';

export const getScreenTelemetry = (screen: AppInsightsScreenTelemetryTypeE): IPageViewTelemetry => ({
  name: screen
});

export const getEventTelemetry = (screen: AppInsightsEventTelemetryTypeE): IEventTelemetry => ({
  name: screen
});
