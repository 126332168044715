import React from 'react';
import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

import * as Models from '../shared.models';
import { ROUTES } from '../constants';
import LoaderService from '../services/bl/loader.service';
import ApiUrlsService from '../services/bl/api-urls.service';
import CommonService from '../services/bl/common.service';
import AuthenticationService from '../services/bl/authentication.service';
import RouteService from '../services/bl/route.service';
import { toastError } from '../utils/toast.utils';
import AuthorizationService from '../services/bl/authorization.service';
import { MsalAuthService } from '../../msal-auth';

export class GlobalInterceptor extends React.Component {
  private static logingBlackList: Array<string> = [];
  private static toasterBlackList: Array<string> = [];

  static init(): void {
    this.request();
    this.response();

    this.logingBlackList.push(ApiUrlsService.logError());

    const regUrls: Array<string> = ApiUrlsService.registrationSaveUrls();
    regUrls.forEach((url) => this.toasterBlackList.push(url));

    const profileUrls = ApiUrlsService.getUrlsUserProfile();
    profileUrls.urls.forEach((url) => this.toasterBlackList.push(url));
  }

  static request(): any {
    return axios.interceptors.request.use(
      (request: AxiosRequestConfig) =>
        this.requestHandler(request).then((r) => {
          LoaderService.request(r);
          return r;
        }),
      (err: AxiosError) => {
        return this.errorResponseHandler(err);
      }
    );
  }

  static response(): any {
    return axios.interceptors.response.use(
      (response: AxiosResponse) => {
        LoaderService.response();

        return response;
      },
      (err: AxiosError) => {
        LoaderService.response();
        return this.errorResponseHandler(err);
      }
    );
  }

  private static async requestHandler(request: AxiosRequestConfig): Promise<AxiosRequestConfig> {
    let token = await MsalAuthService.refreshTokenSilent();

    if (!token) {
      token = AuthenticationService.getToken();
    }

    if (!request.headers) {
      request.headers = {};
    }

    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }

    if (!request.headers.Accept) {
      request.headers.Accept = 'application/json';
    }

    if (!request.headers['Content-Type']) {
      request.headers['Content-Type'] = 'application/json';
    }

    return request;
  }

  private static showError = (msg: any, status?: number) => {
    // INFO: set msg as custom toast Id to prevent multiple toasts with same messages
    toastError(msg, msg && (typeof msg === 'string' || typeof msg === 'number') ? msg : status);
  };

  static errorResolver(error: any): Promise<any> {
    if (error?.response) {
      const url: string = error.response?.config?.url || '';
      const commonErrorMsg =
        typeof error.response?.data === 'string' ? error.response.data : error.response.data?.message || '';
      const status = error.response?.status;

      switch (status) {
        case Models.BE.HttpStatus.Unauthorized: {
          this.showError(commonErrorMsg, status);
          AuthenticationService.logOut();
          RouteService.setRoute(ROUTES.home);
          return Promise.reject(null);
        }
        case Models.BE.HttpStatus.PreconditionFailed: {
          RouteService.setRoute(ROUTES.termsofUse);
          return Promise.reject(null);
        }
        case Models.BE.HttpStatus.Forbidden: {
          this.showError(commonErrorMsg || 'You have no permissions', status);
          if (!AuthorizationService.isGlobalAdmin()) {
            RouteService.setRoute(ROUTES.dashboard);
          }
          return Promise.reject(null);
        }
        case Models.BE.HttpStatus.ServiceUnavailable: {
          RouteService.setRoute(ROUTES.maintenance);
          return Promise.reject(null);
        }
        case Models.BE.HttpStatus.NotFound: {
          if (error.response?.data !== undefined) {
            this.showError(commonErrorMsg, status);
            RouteService.setRoute(ROUTES.dashboard);
          } else {
            this.showError('Not found', status);
          }
          return Promise.reject(null);
        }
        case Models.BE.HttpStatus.ServerError: {
          this.showError('something went wrong', status);
          return Promise.reject(error);
        }
        default: {
          if (commonErrorMsg && !CommonService.LikeSomeInList(this.toasterBlackList, url)) {
            this.showError(commonErrorMsg);
          }
          return Promise.reject(error);
        }
      }
    } else if (error?.message === 'Network Error') {
      this.showError(error.message);
    }

    return Promise.reject(error);
  }

  private static errorResponseHandler(error: AxiosError): Promise<any> {
    const url: string = error?.config?.url || error?.response?.config?.url || '';
    // INFO: next urls is part of tries iteration - so need to handle error only once
    const isUserProfileUrl = !!url && CommonService.LikeSomeInList(ApiUrlsService.getUrlsUserProfile().urls, url);
    const isMemberFirmUrl =
      !!url && CommonService.LikeSomeInList(ApiUrlsService.getMemberFirmsDataByMultiGeo().urls, url);

    const handleErrorByInterceptor = !isMemberFirmUrl && !isUserProfileUrl;

    if (handleErrorByInterceptor) {
      return GlobalInterceptor.errorResolver(error);
    }

    // INFO: Pls handle error in request place with GlobalInterceptor.errorResolver
    return Promise.reject(error);
  }
}

export default GlobalInterceptor;
