import axios, { AxiosPromise } from 'axios';
import * as Models from '../../shared.models';
import ApiUrlsService from '../bl/api-urls.service';

export default class RoadmapService {
  static fetchRoadmapReport(data: Models.DM.FGReportPayloadDto): AxiosPromise<string> {
    return axios.post(ApiUrlsService.getIPOroadmapReport(), data);
  }
  static saveRoadmapTask(payload: any): AxiosPromise<any> {
    return axios.post(ApiUrlsService.saveRoadmapTask(), payload);
  }

  static removeRoadmapTask(payload: any): AxiosPromise<any> {
    return axios.post(ApiUrlsService.removeRoadmapTask(), payload);
  }

  static validateAssociatedTask(data: Models.DM.RoadmapReportPayloadDto): AxiosPromise<string> {
    return axios.post(ApiUrlsService.validateAssociatedTask(), data);
  }

  static getRoadmapTasks(moduleUID: any): AxiosPromise<any> {
    return axios.get(`${ApiUrlsService.getRoadmapTasks()}/${moduleUID}`);
  }
  static saveIPORoadmapReportJson(data: Models.DM.FGReportPayloadDto): any {
    return axios.post(ApiUrlsService.saveIPOroadmapReportJson(), data);
  }
  static getIPORoadmapReportJson(data: Models.DM.FGReportPayloadDto): any {
    return axios.post(ApiUrlsService.getIPOroadmapReportJson(), data);
  }

  static getRoadmapReport(data: Models.DM.RoadmapReportPayloadDto): AxiosPromise<string> {
    return axios.post(ApiUrlsService.getroadmapReport(), data);
  }
}
