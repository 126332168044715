import { applyMiddleware, compose, createStore } from 'redux';
import rootReducer from './combineReducers';
import thunk from 'redux-thunk';

const enhancers: Array<any> = [];
const windowIfDefined = window as any;

if (windowIfDefined?.__REDUX_DEVTOOLS_EXTENSION__ && process.env.NODE_ENV === 'development') {
  enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
}

export const ipoScannerStore = createStore(rootReducer, compose(applyMiddleware(thunk), ...enhancers));
