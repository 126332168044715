import React, { FC } from 'react';
import { CommonIconPropsI } from './common-icon-props.type';

const ReleaseNotesIcon: FC<CommonIconPropsI> = ({ size = 24, ...props }) => {
  return (
    <svg {...props} width="24" height="24" viewBox="0 -3 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M10.875 0.75C11.0591 0.75 11.2122 0.882656 11.244 1.05759L11.25 1.125V7.93934C11.25 8.1051 11.1951 8.2652 11.0954 8.39536L11.0303 8.46967L8.46967 11.0303C8.40576 11.0942 8.33149 11.1454 8.25082 11.1823L8.25 11.25H1.125C0.940905 11.25 0.787794 11.1173 0.756042 10.9424L0.75 10.875V1.125C0.75 0.940905 0.882656 0.787794 1.05759 0.756042L1.125 0.75H10.875ZM10.5 1.5H1.5V10.5H7.5V8.625C7.5 8.21079 7.83579 7.875 8.25 7.875H10.4992L10.5 1.5ZM9.813 8.625H8.25V10.1888L9.813 8.625ZM5.625 6C5.83211 6 6 6.16789 6 6.375C6 6.58211 5.83211 6.75 5.625 6.75H3.375C3.16789 6.75 3 6.58211 3 6.375C3 6.16789 3.16789 6 3.375 6H5.625ZM8.625 4.5C8.83211 4.5 9 4.66789 9 4.875C9 5.08211 8.83211 5.25 8.625 5.25H3.375C3.16789 5.25 3 5.08211 3 4.875C3 4.66789 3.16789 4.5 3.375 4.5H8.625ZM8.625 3C8.83211 3 9 3.16789 9 3.375C9 3.58211 8.83211 3.75 8.625 3.75H3.375C3.16789 3.75 3 3.58211 3 3.375C3 3.16789 3.16789 3 3.375 3H8.625Z"
        fill="black"
      />
    </svg>
  );
};

export default ReleaseNotesIcon;
