import { Reducer } from 'redux';
import { AppCoreStateActionT, AppCoreStateI } from './app-core.interface';
import { SET_ASSET, SET_GEO } from './app-core.types';

const initialState: AppCoreStateI = {
  asset: null,
  geo: ''
};

export const appCoreReducer: Reducer<AppCoreStateI, AppCoreStateActionT> = (
  state = initialState,
  action
): AppCoreStateI => {
  switch (action.type) {
    case SET_GEO: {
      return {
        ...state,
        geo: action.payload
      };
    }

    case SET_ASSET: {
      return {
        ...state,
        asset: action.payload
      };
    }

    default: {
      return state;
    }
  }
};
