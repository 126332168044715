import { SelfAssessTypeDTO } from '../../../interface';
import { SetSidebarAccordionState } from '../self-assessment-assets.actions';
import { SelfAssessmentAssetsReducer, SelfAssessmentAssetsState } from '../self-assessment-assets.interfaces';
import { setAssetsCountriesFetchingReducer } from './setPendingState.reducer';

const normalizeList = (list: SelfAssessTypeDTO['Id'][]) =>
  list.reduce<SelfAssessmentAssetsState['sidebarAccordionOpened']>((acc, item) => {
    acc[item] = true;
    return acc;
  }, {});

export const setSidebarAccordionState: SelfAssessmentAssetsReducer<SetSidebarAccordionState> = (state, payload) => {
  if (Object.keys(state.sidebarAccordionOpened).length || payload.length) {
    return {
      ...state,
      sidebarAccordionOpened: normalizeList(payload)
    };
  }

  return state;
};
