import React, { FC } from 'react';
import { CommonIconPropsI } from './common-icon-props.type';

const HeadphonesIcon: FC<CommonIconPropsI> = ({ size = 24, ...props }) => {
  return (
    <svg {...props} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4C15.9283 4 19.8464 7.67399 19.9956 11.2875L20 11.5V17C20 18.1046 19.1046 19 18 19C16.8954 19 16 18.1046 16 17V14C16 12.8954 16.8954 12 18 12H19V11.5C19 8.44839 15.6117 5.1438 12.2211 5.00456L12 5C8.61087 5 5.15049 8.23966 5.00477 11.3007L5 11.5V12H6C7.10457 12 8 12.8954 8 14V17C8 18.1046 7.10457 19 6 19C4.89543 19 4 18.1046 4 17V11.5C4 7.81948 7.99469 4 12 4ZM6 13H5V17C5 17.5523 5.44772 18 6 18C6.55228 18 7 17.5523 7 17V14C7 13.4477 6.55228 13 6 13ZM19 13H18C17.4477 13 17 13.4477 17 14V17C17 17.5523 17.4477 18 18 18C18.5523 18 19 17.5523 19 17V13Z"
        fill="black"
      />
    </svg>
  );
};

export default HeadphonesIcon;
