export enum ROUTE {
  Home = '/:assetName/:countryCode',
  SecureCode = '/auth/:assetName/:countryCode/:requestId/:scrWord',
  GeneralInformation = '/:assetName/:countryCode/general',
  CheckYourEmail = '/:assetName/:countryCode/please_check_email',
  RequestLoginLink = '/:assetName/:countryCode/request_login_link',
  Questionnaire = '/:assetName/:countryCode/questionnaire',
  QuestionnaireResults = '/:assetName/:countryCode/questionnaire/results',
  QuestionnaireTopic = '/:assetName/:countryCode/questionnaire/results/:topicOrderNumber',
  LinkExpired = '/:assetName/:countryCode/not_valid',
  NotFound = '/:assetName/:countryCode/not_found',
  WrongCountry = '/:assetName/:countryCode/country_not_configured'
}
