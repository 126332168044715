import React from 'react';

const DefaultMultiValue = (props: any) => {
  return (
    <>
      {props.data.label}
      {props.index !== props.selectProps.value.length - 1 ? ', ' : ''}
    </>
  );
};

export default DefaultMultiValue;
