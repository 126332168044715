import NavigatorApp from './App';
import IPOScannerApp from './ipo-scanner-app/ipo-scanner-app';
import { checkIsSelfAssessRoute } from './ipo-scanner-app/routes';
import { initToast } from './shared/utils/toast.utils';

initToast();

const isSelfAssess = checkIsSelfAssessRoute();
document.title = isSelfAssess ? 'Loading...' : 'Assurance Navigator';

const AppSelector = () => {
  return isSelfAssess ? <IPOScannerApp /> : <NavigatorApp />;
};

export default AppSelector;
