import { IPOSettingsService } from '../../../services';
import generalInfoConfigService from '../../../services/general-info-config.service';
import { TypedThunk } from '../store.types';
import { setSettings, setSettingsLoading } from './settings.actions';
import { selectSettings } from './settings.selector';

export const getSettings: TypedThunk<void> = () => {
  return async (dispatch, getState) => {
    const state = getState();
    const settings = selectSettings(state);

    if (settings) {
      // INFO: prevent load setting twice
      dispatch(setSettingsLoading(false));
      return;
    }

    try {
      dispatch(setSettingsLoading(true));
      const data = await IPOSettingsService.getSettings();
      dispatch(setSettings(data));
    } catch (error) {
      return Promise.reject(error);
    }
  };
};
