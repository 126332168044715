import { ACTION_TYPES, isCustomLoadingLogicType, IsLoadingStoreType } from '../store';

export function onLoaderChanged(isLoading: boolean): IsLoadingStoreType {
  return {
    type: ACTION_TYPES.IS_LOADING,
    isLoading
  };
}

export function usingCustomLoaderLogic(isCustomLoadingLogic: boolean): isCustomLoadingLogicType {
  return {
    type: ACTION_TYPES.IS_CUSTOM_LOADING_LOGIC,
    isCustomLoadingLogic
  };
}
