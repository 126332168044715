export enum AppInsightsScreenTelemetryTypeE {
  LaunchIPOView = 'SelfAssess Launch',
  GeneralInfoView = 'SelfAssess General information',
  CheckEmailView = 'SelfAssess Check email',
  InvalidLinkView = 'SelfAssess Invalid link',
  RequestLoginLinkView = 'SelfAssess Request login link',
  QuestionnaireView = 'SelfAssess Questionnaire',
  ResultsView = 'SelfAssess Results'
}

export enum AppInsightsEventTelemetryTypeE {
  InitiateIPO = 'SelfAssess Initiate',
  SubmitGeneralInfo = 'SelfAssess Submit General Information',
  Authenticate = 'SelfAssess Authenticate',
  RequestNewLink = 'SelfAssess Request new link',
  // Q - questionnaire
  QExcludeTopic = 'SelfAssess Exclude this topic',
  QSaveNContinue = 'SelfAssess Save answers',
  QBack = 'SelfAssess Go to the previous topic',
  QNavigateByTopic = 'SelfAssess Navigate by topic',
  QComplete = 'SelfAssess Complete questionnaire',
  QSubmit = 'SelfAssess Submit questionnaire',
  // R - results
  REditResponses = 'SelfAssess Edit responses'
}
