import _ from 'lodash';
import { areasIdMap } from '../components/layout/landing/service-area-blocks/ServiceArea.helpers';
import * as BM from './../models/models.bl';
import * as DM from './../models/models.dl';

export const DefaultItemsPerPage = 10;

export const PageSizes: Array<DM.PageSizeDto> = [
  new DM.PageSizeDto(10, '10'),
  new DM.PageSizeDto(20, '20'),
  new DM.PageSizeDto(30, '30'),
  new DM.PageSizeDto(40, '40'),
  new DM.PageSizeDto(50, '50'),
  new DM.PageSizeDto(100000, 'All')
];

export class GridColumnConfig {
  public static CaseDetails: Array<BM.ColumnConfiguration> = [
    { displayName: 'Status', columnName: 'StatusId', sortName: '', isHidden: false, minWidth: '100px' },
    { displayName: 'Engagement Name', columnName: 'CaseName', sortName: '', isHidden: false, minWidth: '110px' },
    { displayName: 'Client Name', columnName: 'ClientName', sortName: '', isHidden: false, minWidth: '110px' },
    { displayName: 'Engagement Type', columnName: 'CaseType', sortName: '', isHidden: false, minWidth: '100px' },
    { displayName: 'Created By', columnName: 'CreatedBy', sortName: '', isHidden: false, minWidth: '170px' },
    { displayName: 'Creation Date', columnName: 'Created', sortName: '', isHidden: false, minWidth: '70px' },
    { displayName: 'Last Updated', columnName: 'LastModified', sortName: '', isHidden: false, minWidth: '70px' },
    { displayName: 'Actions', columnName: '', sortName: '', isHidden: false, minWidth: '50px' }
  ];
  public static CaseRecycleBin: Array<BM.ColumnConfiguration> = [
    { displayName: 'Status', columnName: 'StatusId', sortName: '', isHidden: false, minWidth: '100px' },
    { displayName: 'Engagement Name', columnName: 'CaseName', sortName: '', isHidden: false, minWidth: '110px' },
    { displayName: 'Client Name', columnName: 'ClientName', sortName: '', isHidden: false, minWidth: '110px' },
    { displayName: 'Engagement Type', columnName: 'CaseType', sortName: '', isHidden: false, minWidth: '100px' },
    { displayName: 'Created By', columnName: 'CreatedBy', sortName: '', isHidden: false, minWidth: '170px' },
    { displayName: 'Creation Date', columnName: 'Created', sortName: '', isHidden: false, minWidth: '70px' },
    { displayName: 'Actions', columnName: '', sortName: '', isHidden: false, minWidth: '50px' }
  ];
  public static CaseTypeDetails: Array<BM.ColumnConfiguration> = [
    { displayName: 'Engagement Type', columnName: 'Name', sortName: '', isHidden: false, minWidth: '180px' },
    {
      displayName: 'Service Area',
      columnName: 'CaseTypeCategoryId',
      sortName: 'ServiceArea',
      isHidden: false,
      minWidth: '164px'
    },
    { displayName: 'Country(s)', columnName: 'Countries', sortName: '', isHidden: false, minWidth: '156px' },
    {
      displayName: 'Assigned Content Admins',
      columnName: 'ContentAdmins',
      sortName: '',
      isHidden: false,
      minWidth: '250px'
    },
    { displayName: 'Status', columnName: 'Status', sortName: '', isHidden: false, minWidth: '110px' },
    { displayName: 'Last Updated Date', columnName: 'LastModified', sortName: '', isHidden: false, minWidth: '100px' },
    { displayName: 'Last Updated By', columnName: 'FullName', sortName: '', isHidden: false, minWidth: '170px' },
    { displayName: 'Actions', columnName: '', sortName: '', isHidden: false, minWidth: '50px' }
  ];
  public static UserAdministration: Array<BM.ColumnConfiguration> = [
    {
      displayName: 'User Name',
      columnName: 'userName',
      sortName: 'userName',
      isHidden: false
    },
    {
      displayName: 'Created Date',
      columnName: 'CreatedOn',
      sortName: 'CreatedOn',
      isHidden: false
    },
    { displayName: 'Role', columnName: 'RoleId', sortName: 'RoleId', isHidden: false },
    { displayName: 'Remove', columnName: 'remove', sortName: '', isHidden: false }
  ];
}
