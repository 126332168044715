import { IPOAppLinkService, IPOConfigService } from '../../../services';
import GeneralInfoConfigService from '../../../services/general-info-config.service';
import { GeneralInformationConfigPostDTO } from '../../interfaces';
import { StoreEntityE } from '../store-entity.enum';
import { TypedThunk } from '../store.types';
import { setGeneralInfoConfig, setGeneralInfoConfigLoading } from './general-info-config.actions';
import { prepareConfigForSave } from './general-info-config.helpers';

export const getGeneralInfoConfig: TypedThunk<void> = () => {
  return async (dispatch, getState) => {
    const currentState = getState()[StoreEntityE.GeneralInfoConfig];
    const isRequestedConfigLoaded =
      currentState.data.length &&
      currentState.assetId === IPOConfigService.assetId &&
      currentState.assetName === IPOConfigService.assetName;

    if (IPOConfigService.isIPO && currentState.loading) {
      dispatch(setGeneralInfoConfigLoading(false));
    }

    if (IPOConfigService.isIPO || currentState.loading || isRequestedConfigLoaded) {
      return;
    }

    try {
      dispatch(setGeneralInfoConfigLoading(true));
      const data = await GeneralInfoConfigService.getConfig();
      dispatch(setGeneralInfoConfig(data));
    } catch (error) {
      return Promise.reject(error);
    }
  };
};

export const saveGeneralInfoConfig: TypedThunk<Pick<GeneralInformationConfigPostDTO, 'receiveCommunication'>> = (
  payload
) => {
  return async (_, getState) => {
    const currentState = getState()[StoreEntityE.GeneralInfoConfig];
    const questionnaireAnswer = prepareConfigForSave(currentState.data);
    if (!questionnaireAnswer) throw new Error('Nothing to save');
    const data = await IPOAppLinkService.sendNotIPOSecureLinkToEmail({
      questionnaireAnswer,
      countryStateId: currentState.countryStateId,
      ...payload
    });
    return data;
  };
};
