import React, { FC } from 'react';
import { components, OptionProps } from 'react-select';
import classNames from 'classnames';

import { Option } from '../ThemedSelect';
import './DefaultOption.scss';

const DefaultOption: FC<OptionProps<Option, boolean>> = (props) => {
  return (
    <components.Option
      {...props}
      className={classNames('default-option', {
        'default-option_selected': props.isSelected
      })}
    >
      {props.label}
    </components.Option>
  );
};

export default DefaultOption;
