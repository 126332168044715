import axios from 'axios';
import { ApiEntityE } from '../shared/enums';
import { QuestionnaireDTO, QuestionnaireGlossarySettingsDTO, SaveQuestionnaireAnswersDTO } from '../shared/interfaces';
import { MainApiService } from './main-api-service';
import SessionStorageService from './session-storage.service';

enum IPOQuestionnaireServiceApiEntity {
  Questionnaire = 'questionnaire',
  SaveAnswers = 'saveAnswers',
  SkipStep = 'skipStep',
  Restart = 'restart',
  Glossary = 'glossary'
}

class IPOQuestionnaireService extends MainApiService {
  constructor() {
    super({ useMemberFirmInUrl: false });
  }

  private get currentDefaultRegionApi() {
    return `${this.defaultRegionApi}/${IPOQuestionnaireServiceApiEntity.Questionnaire}`;
  }

  private get currentNewDefaultRegionApi() {
    return `${this.defaultRegionApi}/${ApiEntityE.Types}/${this.assetId}/${IPOQuestionnaireServiceApiEntity.Questionnaire}`;
  }

  private get currentDefaultRequestsRegionApi() {
    const requestId = SessionStorageService.requestId;
    return `${this.defaultRegionApi}/${ApiEntityE.Requests}/${requestId}/${IPOQuestionnaireServiceApiEntity.Questionnaire}`;
  }

  fetchQuestionnaireFirstStep = async (): Promise<QuestionnaireDTO | null> => {
    const { data } = await axios.get<[QuestionnaireDTO]>(this.currentDefaultRequestsRegionApi);
    return data[0] || null;
  };

  excludeTopic = async (stepNumber: number) => {
    const { data } = await axios.post<[QuestionnaireDTO]>(
      `${this.currentDefaultRequestsRegionApi}/${IPOQuestionnaireServiceApiEntity.SkipStep}?stepNumber=${stepNumber}`
    );
    return data[0] || null;
  };

  saveQuestionnaireAnswersAndGoToStep = async (
    newData: SaveQuestionnaireAnswersDTO
  ): Promise<QuestionnaireDTO | null> => {
    const { data } = await axios.post<[QuestionnaireDTO]>(
      `${this.currentDefaultRequestsRegionApi}/${IPOQuestionnaireServiceApiEntity.SaveAnswers}`,
      newData
    );
    return data[0] || null;
  };

  fetchQuestionnaireGlossary = async (
    questionnaireId: QuestionnaireDTO['QuestionaireId']
  ): Promise<QuestionnaireGlossarySettingsDTO[]> => {
    const { data } = await axios.get<QuestionnaireGlossarySettingsDTO[]>(
      `${this.currentDefaultRegionApi}/${questionnaireId}/${IPOQuestionnaireServiceApiEntity.Glossary}`
    );

    return data || [];
  };

  restartQuestionnaire = async (): Promise<void> => {
    const { data } = await axios.post<void>(
      `${this.currentNewDefaultRegionApi}/${IPOQuestionnaireServiceApiEntity.Restart}`
    );
    return data;
  };
}

export default new IPOQuestionnaireService();
