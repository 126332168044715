import { ACTION_TYPES, LanguagesStoreType } from '../store';
import * as Models from '../../shared.models';

const initialState: Models.BM.LanguageLookups = JSON.parse(
  JSON.stringify(Models.BM.LanguageLookups.createDefaultLanguage())
);

export default function languageLookupsReducer(
  state: Models.BM.LanguageLookups = initialState,
  action: LanguagesStoreType
): Models.BM.LanguageLookups {
  let result = {};

  switch (action.type) {
    case ACTION_TYPES.SET_LANGUAGES:
      result = {
        ...state,
        languages: action.languageLookups.languages
      };
      break;
    default:
      result = state;
  }

  if (action.type === ACTION_TYPES.SET_LANGUAGES) {
  }

  return result;
}
