import { Reducer } from 'redux';
import { GET_FG_REPORT, RE_SET_REDUCER, fgAnalysisState, Getfgreport } from './fg-analysisReport.types';

const initialState: fgAnalysisState = {
  fgAnalysisData: []
};

export const fgAnalysisReportReducer: Reducer<any, any> = (state: fgAnalysisState = initialState, action: any) => {
  switch (action.type) {
    case GET_FG_REPORT: {
      return { ...state, fgAnalysisData: action.data };
    }

    case RE_SET_REDUCER: {
      return { ...initialState };
    }
    case Getfgreport: {
      return {
        ...state,
        fgAnalysisData: action.dummydata
      };
    }

    default:
      return state;
  }
};
