import { ACTION_TYPES, RedirectToStoreType } from '../store';

export function redirectToReducer(state = '', action: RedirectToStoreType): string {
  switch (action.type) {
    case ACTION_TYPES.SET_REDIRECT_TO:
      return action.redirectToRoute;
    default:
      return state;
  }
}

export default redirectToReducer;
