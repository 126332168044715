import { Reducer } from 'redux';
import { GET_IR_DETAILS, RE_SET_REDUCER, IRAnalysisState } from './V2-IllustrativeFirp.types';

const initialState: IRAnalysisState = {
  IRAnalysisData: []
};

export const V2IlustrativeFirpReportReducer: Reducer<any, any> = (
  state: IRAnalysisState = initialState,
  action: any
) => {
  switch (action.type) {
    case GET_IR_DETAILS: {
      return { ...state, IRAnalysisData: action.data };
    }

    case RE_SET_REDUCER: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
