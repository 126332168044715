import {
  IPO_ORIGIN,
  OLD_IPO_SCANNER_ROUTE_PART,
  SELF_ASSESSMENT_ORIGIN,
  checkIsOldIPOScannerPathname
} from './check-is-ipo-scanner-route';
import { SECURE_CODE_ROUTE_PART } from './check-is-ipo-secure-code-route';

const getCorrectSelfAssesPathName = (pathname: string, isOldIPOHost: boolean) => {
  const isSecureRoute = pathname.startsWith(SECURE_CODE_ROUTE_PART);

  let [assetName, countryCode, ...rest] = pathname.split('/').slice(1 + Number(isSecureRoute));

  if (isOldIPOHost) {
    // INFO: old host has pathName '/:countryCode/...rest'
    // so assetName is a countryCode in this case
    [assetName, countryCode] = ['ipo', assetName];
  }

  assetName ||= 'ipo';
  countryCode ||= 'us';

  return [isSecureRoute ? SECURE_CODE_ROUTE_PART : '', assetName, countryCode, ...rest].join('/');
};

export const checkSelfAssesAppUrlWithRedirection = () => {
  const currentURL = new URL(window.location.href);
  const selfAssesURL = new URL(SELF_ASSESSMENT_ORIGIN);

  if (currentURL.origin === selfAssesURL.origin) {
    const newPathname = getCorrectSelfAssesPathName(currentURL.pathname, false);

    if (newPathname === currentURL.pathname) return;

    currentURL.pathname = newPathname;

    return window.location.replace(currentURL);
  }

  // INFO: redirection from old IPO host to new one
  if (currentURL.origin === IPO_ORIGIN) {
    currentURL.host = selfAssesURL.host;
    currentURL.pathname = getCorrectSelfAssesPathName(currentURL.pathname, true);
    return window.location.replace(currentURL);
  }

  // INFO: at the beginning IPO app was on the same host as Navigator app
  // and we managed them by OLD_IPO_SCANNER_ROUTE_PART in url pathname
  // {origin}/...rest - Navigator app
  // {origin}{OLD_IPO_SCANNER_ROUTE_PART}/...rest or {origin}/auth{OLD_IPO_SCANNER_ROUTE_PART}/...rest - IPO app
  if (checkIsOldIPOScannerPathname()) {
    currentURL.host = selfAssesURL.host;
    currentURL.pathname = currentURL.pathname.replace(OLD_IPO_SCANNER_ROUTE_PART, '/ipo/us');
    return window.location.replace(currentURL);
  }
};
