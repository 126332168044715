import { FC, useLayoutEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { ROUTES } from '../../constants';
import { setIsAdminToolAC } from '../../store/is-admin-tool';
import {
  getPrevServiceAreaFromLocalStorage,
  getRouteWithServiceArea,
  serviceAreaValue,
  setServiceAreaToLocalStorage
} from '../layout/landing/service-area-blocks/ServiceArea.helpers';

import Services from './../../shared.services';

const ProtectedRoute: FC<any> = ({
  component: CustomComponent,
  accessPredicate,
  role,
  child: ComponentChild,
  redirectUrl,
  ...props
}) => {
  const dispatch = useDispatch();
  const hasAccess = accessPredicate();

  useLayoutEffect(() => {
    if (hasAccess) {
      const isAdminTool = role === 'admin';
      dispatch(setIsAdminToolAC(isAdminTool));
      localStorage.setItem('fromAdminAccess', `${isAdminTool}`);
    }
  }, [dispatch, hasAccess, role]);

  if (props.path === ROUTES.dashboard) {
    const serviceArea = serviceAreaValue();
    if (!serviceArea) {
      setServiceAreaToLocalStorage(getPrevServiceAreaFromLocalStorage() || '');
    }

    return <Redirect to={getRouteWithServiceArea(ROUTES.dashboard)} />;
  }

  return (
    <Route
      {...props}
      render={(props) =>
        hasAccess ? (
          <CustomComponent {...props}>{ComponentChild ? <ComponentChild {...props} /> : null}</CustomComponent>
        ) : (
          <Redirect to={redirectUrl || Services.BL.RouteService.landingPageRoute} />
        )
      }
    />
  );
};

export default ProtectedRoute;
