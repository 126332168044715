import axios from 'axios';
import { ApiEntityE, RegionE } from '../shared/enums';
import IPOConfigService from './config.service';
import { MainApiService } from './main-api-service';

class IPOGeoService extends MainApiService {
  constructor() {
    super({ apiEntity: ApiEntityE.Geo, useMemberFirmInUrl: true });
  }

  getGeo = async (): Promise<RegionE> => {
    const { data } = await axios.get<RegionE>(this.defaultNewRegionApi);
    IPOConfigService.region = data;
    return data;
  };
}

export default new IPOGeoService();
