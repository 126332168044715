import classNames from 'classnames';
import { CSSProperties, FC, ReactNode, useCallback, useState } from 'react';
import { OverlayTrigger, OverlayTriggerProps, Tooltip } from 'react-bootstrap';
import './themed-tooltip.scss';

export interface ThemedTooltipPropsI extends Omit<OverlayTriggerProps, 'overlay'> {
  className?: string;
  tooltip: ReactNode;
  tooltipStyle?: CSSProperties;
  displayOnTooltipHover?: boolean;
}

// INFO: style prop to override absolute value to fix vertical scroll blinking
export const fixTooltipBlinkingScrollStyle: CSSProperties = {
  position: 'fixed'
};

const ThemedTooltip: FC<ThemedTooltipPropsI> = ({
  displayOnTooltipHover,
  tooltip,
  className,
  children,
  tooltipStyle,
  ...rest
}) => {
  const [showOnHover, setShowOnHover] = useState<boolean>(false);

  const onMouseOver = useCallback(() => {
    if (displayOnTooltipHover) {
      setShowOnHover(true);
    }
  }, [displayOnTooltipHover]);

  const onMouseOut = useCallback(() => setShowOnHover(false), []);

  return (
    <OverlayTrigger
      {...(showOnHover ? { show: true } : {})}
      overlay={
        <Tooltip
          onMouseOver={onMouseOver}
          onMouseOut={onMouseOut}
          className={classNames('themed-tooltip', className)}
          style={tooltipStyle}
        >
          {tooltip}
        </Tooltip>
      }
      {...rest}
    >
      {children}
    </OverlayTrigger>
  );
};

export default ThemedTooltip;
