import { Option } from './../../../../../components/ThemedSelect/ThemedSelect';

export type ServiceAreas =
  | 'Accounting & Reporting Advisory'
  | 'Accounting Operations Advisory'
  | 'Disruptive Events Advisory'
  | 'Business Assurance';

export enum EServicesAreasName {
  ACCOUNTING_AND_REPORTING = 'Accounting & Reporting Advisory',
  BUSINESS_ASSURANCE = 'Business Assurance',
  ACCOUNTING_OPERATION = 'Accounting Operations Advisory',
  DISRUPTIVE_EVENTS = 'Disruptive Events Advisory'
}

export const serviceAreaLocalStorageKey = 'serviceArea';
// INFO: for redirecting from landing to the same area
export const prevServiceAreaLocalStorageKey = 'prevServiceArea';

export const areasMap: Record<string, string> = {
  'Accounting & Reporting Advisory': 'accounting_reporting',
  'Accounting Operations Advisory': 'accounting_operations',
  'Disruptive Events Advisory': 'disruptive_events',
  'Business Assurance': 'business_assurance'
};

export const areaUrls = ['accounting_reporting', 'accounting_operations', 'disruptive_events', 'business_assurance'];

export const mapUrlToArea: Record<string, ServiceAreas> = {
  accounting_reporting: 'Accounting & Reporting Advisory',
  accounting_operations: 'Accounting Operations Advisory',
  disruptive_events: 'Disruptive Events Advisory',
  business_assurance: 'Business Assurance'
};

export const areasIdMap: Record<string, number> = {
  'Accounting & Reporting Advisory': 3,
  'Accounting Operations Advisory': 2,
  'Disruptive Events Advisory': 1,
  'Business Assurance': 4
};

export const serviceAreaOptions: Option[] = [
  {
    label: 'Accounting & Reporting Advisory',
    value: 'Accounting & Reporting Advisory'
  },
  {
    label: 'Accounting Operations Advisory',
    value: 'Accounting Operations Advisory'
  },
  {
    label: 'Disruptive Events Advisory',
    value: 'Disruptive Events Advisory'
  },
  {
    label: 'Business Assurance',
    value: 'Business Assurance'
  }
];

export const serviceAreaValue = () =>
  localStorage.getItem(serviceAreaLocalStorageKey) as ServiceAreas | '' | null | undefined;

export const getPrevServiceAreaFromLocalStorage = () =>
  localStorage.getItem(prevServiceAreaLocalStorageKey) as ServiceAreas | '' | null | undefined;

export const setPrevServiceAreaToLocalStorage = (value?: '') => {
  const current = serviceAreaValue();

  if (value === '' || !current) {
    return localStorage.removeItem(prevServiceAreaLocalStorageKey);
  } else {
    localStorage.setItem(prevServiceAreaLocalStorageKey, current);
  }
};

export const setServiceAreaToLocalStorage = (value: ServiceAreas | '') => {
  if (!value) {
    localStorage.removeItem(serviceAreaLocalStorageKey);
  } else {
    localStorage.setItem(serviceAreaLocalStorageKey, value);
    localStorage.setItem(prevServiceAreaLocalStorageKey, value);
  }
};
export const getServiceAreaUrlParam = (value?: ServiceAreas) => areasMap[value || serviceAreaValue() || ''];
export const getServiceAreaId = () => areasIdMap[serviceAreaValue() || 'Accounting & Reporting Advisory'];
export const getRouteWithServiceArea = (route: string) => `/${getServiceAreaUrlParam()}${route}`;
