import { useCallback } from 'react';
import { useTypedDispatch, useTypedSelector } from '../hooks';
import { selectAssetFullName } from './app-core.selector';
import { loadAppCoreData } from './app-core.thunk';

export const useSelectAssetFullName = () => useTypedSelector(selectAssetFullName);

export const useOnLoadAppCoreData = () => {
  const dispatch = useTypedDispatch();

  return useCallback(() => dispatch(loadAppCoreData()), [dispatch]);
};
