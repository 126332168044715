import axios from 'axios';
import { ApiEntityE } from '../shared/enums';
import { MainApiService } from './main-api-service';

class SelfAssessFileService extends MainApiService {
  constructor() {
    super({ apiEntity: ApiEntityE.File, useMemberFirmInUrl: true });
  }

  getFileById = async (id: number | string): Promise<Blob> => {
    if (!id) {
      return Promise.reject('File not fount');
    }
    const { data } = await axios.get<Blob>(`${this.defaultNewRegionApi}/${id}`, {
      responseType: 'blob'
    });
    return data;
  };
}

export default new SelfAssessFileService();
