import { useEffect, useRef } from 'react';
import Services from '../../../shared.services';
import { FileScanWSMessageDto } from '../../../models/models.dl';

export interface UseIPOScannerSettingsFileScanListenerPropsI {
  updateFileData: (data: FileScanWSMessageDto) => void;
}

export const useIPOScannerSettingsFileScanListener = ({
  updateFileData
}: UseIPOScannerSettingsFileScanListenerPropsI) => {
  const updateFileDataRef = useRef(updateFileData);
  updateFileDataRef.current = updateFileData;

  useEffect(() => {
    let connection: ReturnType<typeof Services.BL.SignalRService.getConnection>;
    connection = Services.BL.SignalRService.getConnection({
      url: 'ipoSettingsFileScan'
    });

    const start = async () => {
      try {
        await connection.start();
        connection.on('GetNotificationMessage', (msg: FileScanWSMessageDto) => {
          updateFileDataRef.current(msg);
        });
        await connection.invoke('Subscribe');
      } catch (err) {
        console.error('Connection failed: ', err);
      }
    };
    start();

    return () => {
      const stop = async () => {
        if (connection) {
          try {
            await connection.stop();
          } catch (err) {
            console.error('Disconnection failed: ', err);
          }
        }
      };
      stop();
    };
  }, []);
};
