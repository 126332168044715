import { IPOContactService, IPOQuestionnaireResultService, IPOQuestionnaireService } from '../../../services';
import { TypedThunk } from '../store.types';
import {
  setQuestionnaireResult,
  setQuestionnaireResultContactPhoto,
  setQuestionnaireResultLoading
} from './questionnaire-result.actions';
import { setQuestionnaireGlossarySettings } from '../questionnaire';

export const getQuestionnaireResult: TypedThunk<void> = () => {
  return async (dispatch) => {
    try {
      dispatch(setQuestionnaireResultLoading(true));
      const data = await IPOQuestionnaireResultService.fetchQuestionnaireResult();
      const glossarySettings = await IPOQuestionnaireService.fetchQuestionnaireGlossary(data.QuestionnaireUid);
      dispatch(setQuestionnaireGlossarySettings(glossarySettings));
      dispatch(setQuestionnaireResult(data));
      dispatch(setQuestionnaireResultLoading(false));
    } catch (error) {
      // dispatch(setQuestionnaireResultLoading(false));
      return Promise.reject(error);
    }
  };
};

export const getContactPhoto: TypedThunk<string> = (photoId) => {
  return async (dispatch, getState) => {
    const photo = getState().questionnaireResult.photo[photoId];
    if (photo) return;
    try {
      const data = await IPOContactService.getPhoto(photoId);
      dispatch(setQuestionnaireResultContactPhoto({ [photoId]: data }));
    } catch {}
  };
};
