import * as DE from './enums.dl';
import * as BE from './enums.bl';
import { IContactRecordValidation } from '../../ipo-scanner-app/shared/interfaces';
import { SelfAssessTypeDTO, SelfAssessTypeSettingsDTO } from '../interface';

export class PageSizeDto {
  constructor(public id: number, public name: string) {}
}

export class CaseTypeDto {
  constructor(public Id: string, public Name: string, public DisplayName: string) {}
}

export class MultiGeoMemberFirmDto {
  constructor(public Code: string, public Name: string) {}
}

export class MultiGeoDetailsDto {
  constructor(public Code: string, public IsMain: boolean, public MemberFirms: Array<MultiGeoMemberFirmDto>) {}
}

export class MemberFirmDto {
  constructor(public Id: string, public Name: string, public DisplayName: string, public CaseTypeId: string) {}
}

export class MemberDto {
  constructor(public Id: string, public Name: string, public DisplayName: string) {}
}

export class Users {
  constructor(
    public Uid: string,
    public preferredFullName: any,
    public email: string,
    public jobTitle: string,
    public CaseRoleId: number,
    public role: any
  ) {}
}

export class saveCaseType {
  constructor(
    public Id: string,
    public Name: string,
    public email: string,
    public DisplayName: string,
    public Code: string,
    public ISO3: string,
    public ISO2: string
  ) {}
}

export class ManageMemberDto {
  constructor(public Id: string, public Name: string, public DisplayName: string, public Role?: string) {}
}

export class PrimaryIndustryDto {
  constructor(public Id: string, public Name: string, public DisplayName: string) {}
}

export class CaseFilterDto {
  constructor(
    public SelectedMemberFirmId: number,
    public CaseStatus: Array<MemberFirmDto>,
    public MemberFirms: Array<MemberFirmDto>
  ) {}
}

export class CaseCreateDto {
  constructor(
    public CaseTypes: Array<MemberFirmDto>,
    public PrimaryIndustries: Array<MemberFirmDto>,
    public UserRoles: Array<MemberFirmDto>,
    public Countries: Array<MemberFirmDto>,
    public MemberFirm: Array<MemberFirmDto>
  ) {}
}

export class CaseDetailDto {
  constructor(
    public Id: string,
    public CaseName: string,
    public ClientName: string,
    public CaseTypeId: string,
    public PrimaryIndustryId: string,
    public CountryId: string,
    //public KeyContact: string,
    //public KeyAddress: string,
    public CreatedBy: string,
    public MemberFirm: string,
    public OtherMember: string,
    public MemberList: Array<Users>
  ) {}
}

export class CaseTypeDetailDto {
  constructor(
    public Id: string,
    public Name: string,
    public ClientName: string,
    public DisplayName: string,
    public Code: string,
    public ISO3: string,
    public ISO2: string
  ) {}
}

export class CreateCaseTypeDto {
  constructor(
    public CaseName: string,
    public Countries: Array<saveCaseType>,
    public CaseTypeCategoryId: number,
    public CopyfromCaseTypeId?: number
  ) {}
}

export class UpdateCaseTypeDto {
  constructor(public CaseTypeId: number, public CaseName: string, public Countries: Array<saveCaseType>) {}
}

export class TermsofUse {
  public VersionNumber: any;
  public Title: any;
  public TermsOfUseText: any;
  public IsLatestTermsofUseAccepted = false;
}

export class UserGetDto {
  public UserId = 0;
  public FirstName = '';
  public LastName = '';
  public EmailAddress = '';
  public RoleId: DE.UserRole;
  public IsLatestTermsOfUseAccepted = false;
  public ContainerCode = '';
  public CountryCode = '';
  public IsActive = false;
  public Locale = 'en-US';
  public MemberFirmCode = '';
  public OPMUID = '';
  public UID = '';

  constructor() {
    this.RoleId = 0;
  }
}

export class PagedDataDto<T> {
  constructor(public data: Array<T>, public page?: number, public pageSize?: number, public totalItemsCount?: number) {}
}

export class RegistrationListRequestDto {
  constructor(public countryId: number, public pageIndex: number, public pageSize: number) {}
}

export class PractitionerListItemDto {
  constructor(
    public registrationId: number,
    public userId: number,
    public registrationStatusId: BE.Status,
    public firstName: string,
    public lastName: string,
    public subscriberEmail: string,
    public referralName: string,
    public referralEmail: string,
    public countryId: number,
    public registrationSubmitted: Date,
    public lastDateAccessed: Date,
    public approverFirstName: string,
    public approverLastName: string
  ) {}
}

export class AdminListItemDto {
  constructor(
    public EmailAddress: string,
    public FirstName: string,
    public LastName: string,
    public RoleId: DE.UserRole,
    public CreatedOn: string,
    public UpdatedOn: string,
    public MemberFirmId: number
  ) {}
}

export class AdminOPMListItemDto {
  constructor(
    public email: string,
    public firstName: string,
    public lastName: string,
    public preferredFullName: string,
    public globalPersonUid: string,
    public globalPersonId: number,
    public jobTitle: string,
    public memberFirmCode: string,
    public countryCode: string
  ) {}
}

export type AdminOPMListItemWithRoleDto = AdminOPMListItemDto & { role: DE.UserRole };

export class UserRegistrationDto {
  constructor(
    public email: string,
    public firstName: string,
    public lastName: string,
    public countryId: number,
    public referralName: string,
    public referralEmail: string
  ) {}
}

export class RegistrationActionsRequestDto {
  constructor(public registrationId: number, public countryId: number) {}
}

/* exceptions */
export type ModelStateDto = { [fieldName: string]: Array<string> };

export class ExceptionDto {
  constructor(public message: string) {}
}

export class FormValidationExceptionDto extends ExceptionDto {
  constructor(public message: string, public modelState: ModelStateDto) {
    super(message);
  }
}

export class ExceptionResponseDto {
  constructor(public response: { data: ExceptionDto }) {}
}

export class FormValidationExceptionResponseDto {
  constructor(public response: { data: FormValidationExceptionDto; status: number }) {}
}

export class RegionCountryGetDto {
  constructor(public regionName: string, public countryId: number) {}
}

export class CountryGetDto {
  constructor(
    public countryId: number,
    public countryName: string,
    public regionName: string,
    public isOther: boolean
  ) {}
}

export class LanguageGetDto {
  constructor(
    public localeCode: string,
    public language: string,
    public nativeName: string,
    public englishName: string
  ) {}
}

export class ActiveLanguageDto {
  constructor(
    public localeCode: string,
    public language: string,
    public nativeName: string,
    public englishName: string
  ) {}
}

export class CaseListDto {
  constructor(
    public Id: number,
    public ClientName: number,
    public CaseName: BE.Status,
    public PrimaryName: string,
    public CountryName: string,
    public KeyAddress: string,
    public KeyContact: string,
    public MemberFirm: string,
    public OtherMember: number,
    public StatusName: Date,
    public CreatedBy: Date,
    public MemberList: any
  ) {}
}

export class FGReportPayloadDto {
  constructor(
    public moduleUID?: string,
    public caseId?: string,
    public questionaireId?: string,
    public reportId?: string,
    public caseUID?: string
  ) {}
}

export class RoadmapReportPayloadDto {
  constructor(public moduleUID?: string, public caseUID?: string) {}
}

export class SaveModuleDto {
  constructor(
    public Title: string,
    public ModuleTypeId: number,
    public CaseTypeId: number,
    public IsConfigurable: boolean,
    public UID?: any
  ) {}
}

export class SaveQuestionnaireDto {
  constructor(public Title: string, public ModuleUID: string, public OrderNumber: number) {}
}

export class SaveRoadmapTaskDto {
  constructor(public Title: string, public ModuleUID: string, public roadmapGanttJson: string) {}
}

export class CaseCategoryDto {
  constructor(public Name: string) {}
}

export interface ModuleFileScanWSMessageDto {
  FileMetadataId: number;
  ScanStatus: BE.FileScanStatus;
  ModuleUId: string;
  IsPublishable: boolean;
}

export interface FileScanWSMessageDto extends ModuleFileScanWSMessageDto {
  CaseTypeId: number;
  FileName: string;
  ModuleType: BE.ModuleType;
}

export interface DefaultDataRetentionPolicyDto {
  CompletedInactiveDaysCount: number;
  FirstNotificationDaysCount: number;
  HardDeletionDaysCount: number;
  InProgressInactiveDaysCount: number;
  SecondNotificationDaysCount: number;
}

export interface IPOReadinessSettingsResponseDto extends IPOReadinessSettingsLastUpdatedDto {
  Settings: SelfAssessTypeSettingsDTO[];
  FileMetadataInfos: FileScanWSMessageDto[];
}

export interface IPOReadinessCountryDto {
  Code: string;
  IpoReadiness: {
    Editable: boolean;
    ShowStates: boolean;
  };
  Name: string;
}

export interface IpoReadinessSettingsSaveBody {
  settings: SelfAssessTypeSettingsDTO[];
  modifiedDate: string;
}

export interface IpoReadinessSettingsCopyBody {
  copyFrom: string;
  copyTo: string;
  engagementTypeId: number;
}

export interface IPOReadinessSettingsLastUpdatedDto {
  ModifiedDate: string;
  ModifiedBy: string;
}

export interface IPOReadinessEngagementTypeDto {
  Id: number;
  Name: string;
  DisplayName: string;
}

export interface IPOReadinessTopicDto {
  Title: string;
}

export interface IPOReadinessCountryStates {
  Code: string;
  CountryId: number;
  Id: number;
  Name: string;
  OrderNumber: number;
}

export interface IPOReadinessGeneralInformationConfigDto {
  PrivacyStatementUrl: string;
}

export interface CustomDataRetentionPolicyDto {
  MemberFirmCodes: MultiGeoMemberFirmDto['Code'][];
  Values: DefaultDataRetentionPolicyDto;
}

export interface DataRetentionPolicyDto {
  Custom: CustomDataRetentionPolicyDto[];
  Default: DefaultDataRetentionPolicyDto;
  ModifiedBy: string | null;
  ModifiedDate: string;
}

export interface CaseTypeDisclaimerDto {
  CaseTypeId: number;
  IsDefault: boolean;
  IsEnabled: boolean;
  Text: string | null;
  Title: string | null;
}

export interface CaseTypeModuleImportFileErrorDto {
  ErrorMessage: string;
  FileName: string;
}

export interface CaseTypeModuleImportResponseDto {
  ErrorMessage: string;
  Success: boolean;
  FileErrors: CaseTypeModuleImportFileErrorDto[];
  ModuleUID: string;
}

export type IPOFileUploadResponseDto = Pick<CaseTypeModuleImportResponseDto, 'Success' | 'ErrorMessage'> & {
  FileMetadataId: number;
};

export interface SapReportDataRequestDTO {
  caseUID: string;
  moduleUID: string;
  questionnaires: string[];
}

export interface ISapReportData {
  AreaTitle: string;
  ChartValues: ISapReportChartValues;
  QuestionnaireName: string;
  QuestionnaireUID: string;
}

export interface ISapReportChartValues {
  'Sum of No': number[];
  'Sum of Yes': number[];
}
