import axios, { AxiosPromise } from 'axios';

import ApiUrlsService from './../bl/api-urls.service';

export default class ErrorLogDataService {
  static logError(body: any): AxiosPromise<any> {
    return axios.post(ApiUrlsService.logError(), { text: body });
  }

  // static testError401(): AxiosPromise<any> {
  //     return axios.get(ApiUrlsService.testError401(), {});
  // }
}
