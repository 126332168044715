export enum Status {
  Pending = 1,
  Active = 2,
  Rejected = 3,
  Cancelled = 4,
  Inactive = 5
}

export enum Step {
  first = 1,
  second,
  third
}

export enum HttpStatus {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Conflict = 409,
  PreconditionFailed = 412,
  ServerError = 500,
  ServiceUnavailable = 503
}

export enum TemplateTypes {
  SingleSelect = 1,
  MultiSelect = 2,
  Conversion = 3,
  TableInput = 4,
  Autocomplete = 5,
  ToggleSwitch = 6,
  MultiSelectCheckBox = 7,
  Slider = 8,
  SingleChoice = 9,
  MultipleChoice = 10,
  TextField = 11,
  SmartBox = 12,
  PreDefined = 13,
  CalendarPicker = 14
}

export enum QuestionTypes {
  single = 1,
  grouped = 2
}

export enum HATQuestionTypes {
  SINGLE_SELECT = 9,
  MULTI_SELECT = 10,
  TEXTFIELD = 11,
  SMARTBOX = 12,
  CALENDAR_PICKER = 14
}

export enum SelfAssessGeneralInfoTypes {
  SINGLE_LINE = 15,
  DROPDOWN_MENU = 16
}

export enum SAPReportQuestionTypes {
  SAP = 13
}

export enum QuestionnaireStatusName {
  INCOMPLETE = 'Incomplete',
  COMPLETE = 'Completed'
}

export enum QuestionFieldsName {
  ANSWER_STATEMENT = 'AnswerStatement',
  ANSWER_TEXT = 'AnswerText',
  VARIABLE_NAME = 'VariableName',
  QUESTION_TEXT = 'QuestionText',
  QUESTION_TOPIC_TEXT = 'QuestionTopic',
  QUESTION_GUIDANCE = 'Guidance'
}

export enum CaseTypeCategories {
  EVENT = 1,
  AOA = 2,
  HAT = 3
}

export enum CaseUserRole {
  OWNER = 1,
  EDITOR = 2
}

export enum PdfImageFontColor {
  WhiteColor = '#ffffff',
  BlackColor = '#000000'
}

export enum ModuleType {
  RADAR_GRAPH = 'Radar Graph',
  SCORECARD = 'Scorecard',
  ROADMAP = 'Roadmap',
  SUMMARY = 'Summary Report',
  TEMPLATE = 'Template',
  FGA = 'Financial Gap Analysis',
  SAP = 'Implementation Diagnostic Toolkit', // old SAP report
  IFI = 'Illustrative Financial Impact', // can be 'Illustrative Financial Impact ' with space at the end in module types response
  PROPOSAL = 'Proposal',
  FMM = 'Finance Maturity Model',
  IPO_SCANNER = 'SelfAssess',
  SELF_ASSESS_GENERAL_INFO = 'SelfAssess General Info'
}

export enum FileScanStatus {
  NOT_SCANNED = 'NotScanned',
  SCAN_IN_PROGRESS = 'ScanInProgress',
  SCAN_PASSED = 'ScanPassed',
  INFECTED = 'Infected',
  SCAN_ERROR = 'ScanError',
  SERVICE_UNAVAILABLE = 'ServiceUnavailable'
}

export enum DefinedAnswersType {
  EXISTING = 'Existing',
  COMPLETE = 'Complete',
  APPROPRIATE = 'Appropriate',
  RESPONSE = 'Finding Summary / Response',
  RISK = 'Risk'
}

export enum DefinedAnswersText {
  YES = 'Yes',
  NO = 'No',
  'N/A' = 'N/A'
}

export enum ButtonAction {
  OPEN = 'open',
  CLOSE = 'close',
  CONFIRM = 'confirm'
}

export enum QuestionnaireScoreColorCode {
  Red = '#DA291C',
  Amber = '#ED8B00',
  Yellow = '#FFCD00',
  Blue = '#00A3E0',
  Green = '#43B02A'
}

export enum StateId {
  Current = 1,
  Future = 2
}
