import React, { forwardRef, ForwardRefRenderFunction } from 'react';
import classNames from 'classnames';

import './ThemedButton.scss';

export type ButtonVariant = 'solid' | 'text' | 'outline' | 'with-icon' | 'with-icon-solid';
export type ButtonColor = 'blue' | 'grey' | 'red';
export type ButtonSize = 'large' | 'medium' | 'small';

export interface ThemedButtonPropsI
  extends React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  variant: ButtonVariant;
  color: ButtonColor;
  size: ButtonSize;
  icon?: React.ReactElement;
  active?: boolean;
  fullWidth?: boolean;
  uppercase?: boolean;
  nowrap?: boolean;
}

const ThemedButton: ForwardRefRenderFunction<HTMLButtonElement, ThemedButtonPropsI> = (
  {
    size = 'small',
    variant = 'solid',
    color = 'blue',
    type = 'button',
    active,
    icon,
    className,
    children,
    disabled,
    onClick,
    fullWidth,
    uppercase = true,
    nowrap,
    ...props
  },
  ref
) => {
  const handleClick: ThemedButtonPropsI['onClick'] = onClick
    ? (e) => {
        if (!disabled) {
          onClick(e);
        }
      }
    : undefined;

  return (
    <button
      ref={ref}
      type={type}
      disabled={disabled}
      onClick={handleClick}
      className={classNames(
        'themed-button',
        `themed-button__${variant}`,
        size,
        color,
        {
          ['themed-button__active']: active,
          ['themed-button__full-width']: fullWidth,
          ['themed-button__uppercase']: uppercase,
          ['themed-button__nowrap']: nowrap
        },
        className
      )}
      {...props}
    >
      {(variant === 'with-icon' || variant === 'with-icon-solid') && (
        <span className="themed-button__icon-wrapper">{icon}</span>
      )}
      {children}
    </button>
  );
};

export default forwardRef<HTMLButtonElement, ThemedButtonPropsI>(ThemedButton);
