import {
  SelectAnswerActionI,
  SelectCountryStateIdActionI,
  SetGeneralInfoConfigActionI,
  SetGeneralInfoConfigLoadingActionI
} from './general-info-config.interface';
import { SELECT_ANSWER, SELECT_COUNTRY_STATE_ID, SET_CONFIG, SET_CONFIG_LOADING } from './general-info-config.types';

export const setGeneralInfoConfig = (payload: SetGeneralInfoConfigActionI['payload']): SetGeneralInfoConfigActionI => ({
  type: SET_CONFIG,
  payload
});

export const setGeneralInfoConfigLoading = (
  payload: SetGeneralInfoConfigLoadingActionI['payload']
): SetGeneralInfoConfigLoadingActionI => ({
  type: SET_CONFIG_LOADING,
  payload
});

export const selectAnswer = (payload: SelectAnswerActionI['payload']): SelectAnswerActionI => ({
  type: SELECT_ANSWER,
  payload
});

export const selectCountryStateId = (payload: SelectCountryStateIdActionI['payload']): SelectCountryStateIdActionI => ({
  type: SELECT_COUNTRY_STATE_ID,
  payload
});
