import { ACTION_TYPES, isCustomLoadingLogicType, IsLoadingStoreType, loaderAction, loadingState } from '../store';

const initialState: loadingState = {
  isLoading: false,
  isCustomLoadingLogic: false
};

export function loaderReducer(state: loadingState = initialState, action: loaderAction): loadingState {
  switch (action.type) {
    case ACTION_TYPES.IS_LOADING:
      return { ...state, isLoading: (action as IsLoadingStoreType).isLoading };

    case ACTION_TYPES.IS_CUSTOM_LOADING_LOGIC:
      return { ...state, isCustomLoadingLogic: (action as isCustomLoadingLogicType).isCustomLoadingLogic };

    default:
      return state;
  }
}

export default loaderReducer;
