import { Option } from '../../../../components/ThemedSelect/ThemedSelect';
import { SelfAssessCountryStateDTO } from '../../../interface';
import { SetAssetsCountryStatesAction } from '../self-assessment-assets.actions';
import { SelfAssessmentAssetsReducer, SelfAssessmentAssetsState } from '../self-assessment-assets.interfaces';
import { setAssetsCountryStatesFetchingReducer } from './setPendingState.reducer';

export const getSelfAssesCountryStateOption = (countryState: SelfAssessCountryStateDTO): Option => {
  const label = countryState.Name || countryState.Code;
  return {
    value: `${countryState.Id}`,
    label,
    customValue: countryState,
    searchValue: label
  };
};

const normalizeList = (list: SelfAssessCountryStateDTO[]) => {
  return list.reduce<
    NonNullable<
      SelfAssessmentAssetsState['assetsCountriesStates'][keyof SelfAssessmentAssetsState['assetsCountriesStates']]
    >
  >(
    (acc, item) => {
      if (item.Id) {
        const option = getSelfAssesCountryStateOption(item);
        acc.data[item.Id] = item;
        acc.ids.ipo.push(item.Id);
        acc.selectorOptions.ipo.push(option);
        // INFO: removed by https://dev.azure.com/symphonyvsts/GA%20Navigator/_workitems/edit/4140060
        const disabledForCustomAsset = item.Name === 'Other (non-US)';
        if (!disabledForCustomAsset) {
          acc.ids.custom.push(item.Id);
          acc.selectorOptions.custom.push(option);
        }
      }
      return acc;
    },
    { ids: { ipo: [], custom: [] }, selectorOptions: { ipo: [], custom: [] }, data: {} }
  );
};

export const setAssetCountriesStatesReducer: SelfAssessmentAssetsReducer<SetAssetsCountryStatesAction> = (
  state,
  payload
) => {
  let newState = setAssetsCountryStatesFetchingReducer(state, false);

  const { countryCode, states } = payload;
  const currentStatesData = newState.assetsCountriesStates[countryCode];

  if (currentStatesData?.ids.ipo.length || states.length) {
    if (newState === state) {
      newState = { ...state };
    }
    newState.assetsCountriesStates = {
      ...newState.assetsCountriesStates,
      [countryCode]: normalizeList(states)
    };
  }

  return newState;
};
