import { Reducer } from 'redux';
import { GET_SCORECARD_REPORT, RE_SET_REDUCER, reportState } from './report.types';

const initialState: reportState = {
  scorecardReport: []
};

export const scorecardReportReducer: Reducer<any, any> = (state: reportState = initialState, action: any) => {
  switch (action.type) {
    case GET_SCORECARD_REPORT: {
      return { ...state, scorecardReport: action.data };
    }

    case RE_SET_REDUCER: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
