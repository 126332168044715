import { FILE_SCAN_STATUS_DESCRIPTION, FILE_SCAN_STATUS_ICON } from '../constants/file-scan.constants';
import { FileScanStatus } from '../models/enums.bl';

export const checkFileScanStatus = (status?: FileScanStatus) => {
  const notScanned = status === FileScanStatus.NOT_SCANNED;
  const scanInProgress = status === FileScanStatus.SCAN_IN_PROGRESS;
  const infected = status === FileScanStatus.INFECTED;
  const scanError = status === FileScanStatus.SCAN_ERROR;
  const serviceUnavailable = status === FileScanStatus.SERVICE_UNAVAILABLE;

  return {
    notScanned,
    scanInProgress,
    scanPassed: status === FileScanStatus.SCAN_PASSED,
    infected,
    scanError,
    serviceUnavailable,
    someError: infected || scanError || serviceUnavailable,
    inProgressOrNotScanned: scanInProgress || notScanned
  };
};

export const getFileScanStatusDescription = (status?: FileScanStatus) => {
  if (!status) return '';

  return FILE_SCAN_STATUS_DESCRIPTION[status] || '';
};

export const getFileScanStatusIcon = (status?: FileScanStatus) => {
  if (!status) return null;

  return FILE_SCAN_STATUS_ICON[status] || null;
};
