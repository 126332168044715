import React from 'react';

const ToastSuccessIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0ZM25.8536 10.1464C26.0271 10.32 26.0464 10.5894 25.9114 10.7843L25.8536 10.8536L13.8536 22.8536C13.68 23.0271 13.4106 23.0464 13.2157 22.9114L13.1464 22.8536L7.14645 16.8536C6.95118 16.6583 6.95118 16.3417 7.14645 16.1464C7.32001 15.9729 7.58944 15.9536 7.78431 16.0886L7.85355 16.1464L13.5 21.793L25.1464 10.1464C25.3417 9.95118 25.6583 9.95118 25.8536 10.1464Z"
        fill="#26890D"
      />
    </svg>
  );
};

export default ToastSuccessIcon;
