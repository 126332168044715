import { MemberFirmDto, MultiGeoDetailsDto } from '../../../shared/models/models.dl';

export const SET_MEMBER_FIRMS = 'SET_MEMBERFIRMS';
export const SET_MEMBERFIRM = 'SET_MEMBERFIRM';
export const SET_MEMBER_FIRM_DETAILS = 'SET_MEMBER_FIRM_DETAILS';
export const GET_CASES = 'GET_CASES';
export const GET_DELETED_CASES = 'GET_DELETED_CASES';
export const SET_CLIENTNAME = 'SET_CLIENTNAME';
export const SET_ISLOADING = 'SET_ISLOADING';
export const SET_CASENAME = 'SET_CASENAME';
export const SET_CASETYPE = 'SET_CASETYPE';
export const SET_DELETED_CASETYPE = 'SET_DELETED_CASETYPE';
export const SET_DELETED_CLIENTNAME = 'SET_DELETED_CLIENTNAME';
export const SET_DELETED_CASENAME = 'SET_DELETED_CASENAME';
export const APPLY_SORTING = 'APPLY_SORTING';
export const RESET_SELECT_DATA = 'RESET_SELECT_DATA';
export const RESET_SORT_DETAILS = 'RESET_SORT_DETAILS';
export const SET_LOADING_SELECT_DATA = 'SET_LOADING_SELECT_DATA';

export type dashBoardFilter = Readonly<{
  clientName: Array<string>;
  caseName: Array<string>;
  caseType: Array<string>;
  status: Array<string>;
  dateAdded?: Date;
  memberFirmId: number;
  CaseTypeCategory: number;
}>;

export type dashboardPageDetail = Readonly<{
  pageNo: number;
  pageSize: number;
}>;

export type sortCol = Readonly<{
  sortColumn: string;
  sortDirection: string;
  sortActiveId: number;
}>;

export type pageDetail = Readonly<{
  pageNo: number;
  pageSize: number;
}>;

export type payLoad = Readonly<{
  pageDetail: pageDetail;
  sortDetails: sortCol;
  filterDetails: dashBoardFilter;
}>;

export type CaseDetails = Readonly<{
  CaseDetails: Array<string>;
  TotalRecords: number;
}>;

export type typeAhead = Readonly<{
  clientName: Array<string>;
  caseName: Array<string>;
  caseType: Array<string>;
  deletedCaseType: Array<string>;
  deletedClientName: Array<string>;
  deletedCaseName: Array<string>;
}>;

export type dashBoardState = Readonly<{
  isLoading: boolean;
  isSelectDataLoading: boolean;
  cases: CaseDetails;
  payLoad: payLoad;
  typeAhead: typeAhead;
  memberFirms: MemberFirmDto | [];
  memberFirm: number;
  memberFirmDetails: MultiGeoDetailsDto[] | null;
}>;
