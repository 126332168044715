import * as Models from '../../shared.models';

export const currentUserStorage = localStorage;
export const lookupsStorage = localStorage;
export const languageStorage = localStorage;

export enum StoreName {
  CurrentUser = 'currentUser',
  Loader = 'loader',
  Lookups = 'lookups',
  RedirectTo = 'redirectTo',
  IsAdminTool = 'isAdminTool',
  AllLanguages = 'languages',
  CurrentLanguage = 'language',
  NoLanguage = 'noLanguage',
  MailSent = 'mailSent',
  SelfAssessmentAssets = 'selfAssessmentAssets'
}

export const ACTION_TYPES = {
  IS_CUSTOM_LOADING_LOGIC: 'IS_CUSTOM_LOADING_LOGIC',
  SET_CURRENT_USER: 'SET_CURRENT_USER',
  SET_CURRENT_USER_TOKEN: 'SET_CURRENT_USER_TOKEN',
  IS_LOADING: 'IS_LOADING',
  SET_COUNTRIES: 'SET_COUNTRIES',
  // SET_REGION_COUNTRIES: 'SET_REGION_COUNTRIES'
  SET_REGION_COUNTRIES: 'SET_REGION_COUNTRIES',
  SET_REDIRECT_TO: 'SET_REDIRECT_TO',
  SET_LANGUAGES: 'SET_LANGUAGES',
  ACTIVE_LANGUAGE: 'ACTIVE_LANGUAGE',
  NO_LANGUAGE: 'NO_LANGUAGE',
  MAIL_SENT: 'MAIL_SENT'
};

export type CurrentUserStoreType = {
  type: typeof ACTION_TYPES.SET_CURRENT_USER;
  profile: Models.BM.UserProfile;
};

export type IsLoadingStoreType = {
  type: typeof ACTION_TYPES.IS_CUSTOM_LOADING_LOGIC;
  isLoading: boolean;
};

export type isCustomLoadingLogicType = {
  type: typeof ACTION_TYPES.IS_LOADING;
  isCustomLoadingLogic: boolean;
};

export type loaderAction = IsLoadingStoreType | isCustomLoadingLogicType;

export type loadingState = {
  isLoading: boolean;
  isCustomLoadingLogic: boolean;
};

export type LookupsStoreType = {
  type: typeof ACTION_TYPES.SET_COUNTRIES;
  lookups: Models.BM.Lookups;
};

export type RedirectToStoreType = {
  type: typeof ACTION_TYPES.SET_REDIRECT_TO;
  redirectToRoute: string;
};

export type LanguagesStoreType = {
  type: typeof ACTION_TYPES.SET_LANGUAGES;
  languageLookups: Models.BM.LanguageLookups;
};

export type ActiveLanguageStoreType = {
  type: typeof ACTION_TYPES.ACTIVE_LANGUAGE;
  activeLanguage: Models.BM.ActiveLanguage;
};

export type languageUnavailStoreType = {
  type: typeof ACTION_TYPES.NO_LANGUAGE;
  languageUnavail: string;
};

export type emailSentStoreType = {
  type: typeof ACTION_TYPES.MAIL_SENT;
  emailSent: string;
};
