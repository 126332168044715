import axios, { AxiosPromise, AxiosResponse } from 'axios';

import * as DM from '../../models/models.dl';
import ApiUrlsService from '../bl/api-urls.service';

export default class PractitionerDataService {
  static save(body: DM.UserRegistrationDto, regionName: string): AxiosPromise<any> {
    return axios.post(ApiUrlsService.registrationSave(regionName), body);
  }

  static getUrlsCheckIsUserRegistered(url: string, email: string): AxiosPromise<boolean> {
    return axios.post(url, { emailAddress: email });
  }

  static getList(
    body: DM.RegistrationListRequestDto,
    regionName: string
  ): Promise<DM.PagedDataDto<DM.PractitionerListItemDto>> {
    return axios
      .post(ApiUrlsService.registrationList(regionName), body)
      .then((result: AxiosResponse<DM.PagedDataDto<DM.PractitionerListItemDto>>) => result.data);
  }
}
