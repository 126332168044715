import { Reducer } from 'redux';
import {
  setAssetCaseTypePrimarySectorsReducer,
  setAssetCountriesReducer,
  setAssetCountriesStatesReducer,
  setAssetCreatingReducer,
  setAssetDeletingReducer,
  setAssetListReducer,
  setAssetUpdatingReducer,
  setAssetsCaseTypePrimarySectorsFetchingReducer,
  setAssetsCountriesFetchingReducer,
  setAssetsCountryStatesFetchingReducer,
  setAssetsListFetchingReducer,
  setSelectedAssetIdReducer,
  setSidebarAccordionState
} from './reducers';
import type { SelfAssessmentAssetsAction } from './self-assessment-assets.actions';
import { selfAssessmentAssetsState } from './self-assessment-assets.constants';
import { SelfAssessmentAssetsState } from './self-assessment-assets.interfaces';
import {
  SET_ASSETS_CASE_TYPE_PRIMARY_SECTORS,
  SET_ASSETS_CASE_TYPE_PRIMARY_SECTORS_FETCHING,
  SET_ASSETS_COUNTRIES,
  SET_ASSETS_COUNTRIES_FETCHING,
  SET_ASSETS_COUNTRY_STATES,
  SET_ASSETS_COUNTRY_STATES_FETCHING,
  SET_ASSETS_LIST,
  SET_ASSETS_LIST_FETCHING,
  SET_ASSET_CREATING,
  SET_ASSET_DELETING,
  SET_ASSET_UPDATING,
  SET_SELECTED_ASSET_ID,
  SET_SIDEBAR_ACCORDION_STATE
} from './self-assessment-assets.types';

export const selfAssessmentAssetsReducer: Reducer<SelfAssessmentAssetsState, SelfAssessmentAssetsAction> = (
  state = selfAssessmentAssetsState,
  { type, payload }
) => {
  switch (type) {
    case SET_ASSETS_LIST:
      return setAssetListReducer(state, payload);
    case SET_SELECTED_ASSET_ID:
      return setSelectedAssetIdReducer(state, payload);
    case SET_ASSETS_LIST_FETCHING:
      return setAssetsListFetchingReducer(state, payload);
    case SET_ASSETS_COUNTRIES:
      return setAssetCountriesReducer(state, payload);
    case SET_ASSETS_COUNTRIES_FETCHING:
      return setAssetsCountriesFetchingReducer(state, payload);
    case SET_ASSETS_COUNTRY_STATES:
      return setAssetCountriesStatesReducer(state, payload);
    case SET_ASSETS_COUNTRY_STATES_FETCHING:
      return setAssetsCountryStatesFetchingReducer(state, payload);
    case SET_ASSET_CREATING:
      return setAssetCreatingReducer(state, payload);
    case SET_ASSET_UPDATING:
      return setAssetUpdatingReducer(state, payload);
    case SET_ASSET_DELETING:
      return setAssetDeletingReducer(state, payload);
    case SET_SIDEBAR_ACCORDION_STATE:
      return setSidebarAccordionState(state, payload);
    case SET_ASSETS_CASE_TYPE_PRIMARY_SECTORS:
      return setAssetCaseTypePrimarySectorsReducer(state, payload);
    case SET_ASSETS_CASE_TYPE_PRIMARY_SECTORS_FETCHING:
      return setAssetsCaseTypePrimarySectorsFetchingReducer(state, payload);
    default: {
      return state;
    }
  }
};
