import { UserRole } from './../../models/enums.dl';
import moment from 'moment';
import { getAdminFullName } from '../../utils/get-user-full-name';
import { AdminListItemDto } from '../../models/models.dl';
import { getAdminRoleName } from '../../utils/admin-role.utils';
import {
  AdminListPageDataT,
  AdminListSortDataT,
  AdminListStateT,
  AdminSortFieldT,
  ContentAdminI,
  SortDirectionT
} from './admins.types';
import { Option } from '../../../components/ThemedSelect/ThemedSelect';
import { CaseTypeContentAdminI } from '../../interface';

const getValueForSorting = (user: AdminListItemDto, key: AdminSortFieldT): string => {
  switch (key) {
    case 'RoleId': {
      const value = getAdminRoleName(user[key]);
      return value ? value.toLowerCase() : '';
    }
    case 'userName': {
      const value = getAdminFullName(user);
      return value ? value.toLowerCase() : '';
    }
    case 'CreatedOn': {
      return user[key] ? moment(user[key]).format('x') : '';
    }
    case 'EmailAddress': {
      return user[key] ? user[key].toLowerCase() : '';
    }
    default:
      return '';
  }
};

const getValueForSearch = (user: AdminListItemDto, key: AdminSortFieldT): string => {
  switch (key) {
    case 'CreatedOn': {
      return user[key] ? moment(user[key]).format('MM/DD/YYYY') : '';
    }
    default:
      return getValueForSorting(user, key);
  }
};

const checkSearch = (search: string, values: (string | number)[]) => {
  return (
    !search ||
    values.some((value) => (!!value || value === 0 ? `${value}` : '').toLowerCase().includes(search.toLowerCase()))
  );
};

export const filterAdmin = (search: string) => (user: AdminListItemDto) => {
  if (!search.trim()) return true;
  const fullName = getValueForSearch(user, 'userName');
  const roleName = getValueForSearch(user, 'RoleId');
  const createdDate = getValueForSearch(user, 'CreatedOn');
  const email = getValueForSearch(user, 'EmailAddress');

  return checkSearch(search, [fullName, roleName, createdDate, email]);
};

export const filterAdminList = (items: AdminListItemDto[], search: string) =>
  !items.length || !search.trim() ? items : items.filter(filterAdmin(search));

const checkIsGlobalAdmin = (role: UserRole) => role === UserRole.GlobalAdmin;

export const getFilteredAdminListAndTotalGlobalAdmins = (items: AdminListItemDto[], search: string) =>
  items.reduce<{
    totalGlobalAdmins: number;
    filteredList: AdminListItemDto[];
  }>(
    (acc, item) => {
      if (checkIsGlobalAdmin(item.RoleId)) {
        acc.totalGlobalAdmins += 1;
      }
      if (filterAdmin(search)(item)) {
        acc.filteredList.push(item);
      }

      return acc;
    },
    { totalGlobalAdmins: 0, filteredList: [] }
  );

export const sortAdmins =
  (sortField: AdminSortFieldT, sortDirection: SortDirectionT) => (a: AdminListItemDto, b: AdminListItemDto) => {
    const valueA = getValueForSorting(a, sortField);
    const valueB = getValueForSorting(b, sortField);

    const result = valueA.localeCompare(valueB, undefined, { numeric: true });

    return sortDirection === 'asc' ? result : -1 * result;
  };

export const chooseCorrectArrayForState = <T>(data: Record<'new' | 'old', T[]>): T[] =>
  data.new.length || data.old.length ? data.new : data.old;

export const setAdminsPageDataToNewState = (newState: AdminListStateT, { pageIdx, pageSize }: AdminListPageDataT) => {
  const pages = Math.ceil(newState.totalRecords / (pageSize || 1));
  const maxPageIdx = pages ? pages - 1 : 0;
  const correctPageIdx = Math.min(maxPageIdx, pageIdx);
  const startIdx = correctPageIdx * pageSize;
  const endIdx = startIdx + pageSize;
  const displayList = newState.filteredList.slice(startIdx, endIdx);

  newState.pageIdx = correctPageIdx;
  newState.pageSize = pageSize;
  newState.displayList = chooseCorrectArrayForState({ new: displayList, old: newState.displayList });
};

export const setAdminsSortDataToNewState = (
  newState: AdminListStateT,
  { sortColumnIdx, sortColumnName, sortDirection }: AdminListSortDataT
) => {
  newState.sortColumnIdx = sortColumnIdx;
  newState.sortColumnName = sortColumnName;
  newState.sortDirection = sortDirection;

  if (newState.filteredList.length > 1) {
    newState.filteredList = [...newState.filteredList].sort(
      sortAdmins(newState.sortColumnName, newState.sortDirection)
    );
  }
};

export const transformContentAdminsToOptions = (contentAdmins: ContentAdminI[] | CaseTypeContentAdminI[]): Option[] => {
  return contentAdmins.map(({ UserUID, FirstName, LastName, EmailAddress }) => {
    const label = `${FirstName} ${LastName}`;
    const searchValue = EmailAddress ? `${label} ${EmailAddress}` : label;
    return {
      value: UserUID,
      label,
      searchValue
    };
  });
};
