import React from 'react';
import './footer.scss';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container-fluid footer__content">
        <p>
          &copy;{new Date().getFullYear()} Deloitte. All rights reserved. See&nbsp;
          {/* <a
            href="https://www.km.deloitteresources.com/sites/live/audit/_layouts/DTTS.DR.KAMDocumentForms/KAMDisplay.aspx?List=c34e2990-1e93-4bce-9af9-0e4c21c16e50&ID=72616"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Use
          </a>
          ,&nbsp; */}
          <a href="./privacy.pdf" target="_blank" rel="noopener noreferrer">
            Privacy
          </a>
          ,&nbsp;
          <button id="ot-sdk-btn" className="footer__cookies-link ot-sdk-show-settings">
            Customise Cookies
          </button>
          ,&nbsp;
          <a href="./cookie_notice.pdf" target="_blank">
            Cookie Notice
          </a>
          &nbsp;and&nbsp;
          <a href="./notices.pdf" target="_blank" rel="noopener noreferrer">
            Notices
          </a>
          &nbsp;for more information. Deloitte refers to one or more of Deloitte Touche Tohmatsu Limited ("DTTL"), its
          global network of member firms, and their related entities (collectively, the "Deloitte organization"). DTTL
          (also referred to as "Deloitte Global") and each of its member firms and related entities are legally separate
          and independent entities, which cannot obligate or bind each other in respect of third parties. DTTL and each
          DTTL member firm and related entity is liable only for its own acts and omissions, and not those of each
          other. DTTL does not provide services to clients. Please see{' '}
          <a
            href="https://www2.deloitte.com/global/en/pages/about-deloitte/articles/about-the-network.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            www.deloitte.com/about
          </a>{' '}
          to learn more.
        </p>
      </div>
    </footer>
  );
};

export default Footer;
