import {
  SetQuestionnaireActionI,
  SelectAnswerActionI,
  SetQuestionnaireLoadingActionI,
  ClearQuestionnaireStateActionI,
  SetQuestionnaireGlossarySettingActionI
} from './questionnaire.interface';
import {
  SELECT_ANSWER,
  SET_QUESTIONNAIRE,
  SET_LOADING,
  CLEAR_STATE,
  SET_QUESTIONNAIRE_GLOSSARY_SETTINGS
} from './questionnaire.types';

export const setQuestionnaire = (payload: SetQuestionnaireActionI['payload']): SetQuestionnaireActionI => ({
  type: SET_QUESTIONNAIRE,
  payload
});

export const setQuestionnaireGlossarySettings = (
  payload: SetQuestionnaireGlossarySettingActionI['payload']
): SetQuestionnaireGlossarySettingActionI => ({
  type: SET_QUESTIONNAIRE_GLOSSARY_SETTINGS,
  payload
});

export const setQuestionnaireLoading = (
  payload: SetQuestionnaireLoadingActionI['payload']
): SetQuestionnaireLoadingActionI => ({
  type: SET_LOADING,
  payload
});

export const clearQuestionnaireState = (): ClearQuestionnaireStateActionI => ({
  type: CLEAR_STATE
});

export const selectAnswer = (payload: SelectAnswerActionI['payload']): SelectAnswerActionI => ({
  type: SELECT_ANSWER,
  payload
});
