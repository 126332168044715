import { AuthenticationResult, EventType, InteractionType, PublicClientApplication } from '@azure/msal-browser';
import { AuthServiceUser } from '../shared/models/models.bl';
import { msalConfig, msalLoginRequest } from './msal-auth.config';

class MsalAuthService {
  private clientInstance: PublicClientApplication;

  constructor() {
    this.clientInstance = new PublicClientApplication(msalConfig);
  }

  get client() {
    return this.clientInstance;
  }

  listenUserLoadedEvent = (cb: (data: AuthServiceUser) => void) => {
    this.client.addEventCallback((event) => {
      if (!event.payload) return;

      const isLoginSuccess = event.eventType === EventType.LOGIN_SUCCESS;
      const isAcquireTokenSuccess = event.eventType === EventType.ACQUIRE_TOKEN_SUCCESS;

      if (isLoginSuccess || isAcquireTokenSuccess) {
        if (isAcquireTokenSuccess && event.interactionType === InteractionType.Silent) {
          localStorage.setItem('isRefreshToken', 'true');
        }
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        this.client.setActiveAccount(account);

        cb(this.getAuthServiceUser(payload));
      }
    });
  };

  login = () => {
    this.client.loginRedirect(msalLoginRequest);
  };

  logout = () => {
    const logoutRequest = {
      account: this.client.getActiveAccount(),
      postLogoutRedirectUri: msalConfig.auth.postLogoutRedirectUri,
      mainWindowRedirectUri: msalConfig.auth.postLogoutRedirectUri
    };
    this.client.logoutRedirect(logoutRequest);
  };

  refreshTokenSilent = async () => {
    const account = this.client.getActiveAccount() || undefined;

    try {
      const data = await this.client.acquireTokenSilent({ ...msalLoginRequest, account });
      return this.getTokensFromAuthResult(data).accessToken;
    } catch {
      return '';
    }
  };

  private getMSALUser(data: AuthenticationResult) {
    // INFO: used accessToken to get correct unique_name
    return this.decodeToken(data.accessToken);
  }

  private getTokensFromAuthResult({ accessToken, idToken }: AuthenticationResult) {
    // INFO: used idToken as accessToken because accessToken has wrong Audience value instead of idToken
    return {
      idToken: accessToken,
      accessToken: idToken
    };
  }

  private getAuthServiceUser(data: AuthenticationResult): AuthServiceUser {
    const { unique_name } = this.getMSALUser(data);
    const { idToken, accessToken } = this.getTokensFromAuthResult(data);
    return new AuthServiceUser(idToken, unique_name, accessToken, true);
  }

  private decodeToken(token: string): Record<string, any> {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');

    return JSON.parse(window.atob(base64));
  }
}

export default new MsalAuthService();
