export const GET_QUESTIONNAIRE_REPORTS = 'GET_QUESTIONNAIRE_REPORTS';
export const GET_QUESTIONNAIRE = 'GET_QUESTIONNAIRE_QUESTIONS';
export const SET_CURRENT_QUESTION = 'SET_CURRENT_QUESTION';
export const GO_NEXT_AND_PREVIOUS_QUESTION = 'GO_NEXT_AND_PREVIOUS_QUESTION';
export const ON_ANSWER_CHANGE = 'ON_ANSWER_CHANGE';
export const UPDATE_QUESTIONNAIRE_STEPPER = 'UPDATE_QUESTIONNAIRE_STEPPER';
export const RE_SET_REDUCER = 'RE_SET_REDUCER';
export const ROADMAP_CREATED = 'ROADMAP_CREATED';
export const CURRENT_MODULE = 'CURRENT_MODULE';
export const SELECTED_QUESTIONAIRE = 'SELECTED_QUESTIONAIRE';
export const GOTO_STEP = 'GOTO_STEP';
export const SET_SELECTED_DURATION_UNIT = 'SET_SELECTED_DURATION_UNIT';

export type state = Readonly<{
  steppers: steppers | Array<[]>;
  Question: questions | Array<[]>;
  currentQus: questions | Array<[]>;
  questionInfo: Array<[]>;
  enableSave: boolean;
  roadmapDefined: boolean;
  newId: Record<string, unknown>;
  questionaireDetails: null | Record<string, unknown>;
  unsavedStep: boolean;
  selectedDurationUnit: 0 | 1 | 2;
}>;

export type workFlowReports = Readonly<
  Array<{
    Order?: number;
    Questions: Array<questions>;
  }>
>;

export type questions = Readonly<
  Array<{
    Order: number;
    SerialNumber: number;
    QuestionText: string;
    QuestionType?: number;
    IsMultipleWorkFlowQuestion?: boolean;
    QuestionSections: Array<QuestionSections>;
  }>
>;

export type QuestionSections = Readonly<
  Array<{
    Description: string;
    IsCheckBoxRequired: boolean;
    IsTextBoxRequired: boolean;
    QuestionHeader: {
      Title: string;
      QuestionOptions: Array<[]>;
    };
    TextBoxText?: boolean | null;
    CheckBoxText?: boolean | null;
    QuestionOptions?: Array<{
      Text: string;
      OptionId: number | null;
      Order: number;
      TextBoxPlaceholderText: string;
    }>;
    RowHeaders?: Array<[]>;
    ColumnHeaders?: Array<[]>;
  }>
>;

export type steppers = Readonly<
  Array<{
    Order: number;
    SerialNumber: number;
    Title: string;
  }>
>;
