import { curry } from 'lodash';
import { Reducer } from 'redux';
import { HATQuestionTypes } from '../../models/enums.bl';
import { QuestionGroup, QuestionnairePractioner, QuestionnaireStep } from '../../models/models.bl';
import { checkIsSummaryReportModule } from '../../utils/case-type-module';
import { QuestionnaireStepperDTO } from '../../../ipo-scanner-app/shared/interfaces';
import {
  state,
  GET_QUESTIONNAIRE_REPORTS,
  GO_NEXT_AND_PREVIOUS_QUESTION,
  ON_ANSWER_CHANGE,
  RE_SET_REDUCER,
  ROADMAP_CREATED,
  CURRENT_MODULE,
  SELECTED_QUESTIONAIRE,
  GOTO_STEP,
  SET_SELECTED_DURATION_UNIT,
  UPDATE_QUESTIONNAIRE_STEPPER
} from './questionnaire.types';

const initialState: state = {
  steppers: [],
  Question: [],
  questionInfo: [],
  currentQus: [],
  enableSave: false,
  roadmapDefined: false,
  newId: {},
  questionaireDetails: null,
  unsavedStep: false,
  selectedDurationUnit: 2
};

const checkEvery = (array: Array<[]>, stepper: Array<any>, question: any, isAnswered?: boolean) => {
  if (isAnswered) {
    return true;
  }
  if (question?.ActualStepNumber === stepper.length && stepper.some((item) => !item.isAnswered)) {
    return false;
  }
  return array.every((curr: any) => {
    return curr.QuestionSectionAnswerDetails.every((section: any) => {
      return section.IsAnswered;
    });
  });
};

const validateSummaryQuestionnaireStep = (question: QuestionnaireStep, questionInfo: any) => {
  return question.QuestionGroups.every((group) => {
    if (group.IsVisible) {
      return !group.IsAnswerRequired || group.QuestionSections[0].Questions.some((answer) => answer.IsSelected);
    }

    return true;
  });
};

const updateSteppersValues = curry(
  (stepId: number, values: Partial<QuestionnaireStepperDTO>, steppers: QuestionnaireStepperDTO[]) =>
    steppers.map((stepper: QuestionnaireStepperDTO) => (stepper.Order === stepId ? { ...stepper, ...values } : stepper))
);

const setQuestionsVisibility = (questionnaire: QuestionnairePractioner) => {
  if (!checkIsSummaryReportModule(questionnaire.ModuleType)) return questionnaire;

  const { Question } = questionnaire;
  let navigationQuestionId: string | null = null;
  let prevQuestionIsSelected = false;

  const newStep = Question.QuestionGroups.map((group, idx) => {
    let IsVisible: boolean;
    const navigatedAnswer = group.QuestionSections[0].Questions.find((answer) => answer.IsSelected);

    if (navigationQuestionId) {
      IsVisible = !(group.QuestionId !== navigationQuestionId && navigationQuestionId);
      navigationQuestionId =
        group.QuestionId === navigationQuestionId ? navigatedAnswer?.NextQuestionUID || null : navigationQuestionId;
    } else {
      navigationQuestionId = navigatedAnswer?.NextQuestionUID || navigationQuestionId;
      IsVisible = !idx || prevQuestionIsSelected;
    }

    prevQuestionIsSelected = !!navigatedAnswer || !group.IsAnswerRequired;

    return { ...group, IsVisible };
  });

  return { ...questionnaire, Question: { ...Question, QuestionGroups: newStep } };
};

const getQuestionAnswersInfo = (answers: any, questionGroups: Array<QuestionGroup>) => {
  const questionTypes = Object.values(HATQuestionTypes);

  return [
    ...answers.map((questionAnswer: any) => ({
      ...questionAnswer,
      QuestionSectionAnswerDetails: [
        ...questionAnswer.QuestionSectionAnswerDetails.map((qSectionAnswer: any) => ({
          ...qSectionAnswer,
          IsAnswered: questionTypes.includes(qSectionAnswer.QuestionType)
            ? questionAnswer.IsAnswered ||
              !questionGroups.find((qGroup: QuestionGroup) => qGroup.QuestionId === questionAnswer.QuestionId)
                ?.IsAnswerRequired
            : qSectionAnswer.IsAnswered
        }))
      ]
    }))
  ];
};

export const questionnaireReducer: Reducer<any, any> = (state: any = initialState, action: any) => {
  switch (action.type) {
    case GET_QUESTIONNAIRE_REPORTS: {
      const data = setQuestionsVisibility(action.data[0]);
      const { QuestionAnswerDetails, Steppers, Question, ModuleType } = data;
      const questionInfo = QuestionAnswerDetails;

      return {
        ...state,
        Question: [data],
        steppers: Steppers,
        questionInfo,
        enableSave: checkIsSummaryReportModule(ModuleType)
          ? validateSummaryQuestionnaireStep(Question, questionInfo)
          : checkEvery(questionInfo, Steppers, state.Question[0])
      };
    }

    case GO_NEXT_AND_PREVIOUS_QUESTION: {
      const data = setQuestionsVisibility(action.data[0]);
      const { QuestionAnswerDetails, Steppers, Question, ModuleType } = data;

      return {
        ...state,
        Question: [data],
        steppers: Steppers,
        questionInfo: QuestionAnswerDetails,
        enableSave: checkIsSummaryReportModule(ModuleType)
          ? validateSummaryQuestionnaireStep(Question, QuestionAnswerDetails)
          : !Question.QuestionGroups[0].IsAnswerRequired
          ? !Question.QuestionGroups[0].IsAnswerRequired
          : checkEvery(QuestionAnswerDetails, Steppers, state.Question[0])
      };
    }

    case RE_SET_REDUCER: {
      return { ...initialState };
    }

    case SET_SELECTED_DURATION_UNIT: {
      return { ...state, selectedDurationUnit: action.data };
    }

    case UPDATE_QUESTIONNAIRE_STEPPER: {
      const {
        data: { stepId, values }
      } = action;

      const updateSteppersValuesWithParams = updateSteppersValues(stepId, values);

      return {
        ...state,
        Question: state.Question.map((question: any) => ({
          ...question,
          Steppers: updateSteppersValuesWithParams(question.Steppers)
        })),
        steppers: updateSteppersValuesWithParams(state.steppers)
      };
    }

    case ON_ANSWER_CHANGE: {
      const { data } = action;
      const quesInfo = state.questionInfo.map((ques: any) => {
        return {
          ...ques,
          QuestionSectionAnswerDetails: ques.QuestionSectionAnswerDetails.map((section: any) => {
            return section.Order == data.order && ques.QuestionId == data.qid
              ? { ...section, IsAnswered: data.isAnswered }
              : section;
          })
        };
      });

      const isSummaryReport = checkIsSummaryReportModule(state.Question[0]?.ModuleType);
      // INFO: to hide all questions after changed for summaryReport
      let changedGroupFound = false;

      const Question = state.Question.map((ques: any) => {
        return {
          ...ques,
          Question: {
            ...ques.Question,
            QuestionGroups: ques.Question.QuestionGroups.map((group: any) => {
              return {
                ...group,
                IsVisible: isSummaryReport ? !changedGroupFound : group.IsVisible,
                QuestionSections: group.QuestionSections.map((section: any) => {
                  if (section.Order == data.order && group.QuestionId == data.qid) {
                    if (isSummaryReport) {
                      changedGroupFound = true;
                    }
                    return { ...section, Questions: [...data.answers] };
                  }
                  return section;
                })
              };
            })
          }
        };
      })[0];

      let { steppers } = state;

      if (isSummaryReport && Question.ActualStepNumber < steppers.length) {
        steppers = steppers.slice(0, Question.ActualStepNumber);
      }

      return {
        ...state,
        steppers,
        questionInfo: quesInfo,
        enableSave: isSummaryReport
          ? validateSummaryQuestionnaireStep(Question.Question, quesInfo)
          : checkEvery(quesInfo, state.steppers, state.Question[0], data.isAnswered),
        Question: [Question]
      };
    }

    case ROADMAP_CREATED: {
      return { ...state, roadmapDefined: action.data };
    }

    case CURRENT_MODULE: {
      return { ...state, newId: action.data };
    }

    case SELECTED_QUESTIONAIRE: {
      return { ...state, questionaireDetails: action.data };
    }

    case GOTO_STEP: {
      return { ...state, unsavedStep: action.data };
    }

    default:
      return state;
  }
};
