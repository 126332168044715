import { useCallback } from 'react';
import { EServicesAreasName } from '../components/layout/landing/service-area-blocks/ServiceArea.helpers';
import { AppInsightsEventTelemetryTypeE, useAppInsightsTrackEvent } from '../../telemetry/appInsights';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';

export const useAreaSelectorTelemetry = (appInsights: ReactPlugin) => {
  const trackLandingDisruptiveEventClick = useAppInsightsTrackEvent(
    appInsights,
    AppInsightsEventTelemetryTypeE.disruptiveEventsSelect
  );

  const trackLandingAccountingAndReportingClick = useAppInsightsTrackEvent(
    appInsights,
    AppInsightsEventTelemetryTypeE.accountingAndReportingSelect
  );

  const trackLandingAccountingOperationsClick = useAppInsightsTrackEvent(
    appInsights,
    AppInsightsEventTelemetryTypeE.accountingOperationsSelect
  );

  const trackLandingBusinessAssuranceClick = useAppInsightsTrackEvent(
    appInsights,
    AppInsightsEventTelemetryTypeE.businessAssuranceSelect
  );

  const trackServiceAreaClick = useCallback(
    (areaName: string) => {
      switch (areaName) {
        case EServicesAreasName.ACCOUNTING_AND_REPORTING:
          trackLandingAccountingAndReportingClick();
          break;

        case EServicesAreasName.ACCOUNTING_OPERATION:
          trackLandingAccountingOperationsClick();
          break;

        case EServicesAreasName.BUSINESS_ASSURANCE:
          trackLandingBusinessAssuranceClick();
          break;

        case EServicesAreasName.DISRUPTIVE_EVENTS:
          trackLandingDisruptiveEventClick();
          break;

        default:
          break;
      }
    },
    [
      trackLandingAccountingAndReportingClick,
      trackLandingAccountingOperationsClick,
      trackLandingBusinessAssuranceClick,
      trackLandingDisruptiveEventClick
    ]
  );

  return { trackServiceAreaClick };
};
