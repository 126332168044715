import { useCallback, useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import Services from '../../shared.services';
import { MsalAuthService } from '../../../msal-auth';

import {
  POPUP_TIMEOUT,
  SESSION_EXPIRES_AT,
  SHOW_LOGOUT_POPUP_AT,
  TIMEOUT_TO_KEEP_ALIVE,
  USER_ACTIVITY
} from '../../constants/autologout.const';
import { useEventsTracking } from '../../hooks/useEventsTracking';
import { useTimeInterval } from '../../hooks/useTimeInterval';
import {
  calculateEndTime,
  getDifferenceInSecondsFromNow,
  getFromLocalStorage,
  hasTimeExpired,
  removeFromLocalStorage,
  setToLocalStorage
} from '../../utils/storage.utils';
import styles from './AutoLogout.module.css';

const originalPopupTimeout = 120;
const originalLogoutTimeout = 1800;

const initTimeouts = () => {
  setToLocalStorage(SHOW_LOGOUT_POPUP_AT, calculateEndTime(originalLogoutTimeout - originalPopupTimeout));

  setToLocalStorage(SESSION_EXPIRES_AT, calculateEndTime(originalLogoutTimeout));
};

const eventListener = () => {
  const storageUserActivity = getFromLocalStorage<boolean>(USER_ACTIVITY);

  if (!storageUserActivity) {
    setToLocalStorage(USER_ACTIVITY, true);
  }
};

export function AutoLogout() {
  const [isLogoutPopupOpened, setIsLogoutPopupOpened] = useState(false);

  const [popupTimeout, setPopupTimeout] = useState(originalPopupTimeout);
  const timeoutToKeepAliveRef = useRef(TIMEOUT_TO_KEEP_ALIVE);

  const countDownTimeoutToKeepAlive = () => {
    if (timeoutToKeepAliveRef.current > 0) {
      timeoutToKeepAliveRef.current -= 1;
      return;
    }

    handleSilentRenew();
  };

  const { startInterval: startActivityInterval, stopInterval: stopActivityInterval } = useTimeInterval({
    actionCallback: countDownTimeoutToKeepAlive
  });

  const renewActivityInterval = () => {
    removeFromLocalStorage(USER_ACTIVITY);
    startActivityInterval();
    timeoutToKeepAliveRef.current = TIMEOUT_TO_KEEP_ALIVE;
  };

  const logoutActionCallBack = (): void => {
    const storageLogoutTimeout = getFromLocalStorage<string>(SHOW_LOGOUT_POPUP_AT);
    const storagePopupTimeout = getFromLocalStorage<string>(POPUP_TIMEOUT);
    const storageSessionExpirationTime = getFromLocalStorage<string>(SESSION_EXPIRES_AT);
    const storageUserActivity = getFromLocalStorage<boolean>(USER_ACTIVITY);

    if (storageUserActivity) {
      renewActivityInterval();
    }

    if (!isLogoutPopupOpened) {
      logoutOnSessionExpiration();

      if ((storageLogoutTimeout && hasTimeExpired(storageLogoutTimeout)) || storagePopupTimeout) {
        handleOpenPopup();
      } else if (!storageLogoutTimeout || !storageSessionExpirationTime) {
        initTimeouts();
      }
    }
  };

  const popupActionCallback = () => {
    const storagePopupTimeout = getFromLocalStorage<string>(POPUP_TIMEOUT);
    logoutOnSessionExpiration();

    if (isLogoutPopupOpened) {
      if (popupTimeout > 0) {
        if (storagePopupTimeout) {
          setPopupTimeout(getDifferenceInSecondsFromNow(storagePopupTimeout));
        } else {
          setIsLogoutPopupOpened(false);
          stopPopupInterval();
          setPopupTimeout(originalPopupTimeout);
          startCheckingInterval();
        }
      } else {
        handleLogout();
      }
    }
  };

  const { stopInterval: stopCheckingInterval, startInterval: startCheckingInterval } = useTimeInterval({
    actionCallback: logoutActionCallBack,
    timeout: 500
  });

  const { stopInterval: stopPopupInterval, startInterval: startPopupInterval } = useTimeInterval({
    actionCallback: popupActionCallback
  });

  const logoutOnSessionExpiration = () => {
    const storageSessionExpirationTime = getFromLocalStorage<string>(SESSION_EXPIRES_AT);
    if (storageSessionExpirationTime && hasTimeExpired(storageSessionExpirationTime)) {
      handleLogout();
    }
  };

  useEffect(() => {
    const storageLogoutTimeout = getFromLocalStorage<string>(SHOW_LOGOUT_POPUP_AT);
    const storagePopupTimeout = getFromLocalStorage<string>(POPUP_TIMEOUT);
    const storageSessionExpirationTime = getFromLocalStorage<string>(SESSION_EXPIRES_AT);

    logoutOnSessionExpiration();

    if (storagePopupTimeout) {
      handleOpenPopup();
    } else {
      if (!storageLogoutTimeout && !storageSessionExpirationTime) {
        initTimeouts();
      }
      startCheckingInterval();
    }
  }, []);

  const handleOpenPopup = () => {
    const storagePopupTimeout = getFromLocalStorage<string>(POPUP_TIMEOUT);

    if (!storagePopupTimeout) {
      setToLocalStorage(POPUP_TIMEOUT, calculateEndTime(originalPopupTimeout));
    } else {
      setPopupTimeout(getDifferenceInSecondsFromNow(storagePopupTimeout));
    }

    removeFromLocalStorage(SHOW_LOGOUT_POPUP_AT);
    stopCheckingInterval();
    setIsLogoutPopupOpened(true);
    startPopupInterval();
  };

  const logoutInTimeout = () => {
    setTimeout(() => {
      Services.DL.UserDataService.logout().then(() => {
        Services.BL.AuthenticationService.logOut();
        MsalAuthService.logout();
        localStorage.clear();
      });
    }, 2000);
  };

  const handleLogout = useCallback(() => {
    setIsLogoutPopupOpened(false);
    setToLocalStorage(SESSION_EXPIRES_AT, calculateEndTime());
    logoutInTimeout();
  }, []);

  const requestKeepAlive = useCallback(async () => {
    stopActivityInterval();
    MsalAuthService.refreshTokenSilent();
  }, [stopActivityInterval]);

  const handleSilentRenew = useCallback(() => {
    requestKeepAlive();
    initTimeouts();
    startCheckingInterval();
  }, [requestKeepAlive, startCheckingInterval]);

  const stopAllIntervals = useCallback(() => {
    stopCheckingInterval();
    stopPopupInterval();
  }, [stopCheckingInterval, stopPopupInterval]);

  const handleContinueSession = useCallback(() => {
    removeFromLocalStorage(POPUP_TIMEOUT);
    stopAllIntervals();
    setPopupTimeout(originalPopupTimeout);
    setIsLogoutPopupOpened(false);
    handleSilentRenew();
  }, [setIsLogoutPopupOpened, handleSilentRenew, stopAllIntervals]);

  useEventsTracking(eventListener);

  return (
    <div>
      <Modal backdrop="static" centered={true} show={isLogoutPopupOpened} onHide={handleContinueSession}>
        <Modal.Header className={styles.header}>
          <Modal.Title className={styles.title}>Logout Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body className={styles.body}>
          <div className={styles.iconCircle}>
            <i className={'icon-exclamation-mark-special'} />
          </div>
          <div className={styles.text}>
            <p className={styles.message}>
              <h3>You will be logged out in {popupTimeout > 0 ? popupTimeout : 0} seconds.</h3>
            </p>
            <p className={styles.note}>Are you sure you want to log off?</p>
          </div>
        </Modal.Body>
        <Modal.Footer className={styles.footer}>
          <Button className={styles.yesaction} size="sm" variant="outline-secondary" onClick={handleLogout}>
            YES
          </Button>
          <Button className={styles.action} size="sm" variant="primary" onClick={handleContinueSession}>
            NO
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}
