import axios from 'axios';
import { ApiEntityE } from '../shared/enums';
import { blobToBase64 } from '../shared/utils';
import { MainApiService } from './main-api-service';

enum IPOContactServiceApiEntity {
  Photo = 'photo'
}

class IPOContactService extends MainApiService {
  constructor() {
    super({ apiEntity: ApiEntityE.Contact, useMemberFirmInUrl: false });
  }

  getPhoto = async (photoId: string): Promise<string> => {
    const { data } = await axios.get<Blob>(
      `${this.defaultNewRegionApi}/${IPOContactServiceApiEntity.Photo}/${photoId}`,
      {
        responseType: 'blob'
      }
    );
    const url = await blobToBase64(data);
    return url;
  };
}

export default new IPOContactService();
