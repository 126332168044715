import { selectSettings } from '../settings';
import { StoreEntityE } from '../store-entity.enum';
import { IpoScannerRootStateT } from '../store.types';
import { getCustomValidationErrorKey } from './general-info-config.helpers';
import {
  StepSelectorData,
  GroupSelectorData,
  SectionSelectorData,
  AnswerSelectorData
} from './general-info-config.interface';

const getStateData = (state: IpoScannerRootStateT) => state[StoreEntityE.GeneralInfoConfig];

export const selectGeneralInfoConfig = (state: IpoScannerRootStateT) => getStateData(state).data;
export const selectIsThereGeneralInfoConfig = (state: IpoScannerRootStateT) => !!selectGeneralInfoConfig(state);
export const selectGeneralInfoConfigLoading = (state: IpoScannerRootStateT) => getStateData(state).loading;

export const selectGeneralInfoStepsTotal = (state: IpoScannerRootStateT) => selectGeneralInfoConfig(state).length;

export const selectGeneralInfoSelectedCountryStateId = (state: IpoScannerRootStateT) =>
  getStateData(state).countryStateId;

export const selectGeneralInfoTitle = (state: IpoScannerRootStateT) =>
  selectGeneralInfoConfig(state)[0]?.QuestionnaireTitle || '';

// Step
const selectGeneralInfoStepByIdx = (state: IpoScannerRootStateT, data: StepSelectorData) =>
  selectGeneralInfoConfig(state)[data.stepIdx];

export const selectGeneralInfoStepTitleByIdx = (state: IpoScannerRootStateT, data: StepSelectorData) =>
  selectGeneralInfoStepByIdx(state, data).Question.QuestionText;

export const selectGeneralInfoGroupsTotalByIdx = (state: IpoScannerRootStateT, data: StepSelectorData) =>
  selectGeneralInfoStepByIdx(state, data).Question.QuestionGroups.length;

// Group
const selectGeneralInfoGroupByIdx = (state: IpoScannerRootStateT, data: GroupSelectorData) =>
  selectGeneralInfoStepByIdx(state, data).Question.QuestionGroups[data.groupIdx];

export const selectGeneralInfoGroupTitleByIdx = (state: IpoScannerRootStateT, data: GroupSelectorData) =>
  selectGeneralInfoGroupByIdx(state, data).QuestionText;

export const selectGeneralInfoSectionsTotalByIdx = (state: IpoScannerRootStateT, data: GroupSelectorData) =>
  selectGeneralInfoGroupByIdx(state, data).QuestionSections.length;

export const selectGeneralInfoGroupRequiredByIdx = (state: IpoScannerRootStateT, data: GroupSelectorData) =>
  selectGeneralInfoGroupByIdx(state, data).IsAnswerRequired;

// Section
const selectGeneralInfoSectionByIdx = (state: IpoScannerRootStateT, data: SectionSelectorData) =>
  selectGeneralInfoGroupByIdx(state, data).QuestionSections[data.sectionIdx];

export const selectGeneralInfoSectionTypeByIdx = (state: IpoScannerRootStateT, data: SectionSelectorData) =>
  selectGeneralInfoSectionByIdx(state, data).QuestionType;

export const selectGeneralInfoAnswersByIdx = (state: IpoScannerRootStateT, data: SectionSelectorData) =>
  selectGeneralInfoSectionByIdx(state, data).Questions;

export const selectGeneralInfoAnswersTotalByIdx = (state: IpoScannerRootStateT, data: SectionSelectorData) =>
  selectGeneralInfoAnswersByIdx(state, data).length;

export const selectGeneralInfoAnswerByIdx = (state: IpoScannerRootStateT, data: AnswerSelectorData) =>
  selectGeneralInfoAnswersByIdx(state, data)[data.answerIdx];

// Custom Errors

const selectGeneralInfoCustomErrors = (state: IpoScannerRootStateT) => getStateData(state).customValidationErrors;

export const selectGeneralInfoCustomErrorsByIdx = (state: IpoScannerRootStateT, data: SectionSelectorData) => {
  const key = getCustomValidationErrorKey(data);
  return selectGeneralInfoCustomErrors(state)[key];
};

// Common
export const selectGeneralInfoIsInvalid = (state: IpoScannerRootStateT) => {
  const { customValidationErrors, data, countryStateId } = getStateData(state);
  const settings = selectSettings(state);
  if (!settings || (settings.ShowStates && !countryStateId)) return true;
  if (Object.values(customValidationErrors).some(Boolean)) return true;
  return data.some((step) =>
    step.QuestionAnswerDetails.some(
      (detail, idx) => !detail.IsAnswered && step.Question.QuestionGroups[idx].IsAnswerRequired
    )
  );
};
