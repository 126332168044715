import { RootState } from './../combineReducers/index';

export const selectAdminList = ({ admins }: RootState) => admins.displayList;
export const selectContactAdmins = ({ admins }: RootState) => admins.contentAdmins;
export const selectAdminListPageIdx = ({ admins }: RootState) => admins.pageIdx;
export const selectAdminListPageSize = ({ admins }: RootState) => admins.pageSize;
export const selectAdminListSortDirection = ({ admins }: RootState) => admins.sortDirection;
export const selectAdminListSortColumnIdx = ({ admins }: RootState) => admins.sortColumnIdx;
export const selectAdminListSortColumnName = ({ admins }: RootState) => admins.sortColumnName;
export const selectTotalAdminsCount = ({ admins }: RootState) => admins.totalRecords;
export const selectTotalGlobalAdminsCount = ({ admins }: RootState) => admins.totalGlobalAdmins;
export const selectAdminListAdminDeleting = ({ admins }: RootState) => admins.deleting;
export const selectAdminListAdminRoleChanging = ({ admins }: RootState) => admins.roleChanging;
