import { ROUTE } from '../constants';
import { SECURE_CODE_ROUTE_PART, checkIsSecureCodeRoute } from './check-is-ipo-secure-code-route';
import { getParamsFromPathname } from './get-params-from-pathname';

const routesList = Object.values(ROUTE);
const routesWithoutCountryCode = new Set(routesList.map((route) => route.replace(/\/:assetName\/:countryCode/, '')));

interface Result {
  countryCode: { current: string; normalized: string };
  assetName: string;
  pathname: string;
}

const getAssetNameAndCountryCodeFromKnownPathname = (data: {
  pathname: string;
  route: ROUTE;
}): Omit<Result, 'pathname'> => {
  const params = getParamsFromPathname(data);

  const assetName = (params.assetName || '').trim();
  const countryCode = (params.countryCode || '').trim();
  const isCountryCodeValid = countryCode && countryCode.length < 3 && !data.route.endsWith(`/${countryCode}`);

  return {
    assetName,
    countryCode: {
      current: isCountryCodeValid ? countryCode : '',
      normalized: isCountryCodeValid ? countryCode.replace(/[^a-zA-Z]/gi, '') : ''
    }
  };
};

export const getAssetNameAndCountryCodeFromUnknownPathname = (pathname?: string): Result => {
  pathname = pathname || location.pathname;
  pathname = pathname === '/' ? pathname : pathname.replace(/\/$/, '');

  const result = { countryCode: { current: '', normalized: '' }, assetName: '', pathname };

  if (routesWithoutCountryCode.has(pathname)) {
    return result;
  }

  const isSecureCodeRoute = checkIsSecureCodeRoute(pathname);
  const diff = Number(isSecureCodeRoute);

  // INFO: transform unknown pathname to known one
  const knownPathName = pathname
    .split('/')
    // first element of route after split is empty string
    .slice(1 + diff, 3 + diff)
    .join('/');

  const data = getAssetNameAndCountryCodeFromKnownPathname({
    pathname: `/${knownPathName}`,
    route: ROUTE.Home
  });

  return { ...result, ...data };
};
