import { FC, PropsWithChildren } from 'react';
import { AppFooter } from '../footer';
import { AppHeader } from '../header';
import { AppMain } from '../main';
import './page-layout.scss';

const PageLayout: FC<PropsWithChildren<{}>> = ({ children }) => (
  <div className="ipo-scanner__page-layout">
    <AppHeader className="ipo-scanner__page-layout__header" />
    <AppMain className="ipo-scanner__page-layout__main">{children}</AppMain>
  </div>
);

export default PageLayout;
