import { Reducer } from 'redux';
import {
  dashBoardState,
  SET_ISLOADING,
  SET_MEMBER_FIRMS,
  SET_MEMBERFIRM,
  SET_MEMBER_FIRM_DETAILS,
  GET_CASES,
  GET_DELETED_CASES,
  SET_DELETED_CASETYPE,
  SET_DELETED_CLIENTNAME,
  SET_DELETED_CASENAME,
  SET_CLIENTNAME,
  SET_CASENAME,
  APPLY_SORTING,
  SET_CASETYPE,
  RESET_SELECT_DATA,
  SET_LOADING_SELECT_DATA,
  RESET_SORT_DETAILS
} from './dashboard.types';

const initialState: dashBoardState = {
  isLoading: false,
  isSelectDataLoading: false,
  cases: {
    CaseDetails: [],
    TotalRecords: 0
  },
  payLoad: {
    pageDetail: {
      pageNo: 1,
      pageSize: 10
    },
    sortDetails: {
      sortActiveId: 5,
      sortColumn: 'created',
      sortDirection: 'desc'
    },
    filterDetails: {
      clientName: [],
      caseName: [],
      caseType: [],
      status: [],
      dateAdded: new Date(),
      memberFirmId: 0,
      CaseTypeCategory: 1
    }
  },
  typeAhead: {
    clientName: [],
    caseName: [],
    caseType: [],
    deletedCaseType: [],
    deletedClientName: [],
    deletedCaseName: []
  },
  memberFirms: [],
  memberFirm: 0,
  memberFirmDetails: null
};

export const dashBoardReducer: Reducer<any, any> = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_ISLOADING: {
      return { ...state, isLoading: action.data };
    }

    case SET_MEMBER_FIRMS: {
      return { ...state, memberFirm: action.data.selectedMemberFirmId, memberFirms: action.data.memberFirms };
    }

    case SET_MEMBERFIRM: {
      return { ...state, memberFirm: action.data };
    }

    case SET_MEMBER_FIRM_DETAILS: {
      return { ...state, memberFirmDetails: action.data };
    }

    case GET_CASES: {
      const cases = {
        ...state.cases,
        CaseDetails: action.data.CaseDetails || initialState.cases.CaseDetails,
        TotalRecords: action.data.TotalRecords
      };

      return { ...state, cases };
    }

    case GET_DELETED_CASES: {
      const cases = {
        ...state.cases,
        CaseDetails: action.data.CaseDetails || initialState.cases.CaseDetails,
        TotalRecords: action.data.TotalRecords
      };

      return { ...state, cases };
    }

    case SET_CLIENTNAME: {
      return { ...state, typeAhead: { ...state.typeAhead, clientName: action.data } };
    }

    case SET_DELETED_CLIENTNAME: {
      return { ...state, typeAhead: { ...state.typeAhead, deletedClientName: action.data } };
    }

    case SET_CASENAME: {
      return { ...state, typeAhead: { ...state.typeAhead, caseName: action.data } };
    }

    case SET_DELETED_CASENAME: {
      return { ...state, typeAhead: { ...state.typeAhead, deletedCaseName: action.data } };
    }

    case SET_CASETYPE: {
      return { ...state, typeAhead: { ...state.typeAhead, caseType: action.data } };
    }

    case SET_DELETED_CASETYPE: {
      return { ...state, typeAhead: { ...state.typeAhead, deletedCaseType: action.data } };
    }

    case RESET_SELECT_DATA: {
      return { ...state, typeAhead: initialState.typeAhead };
    }

    case SET_LOADING_SELECT_DATA: {
      return { ...state, isSelectDataLoading: action.data };
    }

    case RESET_SORT_DETAILS: {
      return { ...state, payLoad: { ...state.payLoad, sortDetails: initialState.payLoad.sortDetails } };
    }

    case APPLY_SORTING: {
      return {
        ...state,
        payLoad: {
          ...state.payLoad,
          sortDetails: {
            sortActiveId: action.data.sortActiveId,
            sortColumn: action.data.sortColumn,
            sortDirection:
              action.data.sortDirection || state.payLoad.sortDetails.sortDirection === 'desc' ? 'asc' : 'desc'
          }
        }
      };
    }

    default:
      return state;
  }
};
