import React, { ReactElement } from 'react';
import { Spinner } from '../../assets/icons/Spinner';
import { FileScanStatus } from '../models/enums.bl';

export const FILE_SCAN_STATUS_DESCRIPTION: Readonly<Record<FileScanStatus, string>> = {
  [FileScanStatus.INFECTED]: 'File is infected, please upload clean file',
  [FileScanStatus.SCAN_ERROR]: "Can't scan file with antivirus, please try your upload again",
  [FileScanStatus.SERVICE_UNAVAILABLE]: 'Antivirus service is unavailable, please try your upload again',
  [FileScanStatus.NOT_SCANNED]: '',
  [FileScanStatus.SCAN_IN_PROGRESS]: '',
  [FileScanStatus.SCAN_PASSED]: ''
};

export const FILE_SCAN_STATUS_ICON: Readonly<Record<FileScanStatus, ReactElement | null>> = {
  [FileScanStatus.INFECTED]: null,
  [FileScanStatus.SCAN_ERROR]: null,
  [FileScanStatus.SERVICE_UNAVAILABLE]: null,
  [FileScanStatus.NOT_SCANNED]: <Spinner />,
  [FileScanStatus.SCAN_IN_PROGRESS]: <Spinner />,
  [FileScanStatus.SCAN_PASSED]: null
};
