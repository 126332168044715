import { Reducer } from 'redux';
import { GET_RADARGRAPH_REPORT, RE_SET_REDUCER, reportState } from './Radargraphreport.types';

const initialState: reportState = {
  NewRadarGraphReport: []
};

export const radargraphReportReducer: Reducer<any, any> = (state: reportState = initialState, action: any) => {
  switch (action.type) {
    case GET_RADARGRAPH_REPORT:
      return { ...state, NewRadarGraphReport: action.data };
    case RE_SET_REDUCER:
      return { ...initialState };
    default:
      return state;
  }
};
