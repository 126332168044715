import { useCallback, useEffect } from 'react';
import { useTypedDispatch, useTypedSelector } from '../hooks';
import { IpoScannerRootStateT } from '../store.types';
import { selectAnswer, selectCountryStateId } from './general-info-config.actions';
import {
  AnswerSelectorData,
  GroupSelectorData,
  SectionSelectorData,
  SelectAnswerActionI,
  StepSelectorData
} from './general-info-config.interface';
import {
  selectGeneralInfoAnswerByIdx,
  selectGeneralInfoAnswersByIdx,
  selectGeneralInfoAnswersTotalByIdx,
  selectGeneralInfoConfig,
  selectGeneralInfoConfigLoading,
  selectGeneralInfoCustomErrorsByIdx,
  selectGeneralInfoGroupRequiredByIdx,
  selectGeneralInfoGroupTitleByIdx,
  selectGeneralInfoGroupsTotalByIdx,
  selectGeneralInfoIsInvalid,
  selectGeneralInfoSectionTypeByIdx,
  selectGeneralInfoSectionsTotalByIdx,
  selectGeneralInfoSelectedCountryStateId,
  selectGeneralInfoStepTitleByIdx,
  selectGeneralInfoStepsTotal,
  selectGeneralInfoTitle,
  selectIsThereGeneralInfoConfig
} from './general-info-config.selector';
import { getGeneralInfoConfig, saveGeneralInfoConfig } from './general-info-config.thunk';

export const useSelectGeneralInfoConfig = () => useTypedSelector(selectGeneralInfoConfig);
export const useSelectIsThereGeneralInfoConfig = () => useTypedSelector(selectIsThereGeneralInfoConfig);
export const useSelectGeneralInfoConfigLoading = () => useTypedSelector(selectGeneralInfoConfigLoading);
export const useSelectGeneralInfoTitle = () => useTypedSelector(selectGeneralInfoTitle);

export const useOnLoadGeneralInfoConfig = () => {
  const dispatch = useTypedDispatch();

  return useCallback(() => dispatch(getGeneralInfoConfig()), [dispatch]);
};

export const useOnSaveGeneralInfoConfig = () => {
  const dispatch = useTypedDispatch();

  return useCallback(
    (...params: Parameters<typeof saveGeneralInfoConfig>) => dispatch(saveGeneralInfoConfig(...params)),
    [dispatch]
  );
};

export const useLoadGeneralInfoConfigOnMount = () => {
  const load = useOnLoadGeneralInfoConfig();
  useEffect(() => {
    load().catch(() => {});
  }, [load]);
};

export const useSelectGeneralInfoSelectedCountryStateId = () =>
  useTypedSelector(selectGeneralInfoSelectedCountryStateId);

export const useOnChangeGeneralInfoCountryStateId = () => {
  const dispatch = useTypedDispatch();

  return useCallback(
    (...params: Parameters<typeof selectCountryStateId>) => dispatch(selectCountryStateId(...params)),
    [dispatch]
  );
};

// Step
export const useSelectGeneralInfoStepsTotal = () => useTypedSelector(selectGeneralInfoStepsTotal);

export const useSelectGeneralInfoStepTitleByIdx = ({ stepIdx }: StepSelectorData) => {
  const selector = useCallback(
    (state: IpoScannerRootStateT) => selectGeneralInfoStepTitleByIdx(state, { stepIdx }),
    [stepIdx]
  );

  return useTypedSelector(selector);
};

export const useSelectGeneralInfoGroupsTotalByIdx = ({ stepIdx }: StepSelectorData) => {
  const selector = useCallback(
    (state: IpoScannerRootStateT) => selectGeneralInfoGroupsTotalByIdx(state, { stepIdx }),
    [stepIdx]
  );

  return useTypedSelector(selector);
};

export const useOnChangeGeneralInfoAnswer = ({ stepIdx, groupIdx, sectionIdx }: SectionSelectorData) => {
  const dispatch = useTypedDispatch();

  return useCallback(
    ({ answerIdx, TextBoxValue }: Pick<SelectAnswerActionI['payload'], 'answerIdx' | 'TextBoxValue'>) =>
      dispatch(selectAnswer({ stepIdx, groupIdx, sectionIdx, answerIdx, TextBoxValue })),
    [dispatch, stepIdx, groupIdx, sectionIdx]
  );
};

// Group
export const useSelectGeneralInfoGroupTitleByIdx = ({ stepIdx, groupIdx }: GroupSelectorData) => {
  const selector = useCallback(
    (state: IpoScannerRootStateT) => selectGeneralInfoGroupTitleByIdx(state, { stepIdx, groupIdx }),
    [stepIdx, groupIdx]
  );

  return useTypedSelector(selector);
};

export const useSelectGeneralInfoSectionsTotalByIdx = ({ stepIdx, groupIdx }: GroupSelectorData) => {
  const selector = useCallback(
    (state: IpoScannerRootStateT) => selectGeneralInfoSectionsTotalByIdx(state, { stepIdx, groupIdx }),
    [stepIdx, groupIdx]
  );

  return useTypedSelector(selector);
};

export const useSelectGeneralInfoGroupRequiredByIdx = ({ stepIdx, groupIdx }: GroupSelectorData) => {
  const selector = useCallback(
    (state: IpoScannerRootStateT) => selectGeneralInfoGroupRequiredByIdx(state, { stepIdx, groupIdx }),
    [stepIdx, groupIdx]
  );

  return useTypedSelector(selector);
};

// Section
export const useSelectGeneralInfoSectionTypeByIdx = ({ stepIdx, groupIdx, sectionIdx }: SectionSelectorData) => {
  const selector = useCallback(
    (state: IpoScannerRootStateT) => selectGeneralInfoSectionTypeByIdx(state, { stepIdx, groupIdx, sectionIdx }),
    [stepIdx, groupIdx, sectionIdx]
  );

  return useTypedSelector(selector);
};

export const useSelectGeneralInfoAnswersByIdx = ({ stepIdx, groupIdx, sectionIdx }: SectionSelectorData) => {
  const selector = useCallback(
    (state: IpoScannerRootStateT) => selectGeneralInfoAnswersByIdx(state, { stepIdx, groupIdx, sectionIdx }),
    [stepIdx, groupIdx, sectionIdx]
  );

  return useTypedSelector(selector);
};

export const useSelectGeneralInfoAnswersTotalByIdx = ({ stepIdx, groupIdx, sectionIdx }: SectionSelectorData) => {
  const selector = useCallback(
    (state: IpoScannerRootStateT) => selectGeneralInfoAnswersTotalByIdx(state, { stepIdx, groupIdx, sectionIdx }),
    [stepIdx, groupIdx, sectionIdx]
  );

  return useTypedSelector(selector);
};

export const useSelectGeneralInfoAnswerByIdx = ({ stepIdx, groupIdx, sectionIdx, answerIdx }: AnswerSelectorData) => {
  const selector = useCallback(
    (state: IpoScannerRootStateT) => selectGeneralInfoAnswerByIdx(state, { stepIdx, groupIdx, sectionIdx, answerIdx }),
    [stepIdx, groupIdx, sectionIdx, answerIdx]
  );

  return useTypedSelector(selector);
};

// Custom Errors
export const useSelectGeneralInfoCustomErrorsByIdx = ({ stepIdx, groupIdx, sectionIdx }: SectionSelectorData) => {
  const selector = useCallback(
    (state: IpoScannerRootStateT) => selectGeneralInfoCustomErrorsByIdx(state, { stepIdx, groupIdx, sectionIdx }),
    [stepIdx, groupIdx, sectionIdx]
  );

  return useTypedSelector(selector);
};

// Common
export const useSelectGeneralInfoIsInvalid = () => useTypedSelector(selectGeneralInfoIsInvalid);
