import axios from 'axios';
import { ApiEntityE, RegionE } from '../shared/enums';
import IPOConfigService from './config.service';
import { MainApiService } from './main-api-service';
import { SelfAssessTypeDTO } from '../../shared/interface';

class SelfAssessAssetService extends MainApiService {
  constructor() {
    super({ apiEntity: ApiEntityE.Types, useMemberFirmInUrl: false });
  }

  getAsset = async (): Promise<SelfAssessTypeDTO> => {
    try {
      if (!this.assetName) {
        throw new Error('No Asset name');
      }
      const { data } = await axios.get<SelfAssessTypeDTO>(`${this.defaultNewRegionApi}/${this.assetName}`);
      IPOConfigService.assetId = data.Id;
      IPOConfigService.assetName = data.ShortName;
      document.title = data.Name;
      return data;
    } catch (error) {
      document.title = 'Unknown Asset';
      return Promise.reject(error);
    }
  };
}

export default new SelfAssessAssetService();
