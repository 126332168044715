import { setQuestionnaire, setQuestionnaireGlossarySettings, setQuestionnaireLoading } from './questionnaire.actions';
import { IPOQuestionnaireService } from '../../../services';
import { TypedThunk } from '../store.types';
import { NextStepTypeT, prepareParamsForStepNavigation } from './questionnaire.helpers';
import { QuestionnaireQuestionUid } from '../../interfaces';

export const getQuestionnaireFirstStep: TypedThunk<void> = () => {
  return async (dispatch) => {
    try {
      dispatch(setQuestionnaireLoading(true));
      const questionnaire = await IPOQuestionnaireService.fetchQuestionnaireFirstStep();
      if (questionnaire) {
        const glossarySettings = await IPOQuestionnaireService.fetchQuestionnaireGlossary(questionnaire.QuestionaireId);
        dispatch(setQuestionnaireGlossarySettings(glossarySettings));
      }
      dispatch(setQuestionnaire(questionnaire));
      dispatch(setQuestionnaireLoading(false));
    } catch (error) {
      dispatch(setQuestionnaireLoading(false));
      return Promise.reject(error);
    }
  };
};

export const excludeQuestionnaireTopic: TypedThunk<{ stepNumber: number }> = ({ stepNumber }) => {
  return async (dispatch) => {
    try {
      dispatch(setQuestionnaireLoading(true));
      const data = await IPOQuestionnaireService.excludeTopic(stepNumber);
      dispatch(setQuestionnaire(data));
      dispatch(setQuestionnaireLoading(false));
    } catch (error) {
      dispatch(setQuestionnaireLoading(false));
      return Promise.reject(error);
    }
  };
};

export const saveQuestionnaireAnswersAndGoToStep: TypedThunk<{
  nextStepType: NextStepTypeT;
  redirect?: VoidFunction;
  isLastQuestion?: boolean;
}> = ({ nextStepType, redirect, isLastQuestion }) => {
  return async (dispatch, getState) => {
    try {
      const params = prepareParamsForStepNavigation(getState().questionnaire.questionnaire, nextStepType);
      if (!params) {
        throw new Error('');
      }

      if (isLastQuestion) {
        params.IsComplete = true;
      }
      dispatch(setQuestionnaireLoading(true));
      let data = await IPOQuestionnaireService.saveQuestionnaireAnswersAndGoToStep(params);
      if (redirect) {
        // INFO: no need to set new questionnaire if we go to another page after save
        // And no need to set loading to false - loading === true by default.
        dispatch(setQuestionnaire(null));
        redirect();
        return;
      }
      // TODO: remove next if statement with all code inside when BE will return correct QuestionAnswersDetails
      // INFO: For now BE return all questions answered if go to the prev step but some questions not answered
      if (data) {
        const questionAnsweredMap = data.Question.QuestionGroups.reduce<Record<QuestionnaireQuestionUid, boolean>>(
          (acc, question) => {
            const questionUid = question.QuestionId;
            const isAnswered = question.QuestionSections.some((section) =>
              section.Questions.some((answer) => answer.IsSelected)
            );
            acc[questionUid] = isAnswered;
            return acc;
          },
          {}
        );

        data.QuestionAnswerDetails = data.QuestionAnswerDetails.map((question) => {
          const isAnswered = !!questionAnsweredMap[question.QuestionId];
          return {
            ...question,
            IsAnswered: isAnswered,
            QuestionSectionAnswerDetails: question.QuestionSectionAnswerDetails.map((section) => ({
              ...section,
              IsAnswered: isAnswered
            }))
          };
        });
      }
      dispatch(setQuestionnaire(data));
      dispatch(setQuestionnaireLoading(false));
    } catch (error) {
      dispatch(setQuestionnaireLoading(false));
      return Promise.reject(error);
    }
  };
};
