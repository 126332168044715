import React, { FC, PropsWithChildren, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactPlugin, AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
import { selectTelemetryEnvironment } from '../../shared/store/telemetry/telemetry.selector';
import { selectCurrentUserEmail } from '../../shared/store/selectors';
import { fetchTelemetryEnvironment } from '../../shared/store/telemetry/telemetry.thunks';

const history = createBrowserHistory();

const reactPlugin = new ReactPlugin();

const AppInsightsProvider: FC<PropsWithChildren<any>> = ({ children }) => {
  const userEmail = useSelector(selectCurrentUserEmail);
  const key = useSelector(selectTelemetryEnvironment);
  const dispatch = useDispatch();

  useEffect(() => {
    if (userEmail) {
      dispatch(fetchTelemetryEnvironment());
    }
  }, [dispatch, userEmail]);

  useEffect(() => {
    if (key) {
      const appInsights = new ApplicationInsights({
        config: {
          connectionString: key,
          extensions: [reactPlugin],
          extensionConfig: {
            [reactPlugin.identifier]: { history }
          },
          isCookieUseDisabled: true,
          enableSessionStorageBuffer: true,
          enableAutoRouteTracking: false, // option to log all route changes
          disableAjaxTracking: true,
          disableFetchTracking: true
        }
      });
      appInsights.loadAppInsights();
    }
  }, [key]);
  return <AppInsightsContext.Provider value={reactPlugin}>{children}</AppInsightsContext.Provider>;
};

export default AppInsightsProvider;
