import { StoreEntityE } from '../store-entity.enum';
import { IpoScannerRootStateT } from '../store.types';

const getStateData = (state: IpoScannerRootStateT) => state[StoreEntityE.Settings];

export const selectSettings = (state: IpoScannerRootStateT) => getStateData(state).data;
export const selectSettingsShowStates = (state: IpoScannerRootStateT) => !!selectSettings(state)?.ShowStates;
export const selectLandingSettings = (state: IpoScannerRootStateT) => selectSettings(state)?.HeadingText;
export const selectSettingsLoading = (state: IpoScannerRootStateT) => getStateData(state).loading;
export const selectSettingsCountryStates = (state: IpoScannerRootStateT) => selectSettings(state)?.CountryStates;
