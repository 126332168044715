import { Reducer } from 'redux';
import { SettingsStateActionT, SettingsStateI } from './settings.interface';
import { SET_SETTINGS, SET_SETTINGS_LOADING } from './settings.types';

const initialState: SettingsStateI = {
  data: null,
  loading: true
};

export const settingsReducer: Reducer<SettingsStateI, SettingsStateActionT> = (
  state = initialState,
  action
): SettingsStateI => {
  switch (action.type) {
    case SET_SETTINGS: {
      return {
        ...state,
        data: action.payload,
        loading: false
      };
    }

    case SET_SETTINGS_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    default: {
      return state;
    }
  }
};
