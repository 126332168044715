import { useEffect } from 'react';
import Services from '../../../shared.services';
import { ModuleFileScanWSMessageDto } from '../../../models/models.dl';

export const moduleUIDSearchParamKey = 'moduleUID';

export interface UseCurrentModuleFileScanListenerPropsI {
  moduleUID: string;
  hasFileScan: boolean;
  updateScanStatus: (data: ModuleFileScanWSMessageDto) => void;
  refreshFilesOnSubscribe: (moduleUid: string) => void;
}

export const useCurrentModuleFileScanListener = ({
  moduleUID,
  hasFileScan,
  updateScanStatus,
  refreshFilesOnSubscribe
}: UseCurrentModuleFileScanListenerPropsI) => {
  useEffect(() => {
    let connection: ReturnType<typeof Services.BL.SignalRService.getConnection>;
    if (hasFileScan) {
      connection = Services.BL.SignalRService.getConnection({
        url: 'moduleFileScan'
      });

      const start = async () => {
        try {
          await connection.start();
          connection.on('GetNotificationMessage', (msg: ModuleFileScanWSMessageDto) => {
            if (!msg.ModuleUId) {
              msg.ModuleUId = moduleUID;
            }
            updateScanStatus(msg);
          });
          await connection.invoke('Subscribe', moduleUID);
          refreshFilesOnSubscribe(moduleUID);
        } catch (err) {
          console.error('Connection failed: ', err);
        }
      };
      start();
    }

    return () => {
      const stop = async () => {
        if (connection) {
          try {
            await connection.stop();
          } catch (err) {
            console.error('Disconnection failed: ', err);
          }
        }
      };
      stop();
    };
  }, [moduleUID, hasFileScan, updateScanStatus, refreshFilesOnSubscribe]);
};
