import { FC, lazy, Suspense } from 'react';
import { generatePath, Redirect, Route, Switch } from 'react-router-dom';
import Loader from '../../shared/components/loader/loader';
import { IPOConfigService } from '../services';
import { ROUTE } from './constants';
import PrivateRoute from './private-route';

const HomePage = lazy(() => import('../pages/home-page/home-page'));
const SecureCode = lazy(() => import('../pages/secure-code/secure-code'));
const GeneralInformationPage = lazy(() => import('../pages/general-information-page'));
const CheckEmail = lazy(() => import('../pages/check-email/check-email'));
const LinkExpired = lazy(() => import('../pages/link-expired/link-expired'));
const RequestLoginLink = lazy(() => import('../pages/request-login-link/request-login-link'));
const NotFound = lazy(() => import('../pages/not-found/not-found'));
const QuestionnaireResults = lazy(() => import('../pages/questionnaire-results/questionnaire-results'));
const Questionnaire = lazy(() => import('../pages/questionnaire/Questionnaire'));
const WrongCountryPage = lazy(() => import('../pages/wrong-country-page/wrong-country-page'));

export const Routes: FC = () => {
  return (
    <Suspense fallback={<Loader />}>
      <Switch>
        <Route exact path={ROUTE.NotFound} component={NotFound} />
        <Route exact path={ROUTE.Home} component={HomePage} />
        <Route exact path={ROUTE.SecureCode} component={SecureCode} />
        <Route exact path={ROUTE.GeneralInformation} component={GeneralInformationPage} />
        <Route exact path={ROUTE.CheckYourEmail} component={CheckEmail} />
        <Route exact path={ROUTE.LinkExpired} component={LinkExpired} />
        <Route exact path={ROUTE.RequestLoginLink} component={RequestLoginLink} />
        <Route exact path={ROUTE.WrongCountry} component={WrongCountryPage} />
        <PrivateRoute exact path={ROUTE.Questionnaire} component={Questionnaire} />
        <PrivateRoute exact path={ROUTE.QuestionnaireResults} component={QuestionnaireResults} />
        <Redirect
          from="*"
          to={generatePath(ROUTE.NotFound, {
            assetName: IPOConfigService.assetName,
            countryCode: IPOConfigService.countryCode
          })}
        />
      </Switch>
    </Suspense>
  );
};
