import { Reducer } from 'redux';
import { GET_IPO_DETAILS, RE_SET_REDUCER, IPOAnalysisState } from './iporoadmap.types';

const initialState: IPOAnalysisState = {
  IPOAnalysisData: []
};

export const IPOReportReducer: Reducer<any, any> = (state: IPOAnalysisState = initialState, action: any) => {
  switch (action.type) {
    case GET_IPO_DETAILS: {
      return { ...state, IPOAnalysisData: action.data };
    }

    case RE_SET_REDUCER: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
