import { IPOGeoService, SelfAssessAssetService } from '../../../services';
import { TypedThunk } from '../store.types';
import { setAsset, setGeo } from './app-core.actions';

export const loadGeo: TypedThunk<void> = () => {
  return async (dispatch) => {
    const data = await IPOGeoService.getGeo();
    dispatch(setGeo(data));
  };
};

export const loadAsset: TypedThunk<void> = () => {
  return async (dispatch) => {
    const data = await SelfAssessAssetService.getAsset();
    dispatch(setAsset(data));
  };
};

export const loadAppCoreData: TypedThunk<void> = () => {
  return async (dispatch) => {
    // WARN: do not change requests order
    // get asset id first to use it in geo request
    await dispatch(loadAsset());
    await dispatch(loadGeo());
  };
};
