import { useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { moduleUIDSearchParamKey } from './useCurrentModuleFileScanListener';

export interface UseScrollToModuleByFileScanResultRefPropsI {
  moduleUID: string;
  isExpanded: boolean;
  moduleOrderNumber: number;
  expandCloseModule?: (moduleOrderNumber: number) => void;
}

export const useScrollToModuleByFileScanResultRef = ({
  moduleUID,
  isExpanded,
  moduleOrderNumber,
  expandCloseModule
}: UseScrollToModuleByFileScanResultRefPropsI) => {
  const moduleWrapperRef = useRef<HTMLDivElement | null>(null);
  const moduleOrderNumberRef = useRef(moduleOrderNumber);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    moduleOrderNumberRef.current = moduleOrderNumber;
  }, [moduleOrderNumber]);

  useEffect(() => {
    if (moduleWrapperRef.current && moduleUID && location.search) {
      const searchParams = new URLSearchParams(location.search);
      const searchParamsModuleUID = searchParams.get(moduleUIDSearchParamKey);

      if (searchParamsModuleUID && searchParamsModuleUID === moduleUID) {
        if (!isExpanded && expandCloseModule) {
          expandCloseModule(moduleOrderNumberRef.current);
        } else {
          moduleWrapperRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
          searchParams.delete(moduleUIDSearchParamKey);
          const search = searchParams.toString();
          history.replace({ search });
        }
      }
    }
  }, [location, history, moduleUID, isExpanded, expandCloseModule]);

  return moduleWrapperRef;
};
