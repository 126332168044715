import classNames from 'classnames';
import React, { FC } from 'react';

import './loader.scss';

const Loader: FC<{ className?: string }> = ({ className }) => (
  <>
    <div className={classNames('overlay', className)} />
    <div className={classNames('loader', className)}>
      <img src="images/Deloitte-Spinner.svg" alt="" />
    </div>
  </>
);

export default Loader;
