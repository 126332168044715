import { combineReducers } from 'redux';
import { reducers } from './../reducers/index';
import { dashBoardReducer as dashboard } from '../dashboard/dashboard.reducer';
import { workFlowReducer as workflow } from '../workflow/workflow.reducer';
import { workFlowReportReducer as workflowReport } from '../workflowReports/reports.reducer';
import { fgAnalysisReducer as fgAnalysis } from '../fg-analysis/fg-analysis.reducer';
import { scorecardReportReducer as scorecardReport } from '../scorecardReport/report.reducer';
import { illustrativeReportReducer as illustrativeReport } from '../illustrativeReport/illustrative.reducer';
import { IPOReportReducer as ipoRoadmapReport } from '../ipoRoadmapReport/iporoadmap.reducer';
import { casetypeDashboardReducer as casetypeDashboard } from '../casetype-dashboard/casetype-dashboard.reducer';
import { questionnaireReducer as Questionnare } from '../Questionnaire/questionnaire.reducer';
import { scorecardReportReducer as NewScoreCardReport } from '../NewScoreCardReport/Scorecardreport.reducer';
import { radargraphReportReducer as NewRadarGraphReport } from '../RadarGraphReport/Radargraphreport.reducer';
import { sapReportReducer as SapReport } from '../SapReport/SapReport.reducer';
import { telemetryReducer as Telemetry } from '../telemetry/telemetry.reducer';
import { fgAnalysisReportReducer as fgAnalysisReport } from '../V2-Fg-Analysis/fg-analysisReport.reducer';
import { V2IlustrativeFirpReportReducer as V2IllustrativeReport } from '../../store/V2IllustrativeFirpReport/V2-IllustrativeFirp.reducer';
import { ManageVisibilityReducer as ManageVisibility } from '../../store/Manage-Visibility/ManagaeVisibility.reducer';
import { fmmReportReducer as fmmReport } from '../../store/financial-maturity-report/fmm.reducer';
import { settingsReducer as settings } from '../settings/settings.reducer';
import { adminsListReducer as admins } from '../admins';

const rootReducer = combineReducers({
  ...reducers,
  settings,
  dashboard,
  workflow,
  workflowReport,
  fgAnalysis,
  scorecardReport,
  illustrativeReport,
  ipoRoadmapReport,
  casetypeDashboard,
  Questionnare,
  NewScoreCardReport,
  NewRadarGraphReport,
  SapReport,
  fgAnalysisReport,
  V2IllustrativeReport,
  ManageVisibility,
  fmmReport,
  admins,
  Telemetry
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
