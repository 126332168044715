import { FC } from 'react';
import { Provider } from 'react-redux';

import { IPOScannerAppInitializationContainer } from './app-initialization-container';
import { PageLayout } from './layout';
import { Routes } from './routes';
import { ipoScannerStore } from './shared/store';
import { AppInsightsProvider } from './telemetry/appInsights';

const IPOScannerApp: FC = () => (
  <Provider store={ipoScannerStore}>
    <IPOScannerAppInitializationContainer>
      <AppInsightsProvider>
        <PageLayout>
          <Routes />
        </PageLayout>
      </AppInsightsProvider>
    </IPOScannerAppInitializationContainer>
  </Provider>
);

export default IPOScannerApp;
