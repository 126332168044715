import { StoreName } from '../store';

export const SET_ASSETS_LIST = `${StoreName.SelfAssessmentAssets}/SET_ASSETS_LIST` as const;
export const SET_ASSETS_LIST_FETCHING = `${StoreName.SelfAssessmentAssets}/SET_ASSETS_LIST_FETCHING` as const;
export const SET_ASSETS_COUNTRIES = `${StoreName.SelfAssessmentAssets}/SET_ASSETS_COUNTRIES` as const;
export const SET_ASSETS_COUNTRIES_FETCHING = `${StoreName.SelfAssessmentAssets}/SET_ASSETS_COUNTRIES_FETCHING` as const;
export const SET_ASSETS_COUNTRY_STATES = `${StoreName.SelfAssessmentAssets}/SET_ASSETS_COUNTRY_STATES` as const;
export const SET_ASSETS_COUNTRY_STATES_FETCHING =
  `${StoreName.SelfAssessmentAssets}/SET_ASSETS_COUNTRY_STATES_FETCHING` as const;
export const SET_ASSET_CREATING = `${StoreName.SelfAssessmentAssets}/SET_ASSET_CREATING` as const;
export const SET_ASSET_UPDATING = `${StoreName.SelfAssessmentAssets}/SET_ASSET_UPDATING` as const;
export const SET_ASSET_DELETING = `${StoreName.SelfAssessmentAssets}/SET_ASSET_DELETING` as const;
export const SET_SELECTED_ASSET_ID = `${StoreName.SelfAssessmentAssets}/SET_SELECTED_ASSET_ID` as const;
export const SET_SIDEBAR_ACCORDION_STATE = `${StoreName.SelfAssessmentAssets}/SET_SIDEBAR_ACCORDION_STATE` as const;
export const SET_ASSETS_CASE_TYPE_PRIMARY_SECTORS =
  `${StoreName.SelfAssessmentAssets}/SET_ASSETS_CASE_TYPE_PRIMARY_SECTORS` as const;
export const SET_ASSETS_CASE_TYPE_PRIMARY_SECTORS_FETCHING =
  `${StoreName.SelfAssessmentAssets}/SET_ASSETS_CASE_TYPE_PRIMARY_SECTORS_FETCHING` as const;
