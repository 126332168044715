import RegistrationDataService from './services/dl/practitioner.data.service';
import ErrorLogDataService from './services/dl/error-log.data.service';
import UserDataService from './services/dl/user.data.service';
import ApiUrlsService from './services/bl/api-urls.service';
import LoaderService from './services/bl/loader.service';
import RouteService from './services/bl/route.service';
import ValidatorService from './services/bl/validation.service';
import AuthorizationService from './services/bl/authorization.service';
import AuthenticationService from './services/bl/authentication.service';
import CommonService from './services/bl/common.service';
import LookupService from './services/bl/lookup.service';
import CaseDetailService from './services/bl/casedetail.service';
import ConfigService from './services/bl/config.service';
import EmailAlertService from './services/bl/email-alert.service';
import ReportService from './services/bl/report.service';
import RoadmapService from './services/bl/roadmap.service';
import CaseTypeDetailService from './services/bl/caseType.service';
import addModuleService from './services/bl/caseType.service';
import FilesService from './services/bl/files.service';
import UserAdministrationService from './services/bl/user-administration.service';
import SignalRService from './services/bl/signalr.service';

export default {
  BL: {
    AuthenticationService,
    AuthorizationService,
    ApiUrlsService,
    LoaderService,
    RouteService,
    ValidatorService,
    LookupService,
    ReportService,
    CaseDetailService,
    CommonService,
    ConfigService,
    EmailAlertService,
    RoadmapService,
    CaseTypeDetailService,
    FilesService,
    addModuleService,
    UserAdministrationService,
    SignalRService
  },
  DL: {
    RegistrationDataService,
    ErrorLogDataService,
    UserDataService
  }
};
