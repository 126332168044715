import { ACTION_TYPES, emailSentStoreType } from '../store';

export function emailSentReducer(state = '', action: emailSentStoreType): string {
  switch (action.type) {
    case ACTION_TYPES.MAIL_SENT:
      return action.emailSent;
    default:
      return state;
  }
}

export default emailSentReducer;
