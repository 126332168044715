import { addSeconds, isAfter, isSameSecond } from 'date-fns';

export function getFromLocalStorage<T = null>(key: string): T | null {
  const localStorageValue = localStorage.getItem(key);
  return localStorageValue ? JSON.parse(localStorageValue) : null;
}

export const removeFromLocalStorage = (key: string) => localStorage.removeItem(key);

export const setToLocalStorage = (key: string, value: any) => localStorage.setItem(key, JSON.stringify(value));

export const calculateEndTime = (time = 0) => {
  return addSeconds(new Date(), time).toISOString();
};

export const hasTimeExpired = (timeString: string) => {
  return isAfter(new Date(), new Date(timeString)) || isSameSecond(new Date(), new Date(timeString));
};

export const getDifferenceInSecondsFromNow = (dateStringToSubtractSeconds: string) => {
  const startDate = new Date();
  const endDate = new Date(dateStringToSubtractSeconds);
  return Math.floor((endDate.getTime() - startDate.getTime()) / 1000);
};
