import React, { FC, InputHTMLAttributes, ReactNode } from 'react';
import cs from 'classnames';

import './ThemedCheckbox.scss';

export interface ThemedCheckboxPropsI extends InputHTMLAttributes<HTMLInputElement> {
  indeterminate?: boolean;
  label?: ReactNode;
  nowrapLabel?: boolean;
  name?: string;
}

export const ThemedCheckbox: FC<ThemedCheckboxPropsI> = ({
  className,
  label,
  indeterminate,
  onChange,
  nowrapLabel = true,
  disabled,
  name,
  ...props
}) => {
  return (
    <label
      className={cs('themed-checkbox', className, { ['themed-checkbox--disabled']: disabled })}
      htmlFor={props.id}
      title={!!label && (typeof label === 'string' || typeof label === 'number') ? String(label) : ''}
    >
      <input
        type="checkbox"
        className={cs('themed-checkbox__input', {
          'themed-checkbox__input_indeterminate': indeterminate
        })}
        name={name}
        onChange={onChange}
        disabled={disabled}
        {...props}
      />
      <div className="themed-checkbox__layout" />
      {label ? (
        <span className={cs('themed-checkbox__label', { 'themed-checkbox__label--nowrap': nowrapLabel })}>{label}</span>
      ) : null}
    </label>
  );
};
