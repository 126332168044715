import { StoreEntityE } from '../store-entity.enum';
import { IpoScannerRootStateT } from '../store.types';
import { checkIsAllTopicsDisabled } from './questionnaire-result.helpers';

const getStateData = (state: IpoScannerRootStateT) => state[StoreEntityE.QuestionnaireResult];

export const selectQuestionnaireResult = (state: IpoScannerRootStateT) =>
  getStateData(state).data?.QuestionnaireResult || null;
export const selectQuestionnaireResultLoaded = (state: IpoScannerRootStateT) => !!getStateData(state).data;
export const selectQuestionnaireResultTopics = (state: IpoScannerRootStateT) => getStateData(state).data?.Topics || [];
export const selectIsThereTopic = (state: IpoScannerRootStateT) => !!selectQuestionnaireResultTopics(state).length;
export const selectQuestionnaireResultAllTopicsDisabled = (state: IpoScannerRootStateT) =>
  checkIsAllTopicsDisabled(selectQuestionnaireResultTopics(state));
export const selectQuestionnaireResultContacts = (state: IpoScannerRootStateT) =>
  getStateData(state).data?.Contacts || [];
export const selectQuestionnaireResultLoading = (state: IpoScannerRootStateT) => getStateData(state).loading;
export const selectQuestionnaireResultPhoto = (state: IpoScannerRootStateT) => getStateData(state).photo;
export const selectSelectedQuestionnaireResultTopic = (state: IpoScannerRootStateT) =>
  getStateData(state).selectedTopic;
