import axios, { AxiosPromise } from 'axios';
import Services from './../../shared.services';

export default class ReportService {
  static generateProposal(data: any): any {
    return axios.post(Services.BL.ApiUrlsService.getProposal(), data, { responseType: 'blob' });
  }

  static generateProposalV2(data: any): any {
    return axios.post(Services.BL.ApiUrlsService.getProposalV2(), data, { responseType: 'blob' });
  }

  static generateRoadmapExcel(data: any): any {
    return axios.post(Services.BL.ApiUrlsService.getRoadmapExcel(), data, { responseType: 'blob' });
  }

  static generateSAPExcel(data: { caseUID: string; moduleUID: string; questionnaires: string[] }): AxiosPromise<Blob> {
    return axios.post<Blob>(Services.BL.ApiUrlsService.getSAPExcel(), data, { responseType: 'blob' });
  }

  static generateSAPPowerPoint(data: {
    caseUID: string;
    moduleUID: string;
    questionnaires: string[];
  }): AxiosPromise<Blob> {
    return axios.post<Blob>(Services.BL.ApiUrlsService.getSAPPowerPoint(), data, { responseType: 'blob' });
  }
}
