import { SetAssetActionI, SetGeoActionI } from './app-core.interface';
import { SET_ASSET, SET_GEO } from './app-core.types';

export const setAsset = (payload: SetAssetActionI['payload']): SetAssetActionI => ({
  type: SET_ASSET,
  payload
});

export const setGeo = (payload: SetGeoActionI['payload']): SetGeoActionI => ({
  type: SET_GEO,
  payload
});
