import axios from 'axios';
import { ApiEntityE } from '../shared/enums';
import { GeneralInformationConfigGetDTO } from '../shared/interfaces';
import { MainApiService } from './main-api-service';

class GeneralInfoConfigService extends MainApiService {
  constructor() {
    super({ apiEntity: ApiEntityE.GeneralInfoConfig, useMemberFirmInUrl: true });
  }

  getConfig = async (): Promise<GeneralInformationConfigGetDTO[]> => {
    const { data } = await axios.get<GeneralInformationConfigGetDTO[]>(this.defaultNewRegionApi);
    return data;
  };
}

export default new GeneralInfoConfigService();
