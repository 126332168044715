class OneTrustService {
  isScriptLoaded = false;

  private static isIframe(): boolean {
    return window.self !== window.top;
  }

  init = () => {
    if (OneTrustService.isIframe() || this.isScriptLoaded) {
      return;
    }
    const script = document.createElement('script');
    // @ts-ignore
    window.OptanonWrapper = () => {};
    script.onload = () => {
      this.isScriptLoaded = true;

      const interval = setInterval(() => {
        // @ts-ignore
        if (window.OneTrust) {
          const params = new URLSearchParams(document.location.search);
          const isCookiePopup = params.get('cookiesopen');
          if (isCookiePopup) {
            // @ts-ignore
            setTimeout(() => window.OneTrust.ToggleInfoDisplay(), 200);
          }

          // @ts-ignore
          window.OneTrust.OnConsentChanged(() => {
            isCookiePopup ? (document.location.search = '') : document.location.reload();
          });
          clearInterval(interval);
        }
      }, 100);
    };

    script.src = (window as any).ApiConfig.ONE_TRUST_URL;
    script.setAttribute('data-domain-script', (window as any).ApiConfig.ONE_TRUST_ID);
    script.setAttribute('type', 'text/javascript');
    script.setAttribute('charset', 'UTF-8');
    script.setAttribute('id', 'one-trust-script');
    script.defer = true;

    document.body.appendChild(script);
  };
}

export const oneTrustService = new OneTrustService();
