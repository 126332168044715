import { ACTION_TYPES, lookupsStorage, LookupsStoreType, StoreName } from '../store';
import * as Models from '../../shared.models';

const initialState: Models.BM.Lookups = JSON.parse(
  lookupsStorage.getItem(StoreName.Lookups) || JSON.stringify(Models.BM.Lookups.createDefault())
);

export default function lookupsReducer(
  state: Models.BM.Lookups = initialState,
  action: LookupsStoreType
): Models.BM.Lookups {
  let result = {};

  switch (action.type) {
    case ACTION_TYPES.SET_COUNTRIES:
      result = {
        ...state,
        countries: action.lookups.countries
      };
      break;
    default:
      result = state;
  }

  if (action.type === ACTION_TYPES.SET_COUNTRIES) {
    lookupsStorage.setItem(StoreName.Lookups, JSON.stringify(result));
  }

  return result;
}
