import { TemplateTypes } from '../../../../shared/models/enums.bl';
import {
  QuestionnaireDTO,
  QuestionnaireStepperDTO,
  SaveQuestionnaireAnswersDTO
} from '../../interfaces/questionnaire-dto.interface';

export type NextStepTypeT = number | 'next' | 'prev';
export type GroupedSteppers = {
  activeSteps: QuestionnaireStepperDTO[];
  answeredSteps: QuestionnaireStepperDTO[];
};

const getNextStep = (currentStep: number, nextStepType: NextStepTypeT) => {
  if (nextStepType === 'next') return currentStep + 1;
  if (nextStepType === 'prev') return currentStep - 1;
  return nextStepType;
};

export const checkHasDivestitureText = (text: string | undefined | null) =>
  text && text.toLowerCase().includes('divestiture');

const checkTopicCanBeExcluded = (currentStepper: QuestionnaireStepperDTO | undefined) =>
  !checkHasDivestitureText(currentStepper?.Title);

export const getQuestionnaireMetadata = (questionnaire: QuestionnaireDTO | null) => {
  if (!questionnaire)
    return {
      isCurrentTopicExcluded: false,
      isCurrentTopicCanBeExcluded: false,
      isLastQuestion: false,
      isExcludingDisabled: false,
      isNextDisabled: false,
      nextActiveOrder: 1
    };

  const { ActualStepNumber, Steppers } = questionnaire;
  let currentStepper: QuestionnaireStepperDTO | undefined;

  const { activeSteps, answeredSteps }: GroupedSteppers = Steppers.reduce(
    (acc: GroupedSteppers, stepper) => {
      if (stepper.Order === ActualStepNumber) {
        currentStepper = stepper;
      }

      if (!stepper.IsSkipped) {
        acc.activeSteps.push(stepper);
        stepper.IsAnswered && acc.answeredSteps.push(stepper);
      }

      return acc;
    },
    { activeSteps: [], answeredSteps: [] }
  );

  const isCurrentTopicExcluded = !!currentStepper?.IsSkipped;
  const isCurrentTopicCanBeExcluded = checkTopicCanBeExcluded(currentStepper);
  const isLastQuestion = ActualStepNumber === (activeSteps[activeSteps.length - 1]?.Order || ActualStepNumber);
  const isExcludingDisabled = activeSteps.length <= 1 || isCurrentTopicExcluded || !isCurrentTopicCanBeExcluded;
  const isNextDisabled = isLastQuestion ? answeredSteps.length < activeSteps.length : !currentStepper?.IsAnswered;

  return { isCurrentTopicExcluded, isCurrentTopicCanBeExcluded, isLastQuestion, isExcludingDisabled, isNextDisabled };
};

export const prepareParamsForStepNavigation = (
  questionnaire: QuestionnaireDTO | null,
  nextStepType: NextStepTypeT
): SaveQuestionnaireAnswersDTO | null => {
  if (!questionnaire) {
    return null;
  }

  const currentStepNumber = questionnaire.ActualStepNumber;

  let nextStep = getNextStep(currentStepNumber, nextStepType);

  if (nextStep === currentStepNumber || nextStep < 1) {
    return null;
  }

  if (nextStep > questionnaire.Steppers.length) {
    nextStep = currentStepNumber;
  }

  return {
    caseUID: questionnaire.CaseUID,
    // INFO: no moduleUID in questionnaire but BE not uses it
    moduleUID: '00000000-0000-0000-0000-000000000000',
    questionnaireUId: questionnaire.QuestionaireId,
    questionnaireStepUID: questionnaire.Question.QuestionId,
    stepNumber: currentStepNumber,
    nextStepNumber: nextStep,
    questionAnswers: questionnaire.Question.QuestionGroups.map((question) => {
      let answerUid = '';
      // INFO: used find method to break iteration if was found
      question.QuestionSections.find((section) =>
        section.Questions.find(({ TextBoxValue, IsSelected, AnswerId }) => {
          if (section.QuestionType === TemplateTypes.SingleSelect) {
            if (IsSelected) {
              answerUid = AnswerId;
            }

            return !!TextBoxValue;
          }

          if (section.QuestionType === TemplateTypes.TextField) {
            if (TextBoxValue) {
              answerUid = AnswerId;
            }

            return !!TextBoxValue;
          }
        })
      );

      return {
        questionUID: question.QuestionId,
        answerOptionUID: answerUid
      };
    }),
    data: [questionnaire]
  };
};
