import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';

export const useTruncatedHook = (trigger?: string) => {
  const titleRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useLayoutEffect(() => {
    if (titleRef.current?.firstChild) {
      setIsTruncated(titleRef.current.clientWidth < titleRef.current.scrollWidth);
    }
  }, [trigger]);

  return {
    titleRef,
    isTruncated
  };
};
