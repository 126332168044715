import {
  AppInsightsEventTelemetryTypeE,
  useAppInsights,
  useAppInsightsTrackEvent
} from '../../../telemetry/appInsights';

export const useAdminToolTelemetry = () => {
  const appInsights = useAppInsights();

  const trackNavigateToAdminTool = useAppInsightsTrackEvent(appInsights, AppInsightsEventTelemetryTypeE.adminTool);

  return { trackNavigateToAdminTool };
};
