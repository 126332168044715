import { SECURE_CODE_ROUTE_PART } from './check-is-ipo-secure-code-route';

// INFO: old routes started with '/readiness' or '/auth/readiness' - leave next code to support old links in emails
export const OLD_IPO_SCANNER_ROUTE_PART = '/readiness';

export const checkIsOldIPOScannerPathname = (path?: string) => {
  const pathName = path || window.location.pathname;
  return (
    pathName.startsWith(OLD_IPO_SCANNER_ROUTE_PART) ||
    pathName.startsWith(`${SECURE_CODE_ROUTE_PART}${OLD_IPO_SCANNER_ROUTE_PART}`)
  );
};

// <-- end of old routes code support

/**
 * INFO: there are two origins to the same app.
 * IPO_ORIGIN - was first while app supported only IPO asset (redirection to SELF_ASSESSMENT_ORIGIN required)
 * SELF_ASSESSMENT_ORIGIN - new origin with multiple assets support
 */
export const { IPO_ORIGIN, SELF_ASSESSMENT_ORIGIN } = (window as any).ApiConfig as {
  IPO_ORIGIN: string;
  SELF_ASSESSMENT_ORIGIN: string;
};

export const checkIsSelfAssessRoute = () => window.location.origin === SELF_ASSESSMENT_ORIGIN;
