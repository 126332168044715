import React, { FC } from 'react';
import { CommonIconPropsI } from './common-icon-props.type';

const RouteTreeIcon: FC<CommonIconPropsI> = ({ size = 24, ...props }) => {
  return (
    <svg {...props} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.5 4C13.8807 4 15 5.11929 15 6.5C15 7.70933 14.1413 8.7181 13.0004 8.9499L13 11H17.5C18.2797 11 18.9204 11.5949 18.9931 12.3555L19 12.5L19.0004 15.0501C20.1413 15.2819 21 16.2907 21 17.5C21 18.8807 19.8807 20 18.5 20C17.1193 20 16 18.8807 16 17.5C16 16.2903 16.8592 15.2813 18.0006 15.0499L18 12.5C18 12.2545 17.8231 12.0504 17.5899 12.0081L17.5 12H13L13.0004 15.0501C14.1413 15.2819 15 16.2907 15 17.5C15 18.8807 13.8807 20 12.5 20C11.1193 20 10 18.8807 10 17.5C10 16.2903 10.8592 15.2813 12.0006 15.0499L12 12H7.5C7.25454 12 7.05039 12.1769 7.00806 12.4101L7 12.5L7.00043 15.0501C8.14134 15.2819 9 16.2907 9 17.5C9 18.8807 7.88071 20 6.5 20C5.11929 20 4 18.8807 4 17.5C4 16.2903 4.85917 15.2813 6.00057 15.0499L6 12.5C6 11.7203 6.59489 11.0796 7.35554 11.0069L7.5 11H12L12.0006 8.95011C10.8592 8.71869 10 7.70968 10 6.5C10 5.11929 11.1193 4 12.5 4ZM12.5 16C11.6716 16 11 16.6716 11 17.5C11 18.3284 11.6716 19 12.5 19C13.3284 19 14 18.3284 14 17.5C14 16.6716 13.3284 16 12.5 16ZM6.5 16C5.67157 16 5 16.6716 5 17.5C5 18.3284 5.67157 19 6.5 19C7.32843 19 8 18.3284 8 17.5C8 16.6716 7.32843 16 6.5 16ZM18.5 16C17.6716 16 17 16.6716 17 17.5C17 18.3284 17.6716 19 18.5 19C19.3284 19 20 18.3284 20 17.5C20 16.6716 19.3284 16 18.5 16ZM12.5 5C11.6716 5 11 5.67157 11 6.5C11 7.32843 11.6716 8 12.5 8C13.3284 8 14 7.32843 14 6.5C14 5.67157 13.3284 5 12.5 5Z"
        fill="black"
      />
    </svg>
  );
};

export default RouteTreeIcon;
