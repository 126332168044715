import {
  getRouteWithServiceArea,
  getPrevServiceAreaFromLocalStorage
} from './../../landing/service-area-blocks/ServiceArea.helpers';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { OptionsType } from 'react-select';
import { matchPath, useHistory, useParams, useLocation } from 'react-router-dom';
import {
  areaUrls,
  mapUrlToArea,
  serviceAreaOptions,
  ServiceAreas,
  getServiceAreaUrlParam,
  setServiceAreaToLocalStorage,
  serviceAreaValue
} from '../../landing/service-area-blocks/ServiceArea.helpers';
import { Option } from '../../../../../components/ThemedSelect/ThemedSelect';
import { ROUTES } from '../../../../constants';
import { useDispatch } from 'react-redux';
import { setIsAdminToolAC } from '../../../../store/is-admin-tool';
import { useHeaderTelemetry } from './useHeaderTelemetry';

const goToLandingOption: Option = {
  label: 'Navigator Landing Page',
  value: 'Navigator Landing Page'
};

interface ServiceAreaOption {
  label: string;
  value: ServiceAreas;
}

export const selectOptions = [...serviceAreaOptions, goToLandingOption];

export const useServiceAreaDropdown = (isLoggedIn: boolean) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { trackServiceAreaClick } = useHeaderTelemetry();
  const [serviceArea, setServiceArea] = useState<Option | null>(null);

  const isPractitionerRoute = useMemo(
    () => areaUrls.find((area) => history.location.pathname.includes(area)),
    [history.location.pathname]
  );

  const onGoToLanding = useCallback(() => {
    dispatch(setIsAdminToolAC(false));
    setServiceArea(null);
    history.replace(ROUTES.landing);
  }, [dispatch, history]);

  const onAreaChange = useCallback(
    (option: ServiceAreaOption) => {
      setServiceAreaToLocalStorage(option.value);
      trackServiceAreaClick(option.value);
      setServiceArea(option);
      history.replace(`/${getServiceAreaUrlParam(option.value)}${ROUTES.dashboard}`);
      window.location.reload();
    },
    [history, trackServiceAreaClick]
  );

  const handleChange = useCallback(
    (option: Option | OptionsType<Option> | null) => {
      if (!option || Array.isArray(option)) return;

      if ((option as Option).value === goToLandingOption.value) {
        return onGoToLanding();
      }

      onAreaChange(option as ServiceAreaOption);
    },
    [onAreaChange, onGoToLanding]
  );

  useEffect(() => {
    let area = serviceAreaValue();

    const { params: urlParams } =
      matchPath<{ serviceArea?: ServiceAreas }>(history.location.pathname, { path: '/:serviceArea' }) ||
      ({ params: {} } as { params: { serviceArea?: ServiceAreas } });

    const urlServiceArea =
      urlParams.serviceArea && mapUrlToArea[urlParams.serviceArea] ? mapUrlToArea[urlParams.serviceArea] : '';

    if (urlServiceArea) {
      area = urlServiceArea;
    }

    if (!area) {
      const prevServiceArea = getPrevServiceAreaFromLocalStorage();
      area = prevServiceArea;
    }

    setServiceAreaToLocalStorage(area || '');

    setServiceArea((prev) => (area && prev?.value !== area ? { label: area, value: area } : prev));

    if (
      isLoggedIn &&
      ((serviceArea && history.location.pathname === ROUTES.dashboard) ||
        (history.location.pathname.includes(ROUTES.dashboard) && !urlServiceArea && area))
    ) {
      history.replace(getRouteWithServiceArea(ROUTES.dashboard));
    }
  }, [history, isPractitionerRoute, isLoggedIn, serviceArea]);

  return {
    serviceArea,
    handleChange,
    isPractitionerRoute
  };
};
