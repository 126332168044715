import axios from 'axios';
import { ApiEntityE } from '../shared/enums';
import { QuestionnaireFeedbackDTO } from '../shared/interfaces';
import { MainApiService } from './main-api-service';
import SessionStorageService from './session-storage.service';

class IPOQuestionnaireFeedbackService extends MainApiService {
  constructor() {
    super({ apiEntity: ApiEntityE.Requests, useMemberFirmInUrl: false });
  }

  private get currentDefaultRegionApi() {
    const requestId = SessionStorageService.requestId;
    return `${this.defaultRegionApi}/${requestId}/feedback`;
  }

  fetchQuestionnaireFeedback = async (): Promise<QuestionnaireFeedbackDTO> => {
    const { data } = await axios.get<QuestionnaireFeedbackDTO>(this.currentDefaultRegionApi);
    return data;
  };

  saveQuestionnaireFeedback = async (newData: QuestionnaireFeedbackDTO): Promise<QuestionnaireFeedbackDTO | null> => {
    const { data } = await axios.post<QuestionnaireFeedbackDTO>(this.currentDefaultRegionApi, newData);
    return data;
  };
}

export default new IPOQuestionnaireFeedbackService();
