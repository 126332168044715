import axios from 'axios';
import ApiUrlsService from './api-urls.service';
import * as Models from '../../shared.models';
import { CORRECT_VARIABLE_USING_REGEX, getVariableErrorData, VariableErrorType } from '../../constants/validation';

export class FieldInfo {
  validators: Array<Models.BM.IValidator> = [];
}

export default class ValidatorService {
  static validateField(formControl: Models.BM.FormControl, fieldName: string, value: any): Models.BM.ValidationData {
    const vData: Models.BM.ValidationData = new Models.BM.ValidationData();

    vData.isValid = true;

    if (!formControl) {
      return vData;
    }

    if (formControl.validators) {
      formControl.validators.forEach((validator: Models.BM.IValidator) => {
        const v: Models.BM.ValidationData = validator.validate(value);

        if (!v.isValid) {
          vData.isValid = false;
          vData.errorText = vData.errorText.concat(v.errorText);
        }
      });
    }

    return vData;
  }

  static validateQuestionnaireFormulas(value: string): Promise<boolean> {
    return axios.post<boolean>(ApiUrlsService.validateQuestionnaireFormulas(), value).then(({ data }) => data);
  }

  static checkIsFieldHasFormula(value: string): boolean {
    return value.toLowerCase().indexOf('=if') !== -1;
  }

  static checkForUseVariablesInField(
    value: string,
    existingVariables: Set<string>
  ): { valid: boolean; message?: string } {
    const variables = Array.from(value.matchAll(CORRECT_VARIABLE_USING_REGEX()), ([_, variable]) => variable);

    if (!variables.length) {
      return {
        valid: true
      };
    }

    if (!existingVariables.size || variables.some((variable) => !existingVariables.has(variable))) {
      return {
        valid: false,
        message: getVariableErrorData(VariableErrorType.VARIABLE_IS_NOT_EXIST).message
      };
    }

    return {
      valid: true
    };
  }
}
