import { Reducer } from 'redux';
import { GET_FMM_DETAILS, RESET_REDUCER, FMModelState } from './fmm.types';

const initialState: FMModelState = {
  FMModelData: []
};

export const fmmReportReducer: Reducer<any, any> = (state: FMModelState = initialState, action: any) => {
  switch (action.type) {
    case GET_FMM_DETAILS: {
      return { ...state, FMModelData: action.data };
    }

    case RESET_REDUCER: {
      return { ...initialState };
    }

    default:
      return state;
  }
};
