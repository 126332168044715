import { AxiosResponse } from 'axios';
import * as Constants from '../../constants/index';
import GlobalInterceptor from '../../interceptors/global.interceptor';
import ApiUrlsService from '../../services/bl/api-urls.service';
import AuthenticationService from '../../services/bl/authentication.service';

import * as Models from '../../shared.models';
import { onSetCurrentUser } from '../../store/actions/user-profile.actions';
import UserDataService from '../dl/user.data.service';
import { store } from './../../store';

export default class AuthorizationService {
  static setUser(user: Models.DM.UserGetDto): void {
    store.dispatch(onSetCurrentUser(user));
  }

  static isGlobalAdmin(user?: Models.DM.UserGetDto): boolean {
    // @ts-ignore
    const currentUser: Models.DM.UserGetDto = user || store.getState().currentUser.user;

    return Boolean(currentUser) && currentUser.RoleId === Models.DE.UserRole.GlobalAdmin;
  }

  static isContentAdmin(user?: Models.DM.UserGetDto): boolean {
    // @ts-ignore
    const currentUser: Models.DM.UserGetDto = user || store.getState().currentUser.user;

    return Boolean(currentUser) && currentUser.RoleId === Models.DE.UserRole.Admin;
  }

  static isAdmin(user?: Models.DM.UserGetDto): boolean {
    // @ts-ignore
    const currentUser: Models.DM.UserGetDto = user || store.getState().currentUser.user;

    return AuthorizationService.isContentAdmin(currentUser) || AuthorizationService.isGlobalAdmin(currentUser);
  }

  static isUser(user?: Models.DM.UserGetDto): boolean {
    // @ts-ignore
    const currentUser: Models.DM.UserGetDto =
      user || store.getState().currentUser.user || JSON.parse(localStorage.getItem('currentUser') as string).user;

    return (
      currentUser &&
      (currentUser.RoleId === Models.DE.UserRole.Practitioner || currentUser.RoleId === Models.DE.UserRole.User)
    );
  }

  static isPractitioner(user?: Models.DM.UserGetDto): boolean {
    // @ts-ignore
    const currentUser: Models.DM.UserGetDto = user || store.getState().currentUser.user;

    return (
      Boolean(currentUser) && (AuthorizationService.isUser(currentUser) || AuthorizationService.isAdmin(currentUser))
    );
  }

  static isAuthorized(user?: Models.DM.UserGetDto): boolean {
    // @ts-ignore
    const currentUser: Models.DM.UserGetDto = user || store.getState().currentUser.user;

    return Boolean(currentUser);
  }

  static resolveToken(
    tokenObj: Models.BM.AuthServiceUser,
    onSuccessCallback: (route: string) => void,
    onUnSuccessCallback: () => void
  ): void {
    const profileUrls = ApiUrlsService.getUrlsUserProfile();
    const isRefreshedToken = localStorage.getItem('isRefreshToken');

    localStorage.setItem('isRefreshToken', 'false');
    AuthenticationService.setToken(tokenObj);

    if (isRefreshedToken != 'true') {
      const requestUserProfile = (urls: string[], calls: number): any => {
        return UserDataService.getUserProfile(urls[calls])
          .then((response) => {
            localStorage.setItem('defaultRegion', profileUrls.geoCodes[calls]);
            return this.onSuccessfulAuth(response.data, onSuccessCallback);
          })
          .catch((error) => {
            if (calls < 1) {
              return GlobalInterceptor.errorResolver(error);
            }

            return requestUserProfile(profileUrls.urls, calls - 1);
          })
          .catch(() => this.onUnSuccessfulAuth(onUnSuccessCallback));
      };
      requestUserProfile(profileUrls.urls, profileUrls.urls.length - 1);
    }
  }

  private static onSuccessfulAuth(user: Models.DM.UserGetDto, callback: (route: string) => void): void {
    store.dispatch(onSetCurrentUser(user));

    if (this.isPractitioner(user)) {
      user.IsLatestTermsOfUseAccepted ? callback(Constants.ROUTES.dashboard) : callback(Constants.ROUTES.termsofUse);
    }
  }

  private static onUnSuccessfulAuth(callback: () => void): void {
    callback();
  }
}
