export enum ApiEntityE {
  Settings = 'setting',
  Authenticate = 'authenticate',
  RefreshToken = 'refreshToken',
  Requests = 'requests',
  QuestionnaireResult = 'result',
  Contact = 'contact',
  Telemetry = 'trackEvent',
  Geo = 'geo',
  Types = 'types',
  GeneralInfoConfig = 'generalInfoQuestionnaire',
  File = 'file'
}
