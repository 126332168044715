import { ACTION_TYPES, CurrentUserStoreType } from '../store';
import * as Models from '../../shared.models';
import { AuthServiceUser } from '../../models/models.bl';

export function onSetCurrentUser(user?: Models.DM.UserGetDto): CurrentUserStoreType {
  const userProfile: Models.BM.UserProfile = {
    user
  };

  return {
    type: ACTION_TYPES.SET_CURRENT_USER,
    profile: userProfile
  };
}

export function onCurrentUserSetToken(tokenObj?: AuthServiceUser): CurrentUserStoreType {
  const userProfile: Models.BM.UserProfile = {
    tokenObj
  };

  return {
    type: ACTION_TYPES.SET_CURRENT_USER_TOKEN,
    profile: userProfile
  };
}
