export default class ConfigService {
  private static apiUrls: { [region: string]: string } = (window as any).ApiConfig.API_URLS;
  private static hubsUrls: { [region: string]: string } = (window as any).ApiConfig.HUBS_URLS;
  private static currentEnv: string = (window as any).ApiConfig.CURRENT_ENV;
  private static defaultRegion: string = (window as any).ApiConfig.DEFAULT_REGION;
  private static mainRegion: string = (window as any).ApiConfig.MAIN_REGION;
  private static registerLink: string = (window as any).ApiConfig.REGISTER;

  static get ApiUrls() {
    return this.apiUrls;
  }

  static get HubsUrls() {
    return this.hubsUrls;
  }

  static get CurrentEnv(): string {
    return this.currentEnv;
  }

  static get DefaultRegion(): string {
    return localStorage.getItem('fromAdminAccess') === 'true'
      ? this.mainRegion
      : localStorage.getItem('defaultRegion') || this.defaultRegion;
  }

  static get GeoRelatedRegion(): string {
    return localStorage.getItem('fromAdminAccess') === 'true'
      ? this.mainRegion
      : localStorage.getItem('geoCode') || this.DefaultRegion;
  }

  static get MainRegion(): string {
    return this.mainRegion;
  }

  static get RegisterLink(): string {
    return this.registerLink;
  }
}
