import { Reducer } from 'redux';
import {
  state,
  GET_WORKFLOW_REPORTS,
  GO_NEXT_AND_PREVIOUS_QUESTION,
  ON_ANSWER_CHANGE,
  RE_SET_REDUCER,
  GOTO_STEP
} from './reports.types';

const initialState: state = {
  steppers: [],
  Question: [],
  questionInfo: [],
  currentQus: [],
  enableSave: false,
  unsavedStep: false
};

const checkEvery = (array: Array<[]>) => {
  return array.every((curr: any) => {
    return curr.QuestionSectionAnswerDetails.every((section: any) => {
      return section.IsAnswered;
    });
  });
};

export const workFlowReportReducer: Reducer<any, any> = (state: any = initialState, action: any) => {
  switch (action.type) {
    case GET_WORKFLOW_REPORTS: {
      const { QuestionAnswerDetails, Steppers } = action.data[0];

      return {
        ...state,
        Question: action.data,
        steppers: Steppers,
        questionInfo: QuestionAnswerDetails,
        enableSave: checkEvery(QuestionAnswerDetails)
      };
    }

    case GO_NEXT_AND_PREVIOUS_QUESTION: {
      const { QuestionAnswerDetails, Steppers, Question } = action.data[0];

      return {
        ...state,
        Question: action.data,
        steppers: Steppers,
        questionInfo: QuestionAnswerDetails,
        enableSave: !Question.QuestionGroups[0].IsAnswerRequired
          ? !Question.QuestionGroups[0].IsAnswerRequired
          : checkEvery(QuestionAnswerDetails)
      };
    }

    case RE_SET_REDUCER: {
      return { ...initialState };
    }

    case ON_ANSWER_CHANGE: {
      const { data } = action;
      const quesInfo = [
        ...state.questionInfo.map((ques: any) => {
          return {
            ...ques,
            QuestionSectionAnswerDetails: [
              ...ques.QuestionSectionAnswerDetails.map((section: any) => {
                return section.Order == data.order && ques.QuestionId == data.qid
                  ? { ...section, IsAnswered: data.isAnswered }
                  : { ...section };
              })
            ]
          };
        })
      ];

      return {
        ...state,
        questionInfo: quesInfo,
        enableSave: checkEvery(quesInfo),
        Question: [
          ...state.Question.map((ques: any) => {
            return {
              ...ques,
              Question: {
                ...ques.Question,
                QuestionGroups: [
                  ...ques.Question.QuestionGroups.map((group: any) => {
                    return {
                      ...group,
                      QuestionSections: [
                        ...group.QuestionSections.map((section: any) => {
                          const sec = { ...section };

                          if (section.Order == data.order && group.QuestionId == data.qid) {
                            sec.Questions = [...data.answers];

                            return sec;
                          }

                          return sec;
                        })
                      ]
                    };
                  })
                ]
              }
            };
          })
        ]
      };
    }

    case GOTO_STEP: {
      return { ...state, unsavedStep: action.data };
    }

    default:
      return state;
  }
};
