import axios from 'axios';
import { MainApiService } from '../../services/main-api-service';
import { ApiEntityE } from '../../shared/enums';
import type { IEventTelemetry, IPageViewTelemetry, ReactPlugin } from './appInsights.types';

class IPOAppInsightsBEProxyService extends MainApiService implements ReactPlugin {
  constructor() {
    super({ apiEntity: ApiEntityE.Telemetry, useMemberFirmInUrl: false });
  }

  trackEvent({ name }: IEventTelemetry) {
    axios.post(this.defaultRegionApi, name).catch(() => {});
  }

  trackPageView({ name }: IPageViewTelemetry) {
    axios.post(this.defaultRegionApi, name).catch(() => {});
  }
}

export default new IPOAppInsightsBEProxyService();
