import { BrowserCacheLocation, Configuration, RedirectRequest } from '@azure/msal-browser';
import { ROUTES } from '../shared/constants';

const AAD_CLIENT_ID: string = (window as any).ApiConfig.AAD_CLIENT_ID;
const AAD_REDIRECT_URI: string = (window as any).ApiConfig.AAD_REDIRECT_URI;
const AAD_AUTHORITY = (window as any).ApiConfig.AAD_AUTHORITY;
const AAD_POST_REDIRECT_URI: string = (window as any).ApiConfig.AAD_POST_REDIRECT_URI;

export const msalConfig: Configuration = {
  auth: {
    clientId: AAD_CLIENT_ID,
    authority: AAD_AUTHORITY,
    redirectUri: AAD_REDIRECT_URI + ROUTES.authCallback,
    postLogoutRedirectUri: AAD_POST_REDIRECT_URI,
    navigateToLoginRequestUrl: false
  },
  cache: {
    cacheLocation: BrowserCacheLocation.LocalStorage,
    temporaryCacheLocation: BrowserCacheLocation.LocalStorage,
    storeAuthStateInCookie: false
  } /* ,
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      }
    }
  } */
};

export const msalLoginRequest: RedirectRequest = {
  scopes: ['User.Read']
};
