import React from 'react';
import { components } from 'react-select';

import './TagMultiValue.scss';

const TagMultiValue = (props: any) => {
  return (
    <components.MultiValue {...props} className="tag-multi-value">
      <span>{props.data.label}</span>
    </components.MultiValue>
  );
};

export default TagMultiValue;
