import { SetSettingsActionI, SetSettingsLoadingActionI } from './settings.interface';
import { SET_SETTINGS, SET_SETTINGS_LOADING } from './settings.types';

export const setSettings = (payload: SetSettingsActionI['payload']): SetSettingsActionI => ({
  type: SET_SETTINGS,
  payload
});

export const setSettingsLoading = (payload: SetSettingsLoadingActionI['payload']): SetSettingsLoadingActionI => ({
  type: SET_SETTINGS_LOADING,
  payload
});
