import React, { FC } from 'react';
import { ShieldIcon } from '../../../assets/icons/ShieldIcon';
import ThemedButton from '../ThemedButton/ThemedButton';
import './ToastScanFailed.scss';

interface Props {
  message: string;
  onApply: () => void;
}

const ToastScanFailed: FC<Props> = ({ message, onApply }) => {
  return (
    <div className="toast">
      <div className="toast__content__wrapper">
        <div className="toast__icon">
          <ShieldIcon />
        </div>
        <div className="toast__content">
          <span className="toast__content__message">{message}</span>
        </div>
      </div>
      <div className="toast__actions">
        <ThemedButton variant="outline" color="grey" size="small">
          cancel
        </ThemedButton>
        <ThemedButton variant="solid" color="red" size="small" onClick={onApply}>
          replace file
        </ThemedButton>
      </div>
    </div>
  );
};

export default ToastScanFailed;
