import 'bootstrap/dist/css/bootstrap.css';

import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import AppSelector from './AppSelector';
import { checkSelfAssesAppUrlWithRedirection } from './ipo-scanner-app/routes/helpers/checkSelfAssesAppUrlWithRedirection';
import * as serviceWorker from './registerServiceWorker';

checkSelfAssesAppUrlWithRedirection();

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(
  <BrowserRouter>
    <AppSelector />
  </BrowserRouter>
);

serviceWorker.unregister();
