import React from 'react';
import * as ConfigGrid from './../configs/grid.config';
import { FileScanStatus, Step, TemplateTypes } from './enums.bl';
import * as DM from './models.dl';

export interface IPropsBase {
  className?: string;
  children?: React.ReactNode;
}

export interface Module {
  CanHideZeroTask: boolean;
  IsConfigurable: boolean;
  IsTaskAdded: boolean;
  ModuleStatus: string;
  ModuleType: string;
  ModuleUID: string;
  OrderNumber: number;
  QuestionnaireCount: number;
  QuestionnaireDetails: unknown[];
  Title: string;
  TotalQuestions: number;
  TotalSteps: number;
  Questionnaire: unknown[];
  IconName?: string;
  Files?: TemplateFile[];
}

export interface TemplateFile {
  FileMetadataId: number;
  FileName: string;
  IsAdmin?: boolean;
  ScanStatus?: FileScanStatus;
}

export interface Questionnaire {
  CanHideZeroTask: boolean;
  CaseTypeId: number;
  CaseTypeVersionId: number;
  IsNAOptionChecked: boolean;
  IsOverallCommentEnabled: boolean;
  MaxScore: number;
  ModuleScoreConfigs: Array<unknown>;
  ModuleUID: string;
  OrderNumber: number;
  OverallCommentText: string;
  QuestionnaireScoreConfigs: Array<unknown>;
  QuestionnaireStatus: string;
  QuestionnaireStatusId: number;
  QuestionnaireSteps: Array<QuestionnaireStep>;
  QuestionnaireStepsCount: number;
  QuestionnaireUID: string;
  QuestionsCount: number;
  Title: string;
}

export interface QuestionnairePractioner {
  Question: QuestionnaireStep;
  ModuleType: string;
  QuestionAnswerDetails: any;
  Steppers: any;
}
export interface QuestionnaireStep {
  Id: string;
  IsNAOptionChecked: boolean;
  OrderNo: number;
  QuestionGroups: Array<QuestionGroup>;
  QuestionnaireStepScores: Array<unknown>;
  Title: string;
}
export interface QuestionGroup {
  CustomTypeName: string;
  CustomTypeValue: any;
  Description: string;
  IsAnswerRequired: boolean;
  IsRequired: boolean;
  IsMultipleWorkFlowQuestion: boolean;
  IsVisible?: boolean;
  MultipleWorkflowGroupId: string;
  Note: unknown;
  Order: number;
  QuestionId: string;
  QuestionSections: Array<QuestionInnerModel>;
  QuestionText: string;
  SerialNumber: string;
  SharedQuestionnaireDetail: unknown;
  WorkFlowOptionalMessage: string;
  WorkFlowStaticMessage: string;
}

export interface QuestionInnerModel {
  Order: number;
  QuestionRules: Array<unknown>;
  QuestionType: TemplateTypes;
  Questions: Array<Answer>;
  Sliders: Array<unknown>;
}
export interface Answer {
  AnswerId: string;
  AnswerText: string;
  CheckBoxText: string;
  ColumnHeaders: Array<unknown>;
  CurrencyCode: string;
  CustomTypeName: string;
  CustomTypeValue: string;
  Description: string;
  Format: {
    Type: string;
    DecimalScale: number;
    ThousandSeparator: boolean;
    FixedDecimalScale: boolean;
    AllowNegative: boolean;
  };
  InnerSection: Array<unknown>;
  IsCheckBoxChecked: boolean;
  IsCheckBoxRequired: boolean;
  IsNA: boolean;
  IsRequired: boolean;
  IsSelected: boolean;
  IsTextBoxRequired: boolean;
  IsVisible: boolean;
  IsWithoutDescription: boolean;
  LayoutBorderColor: string;
  NextQuestionUID: string;
  QuestionHeader: QuestionHeader;
  QuestionOptions: Array<unknown>;
  RowHeaders: Array<unknown>;
  SelectedExchange: Array<string>;
  TableInputValues: TableInputValues[];
  TextBoxText: string;
  TextBoxValue: string;
  Statement: string;
  CompanyCheckboxes: AnswerCheckboxes[];
  MarketCap: {
    MarketCapText: string;
    Options: Answer[];
  };
}

export interface QuestionHeader {
  CustomTypeName: string;
  CustomTypeValue: string;
  IsRequired: boolean;
  PreRequisiteText: string;
  QuestionOptions: QuestionOptions[];
  SelectedOption: QuestionOptions;
  SelectedOptionId: string | null;
  Title: string;
}

export interface QuestionOptions {
  AnswerId: string;
  CustomTypeName: string;
  CustomTypeValue: string;
  DisplayName: string;
  HideBoard: boolean;
  IsRequired: boolean;
  IsSelected: boolean;
  Order: number;
  Text: string;
  TextBoxPlaceholderText: string | null;
  TextBoxValue: string | null;
}

export interface TableInputValues {
  CellValues: CellValues[];
  RowHeader: string;
  RowId: string;
}

export interface CellValues {
  ColumnHeader: string;
  ColumnId: string;
  FinancialType: string;
  FinancialYear: string;
  IsEnabled: boolean;
  IsRequired: boolean;
  Type: string;
  Value: string;
}

export interface AnswerCheckboxes {
  AnswerId: string;
  CustomTypeName: string;
  CustomTypeValue: string;
  IsEnabled: boolean;
  IsSelected: boolean;
  Order: number;
  Text: string;
}

export interface QuestionComponentAnswer {
  answers: Array<Answer>;
  isAnswered: boolean;
}

export class PagingInfo {
  public pageSize: number;
  public pageIndex: number;
  public totalItems: number;

  public get pageCount(): number {
    return Math.ceil(this.totalItems / this.pageSize);
  }

  public get pageFirstNo(): number {
    return (this.pageIndex - 1) * this.pageSize + 1;
  }

  public get pageLastNo(): number {
    return this.pageFirstNo + this.pageSize - 1;
  }

  constructor(pageSize?: number, pageIndex?: number, totalItems?: number) {
    this.totalItems = totalItems || 0;
    this.pageSize = pageSize || ConfigGrid.DefaultItemsPerPage;
    pageIndex = pageIndex || 0;
    this.pageIndex = (pageIndex > this.pageCount ? this.pageCount : pageIndex) || 1;
  }
}

export class TableModel<T> {
  constructor(public data: DM.PagedDataDto<T>, public tableDescription: TableDescription) {}
}

export class TableDescription {
  constructor(public columnsConfig: Array<ColumnConfiguration>) {}
}

export class ColumnConfiguration {
  constructor(
    public displayName: string,
    public columnName: string,
    public sortName: string,
    public isHidden: boolean,
    public maxWidth?: string,
    public minWidth?: string
  ) {}
}

export class ReloadGridEmitEvent {
  public pageIndex: number;
  public pageSize: number;

  constructor(pageSize?: number, pageIndex?: number) {
    this.pageSize = pageSize || ConfigGrid.DefaultItemsPerPage;
    this.pageIndex = pageIndex || 1;
  }
}

export class UserProfile {
  constructor(public tokenObj?: AuthServiceUser, public user?: DM.UserGetDto) {}

  public static createDefault(): UserProfile {
    const defaultUser: DM.UserGetDto = null as unknown as DM.UserGetDto;
    const tokenObj: AuthServiceUser = null as unknown as AuthServiceUser;

    return {
      user: defaultUser,
      tokenObj
    };
  }
}

// export type RegionCountries = { [countryId: number]: string };

export class Lookups {
  constructor(
    public countries?: Array<DM.CountryGetDto> // public regionCountries?: Models.BM.RegionCountries
  ) {}

  public static createDefault(): Lookups {
    const countries: Array<DM.CountryGetDto> = [];
    // const regionCountries: Models.BM.RegionCountries = {};

    return {
      countries
      // regionCountries: regionCountries
    };
  }
}

export class LanguageLookups {
  constructor(public languages?: Array<DM.LanguageGetDto>) {}

  public static createDefaultLanguage(): LanguageLookups {
    const languages: Array<DM.LanguageGetDto> = [
      {
        localeCode: 'en-US',
        language: 'English (United States)',
        nativeName: 'English (United States)',
        englishName: 'English (United States)'
      }
    ];

    return {
      languages
    };
  }
}

export class ActiveLanguage {
  public static setActiveLanguage(): ActiveLanguage {
    const currentLanguage: DM.ActiveLanguageDto = {
      localeCode: 'en-US',
      language: 'English (United States)',
      nativeName: 'English (United States)',
      englishName: 'English (United States)'
    };

    return currentLanguage;
  }
}

export class AuthServiceUser {
  constructor(public idToken: string, public email: string, public accessToken: string, public isInternal: boolean) {}
}

export interface IValidator {
  validate(value: any): ValidationData;
}

export class ValidationData {
  constructor(public errorText: Array<string> = [], public isValid: boolean = false) {}
}

export type FormControls = {
  [fieldname: string]: FormControl;
};

export class FormState {
  constructor(
    public formControls: FormControls,
    public formSubmitted: boolean,
    public formIsValid: boolean,
    public formIsDirty: boolean
  ) {}
}

export class FormControl {
  public value = '';
  public errorText: Array<string> = [];
  public isValid = true;
  public isTouched = false;
  public validators: Array<IValidator> = [];

  constructor(validators: Array<IValidator>) {
    this.validators = validators;
  }
}

export class UserRegistration {
  static mapSaveModel(formControlsState: { [fieldName: string]: FormControl }): DM.UserRegistrationDto {
    return {
      email: formControlsState.email.value,
      firstName: formControlsState.firstName.value,
      lastName: formControlsState.lastName.value,
      countryId: Number(formControlsState.country.value),
      referralName: formControlsState.referralName.value,
      referralEmail: formControlsState.referralEmail.value
    };
  }

  static mapErrorModel(fieldName: string, data: any, state: any): any {
    return (state = {
      formControls: {
        ...state.formControls,
        [fieldName]: {
          ...state.formControls[fieldName],
          errorText: data.errorText ? data.errorText : data[fieldName],
          isValid: data.isValid ? data.isValid : false
        }
      }
    });
  }
}

export class ConfirmModalConfig {
  constructor(
    public openButtonName?: string,
    public openButtonClass?: string,
    public closeButtonName?: string,
    public title?: string,
    public subTitle?: any,
    public description?: any,
    public icon?: string,
    public iconColor?: string,
    public heading?: string,
    public isWarning?: boolean,
    public submitButtonName?: string,
    public className?: string
  ) {}
}

export class ConfirmDialogConfig {
  constructor(
    public openButtonName?: string,
    public closeButtonName?: string,
    public title?: string,
    public subTitle?: string,
    public description?: string
  ) {}
}

export interface AreYouSureModalConfig {
  openButtonName: string;
  closeButtonName: string;
  title: string;
  subTitle: string;
  description: string;
  icon: string;
  iconColor: string;
  okButtonName: string;
  openButtonIcon?: string;
}

export class StepConfig {
  constructor(
    public step: Step,
    public stepTitle: string,
    public description: string,
    public isActive: string,
    public isActivated: string
  ) {}
}

export class RenameConfig {
  constructor(
    public closeButtonName?: string,
    public heading?: string,
    public label?: any,
    public placeholder?: any,
    public submitButtonName?: string,
    public className?: string
  ) {}
}
