import { Option } from '../../../../components/ThemedSelect/ThemedSelect';
import { SetAssetsCaseTypePrimarySectorsAction } from '../self-assessment-assets.actions';
import { SelfAssessmentAssetsReducer, SelfAssessmentAssetsState } from '../self-assessment-assets.interfaces';
import { setAssetsCaseTypePrimarySectorsFetchingReducer } from './setPendingState.reducer';

export const getSelfAssesCaseTypePrimarySectorOption = (primarySector: string): Option => {
  return {
    value: primarySector,
    label: primarySector,
    customValue: primarySector,
    searchValue: primarySector
  };
};

type NormalizeListReturnType = NonNullable<
  SelfAssessmentAssetsState['assetsCaseTypesPrimarySectors'][keyof SelfAssessmentAssetsState['assetsCaseTypesPrimarySectors']]
>;

const normalizeList = (data: string[]): NormalizeListReturnType => {
  const selectorOptions = data.reduce<NormalizeListReturnType['selectorOptions']>((acc, item) => {
    if (item) {
      acc.push(getSelfAssesCaseTypePrimarySectorOption(item));
    }
    return acc;
  }, []);

  return {
    data,
    selectorOptions
  };
};

export const setAssetCaseTypePrimarySectorsReducer: SelfAssessmentAssetsReducer<
  SetAssetsCaseTypePrimarySectorsAction
> = (state, payload) => {
  let newState = setAssetsCaseTypePrimarySectorsFetchingReducer(state, false);

  if (newState.assetsCaseTypesPrimarySectors[payload.caseTypeId]?.data?.length || payload.primarySectors.length) {
    if (newState === state) {
      newState = { ...state };
    }
    newState.assetsCaseTypesPrimarySectors[payload.caseTypeId] = normalizeList(payload.primarySectors);
  }

  return newState;
};
