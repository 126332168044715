import { toast } from 'react-toastify';

import Toast from '../../shared/components/Toast/Toast';
import { CustomToastForSettings } from '../components/CustomToastForSettings/CustomToastForSettings';
import ToastScanFailed from '../components/ToastScanFailed/ToastScanFailed';
import { getFileScanStatusDescription } from './file-scan.utils';

export const initToast = () =>
  toast.configure({
    position: 'top-center',
    hideProgressBar: true,
    autoClose: 1000,
    closeButton: ({ closeToast }: any) => {
      return <div className="custom-toast__toast-close-btn" onClick={closeToast} />;
    },
    toastClassName: 'custom-toast',
    bodyClassName: 'custom-toast__body'
  });

const options = {
  autoClose: 3000,
  hideProgressBar: false,
  icon: false
};

export function toastSuccess(message: string, autoClose = 3000) {
  return toast.success(<Toast type="success" message={message} />, {
    ...options,
    autoClose
  });
}

export function toastError(message: string, id?: string | number) {
  return toast.error(<Toast type="error" message={message} />, id ? { ...options, toastId: id } : options);
}

export function toastScanFailed(scanStatus: any, onApply: () => void) {
  const message = getFileScanStatusDescription(scanStatus);
  if (!message) return;
  toast(<ToastScanFailed message={message} onApply={onApply} />, {
    pauseOnHover: true,
    closeButton: false,
    autoClose: 5000,
    className: 'toastScanFailed',
    hideProgressBar: true
  });
}

export function customToastForSettings(message: string, title: string) {
  if (!message) return;
  toast(<CustomToastForSettings errorTitle={title} errorMessage={message} />, {
    pauseOnHover: true,
    closeButton: false,
    autoClose: 5000,
    className: 'customToastForSettings',
    hideProgressBar: true
  });
}
