import { SET_TELEMETRY_ENVIRONMENT, telemetryState } from './telemetry.types';
import { Reducer } from 'redux';

const initialState: telemetryState = {
  telemetryEnvironmentKey: null
};

export const telemetryReducer: Reducer<any, any> = (state: telemetryState = initialState, action: any) => {
  switch (action.type) {
    case SET_TELEMETRY_ENVIRONMENT:
      return {
        ...state,
        telemetryEnvironmentKey: action.data.connectionString
      };
    default:
      return state;
  }
};
