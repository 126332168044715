import { StoreEntityE } from '../store-entity.enum';
import { IpoScannerRootStateT } from '../store.types';

const getStateData = (state: IpoScannerRootStateT) => state[StoreEntityE.Questionnaire];

export const selectQuestionnaire = (state: IpoScannerRootStateT) => getStateData(state).questionnaire;
export const selectQuestionnaireNotSaved = (state: IpoScannerRootStateT) => getStateData(state).questionnaireNotSaved;
export const selectQuestionnaireLoading = (state: IpoScannerRootStateT) => getStateData(state).loading;
export const selectQuestionnaireGlossarySettings = (state: IpoScannerRootStateT) =>
  getStateData(state).glossarySettings.sort((a, b) => b.Term.length - a.Term.length);
