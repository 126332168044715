import { Dispatch } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { ROUTES } from '../../constants';
import { AdminListItemDto, AdminOPMListItemWithRoleDto } from '../../models/models.dl';
import { onLoaderChanged } from '../actions/loader.actions';
import { onSetCurrentUser } from '../actions/user-profile.actions';
import { RootState } from '../combineReducers';
import { selectCurrentUser } from '../selectors';
import { UserRole } from './../../models/enums.dl';
import Services from './../../shared.services';
import {
  ADMIN_LIST_RESET_STATE,
  ADMIN_LIST_SET_ALL,
  ADMIN_LIST_SET_DELETING,
  ADMIN_LIST_SET_PAGE_DATA,
  ADMIN_LIST_SET_ROLE_CHANGING,
  ADMIN_LIST_SET_SEARCH,
  ADMIN_LIST_SET_SORT_DATA,
  CONTENT_ADMIN_LIST_SET_ALL
} from './admins.action-types';
import {
  AdminListFiltersDataI,
  AdminListPageDataT,
  AdminListSortDataT,
  AdminListStateT,
  ContentAdminI
} from './admins.types';

export const adminListSetAll = (payload: AdminListItemDto[]) => ({
  type: ADMIN_LIST_SET_ALL,
  payload
});

export const contentAdminListSetAll = (payload: ContentAdminI[]) => ({
  type: CONTENT_ADMIN_LIST_SET_ALL,
  payload
});

export const adminListSetSearch = (payload: AdminListFiltersDataI['search']) => ({
  type: ADMIN_LIST_SET_SEARCH,
  payload
});

export const adminListSetPageData = (payload: AdminListPageDataT) => ({
  type: ADMIN_LIST_SET_PAGE_DATA,
  payload
});

export const adminListSetSortData = (payload: AdminListSortDataT) => ({
  type: ADMIN_LIST_SET_SORT_DATA,
  payload
});

export const adminListSetDeleting = (payload: boolean) => ({
  type: ADMIN_LIST_SET_DELETING,
  payload
});

export const adminListSetRoleChanging = (payload: boolean) => ({
  type: ADMIN_LIST_SET_ROLE_CHANGING,
  payload
});

export const adminListResetData = () => ({
  type: ADMIN_LIST_RESET_STATE
});

export type AdminListActionT =
  | ReturnType<typeof adminListSetAll>
  | ReturnType<typeof adminListSetSearch>
  | ReturnType<typeof adminListSetPageData>
  | ReturnType<typeof adminListSetSortData>
  | ReturnType<typeof adminListSetDeleting>
  | ReturnType<typeof adminListSetRoleChanging>
  | ReturnType<typeof contentAdminListSetAll>
  | ReturnType<typeof adminListResetData>;

export const getAdminList = () => async (dispatch: Dispatch) => {
  dispatch(onLoaderChanged(true));
  try {
    const items = await Services.BL.UserAdministrationService.fetchAllAdmins();
    dispatch(onLoaderChanged(false));
    dispatch(adminListSetAll(items));
  } catch {
    dispatch(onLoaderChanged(false));
  }
};

export const fetchContentAdminsData = () => async (dispatch: Dispatch) => {
  try {
    const items = await Services.BL.UserAdministrationService.getContentAdmins();
    dispatch(contentAdminListSetAll(items));
  } catch (error) {
    return Promise.reject(error);
  }
};

export const addAdmins =
  (admins: AdminOPMListItemWithRoleDto[]) =>
  async (dispatch: ThunkDispatch<AdminListStateT, any, AdminListActionT>) => {
    try {
      await Services.BL.UserAdministrationService.addAdmins(admins);
      dispatch(getAdminList());
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const deleteAdmin =
  (email: AdminListItemDto['EmailAddress']) =>
  async (dispatch: ThunkDispatch<AdminListStateT, any, AdminListActionT>) => {
    dispatch(adminListSetDeleting(true));
    try {
      await Services.BL.UserAdministrationService.deleteAdmin(email);
      dispatch(adminListSetDeleting(false));
      dispatch(getAdminList());
    } catch (error) {
      dispatch(adminListSetDeleting(false));
      return Promise.reject(error);
    }
  };

export const updateAdminRole =
  (email: AdminListItemDto['EmailAddress'], role: UserRole) =>
  async (
    dispatch: ThunkDispatch<AdminListStateT, any, AdminListActionT | ReturnType<typeof onSetCurrentUser>>,
    getState: () => RootState
  ) => {
    dispatch(adminListSetRoleChanging(true));
    try {
      await Services.BL.UserAdministrationService.updateAdminRole(email, role);
      const { user } = selectCurrentUser(getState());
      const isCurrentUser = user?.EmailAddress?.toLowerCase() === email.toLowerCase();
      const isCurrentUserDowngraded = isCurrentUser && role < user.RoleId;
      if (isCurrentUser) {
        dispatch(onSetCurrentUser({ ...user, RoleId: role }));
      }
      dispatch(adminListSetRoleChanging(false));
      if (isCurrentUserDowngraded) {
        window.location.replace(`${window.location.origin}${ROUTES.casetypeDashboard}`);
      } else {
        dispatch(getAdminList());
      }
    } catch (error) {
      dispatch(adminListSetRoleChanging(false));
      return Promise.reject(error);
    }
  };
