import { SelfAssessTypeDTO } from '../../shared/interface';
import { RegionE } from '../shared/enums';

export default class IPOConfigService {
  private static apiUrls: Record<RegionE, string> = (window as any).ApiConfig.API_URLS;
  private static defaultRegion: RegionE = (window as any).ApiConfig.DEFAULT_REGION;
  private static mainRegion: RegionE = (window as any).ApiConfig.MAIN_REGION;
  private static country = 'us';
  private static _assetName = 'ipo';
  private static _assetId: SelfAssessTypeDTO['Id'] = 0;

  static set region(region: RegionE) {
    if (region === RegionE.AME || region === RegionE.APA || region === RegionE.EMA) {
      this.defaultRegion = region;
      this.mainRegion = region;
    }
  }

  static get assetId(): SelfAssessTypeDTO['Id'] {
    return this._assetId;
  }

  static set assetId(id: SelfAssessTypeDTO['Id']) {
    this._assetId = id;
  }

  static get assetName() {
    return this._assetName;
  }

  static set assetName(name: string) {
    const result = (name || '').trim().toLowerCase();
    if (result) {
      this._assetName = result;
    } else {
      this._assetName = 'ipo';
    }
  }

  static get isIPO() {
    return this._assetName === 'ipo';
  }

  static get countryCode() {
    return this.country;
  }

  static set countryCode(country: string) {
    const result = (country || '').trim().toLowerCase();
    if (result) {
      this.country = result;
    } else {
      this.country = 'us';
      this.region = RegionE.AME;
    }
  }

  static get ApiUrls() {
    return this.apiUrls;
  }

  static get DefaultRegion() {
    return this.defaultRegion;
  }

  static get MainRegion() {
    return this.mainRegion;
  }
}
