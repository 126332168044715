import IPOConfigService from './config.service';
import type { RegionE } from '../shared/enums';

export default class IPOApiUrlsService {
  private static getApiUrl(region: RegionE, isNewUrl: boolean) {
    return `${IPOConfigService.ApiUrls[region]}/${isNewUrl ? 'selfAssess' : 'ipoReadiness'}`;
  }
  static get defaultRegionApi() {
    return this.getApiUrl(IPOConfigService.DefaultRegion, false);
  }
  static get mainRegionApiUrl() {
    return this.getApiUrl(IPOConfigService.MainRegion, false);
  }
  static get defaultNewRegionApi() {
    return this.getApiUrl(IPOConfigService.DefaultRegion, true);
  }
  static get mainNewRegionApiUrl() {
    return this.getApiUrl(IPOConfigService.MainRegion, true);
  }
}
