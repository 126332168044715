import { default as classNames, default as cs } from 'classnames';
import { FC, useCallback, useMemo, useRef } from 'react';
import Select, {
  components,
  createFilter,
  GroupTypeBase,
  OptionProps,
  Props as SelectProps,
  SelectOptionActionMeta
} from 'react-select';
import { StylesConfig } from 'react-select/src/styles';

import DropdownIcon from '../../assets/icons/DropdownIcon';
import Highlight from '../../shared/components/highlight/highlight';
import ThemedFieldLabel from '../ThemedFieldLabel/ThemedFieldLabel';
import DefaultMultiValue from './DefaultMultiValue/DefaultMultiValue';
import DefaultOption from './DefaultOption/DefaultOption';
import LimitedTagMultiValue from './LimitedTagMultiValue/LimitedTagMultiValue';
import OptionWithCheckbox from './OptionWithCheckbox/OptionWithCheckbox';
import TagMultiValue from './TagMultiValue/TagMultiValue';
import ValueContainer, { ValueContainerPropsI } from './ValueContainer/ValueContainer';

import './ThemedSelect.scss';

export interface Option {
  value: string;
  label: string;
  searchValue?: string;
  customValue?: any;
}

export interface CustomFilterOptionI {
  data: {
    customValue: string;
    label: string;
    searchValue: boolean;
    value: string;
  };
  label: string;
  value: string;
}

export type GroupOption = GroupTypeBase<Option>;

export interface ThemedSelectPropsI
  extends SelectProps<Option, boolean, GroupOption>,
    Pick<ValueContainerPropsI, 'renderValue'> {
  isLoading?: boolean;
  size?: 'small' | 'medium';
  isError?: boolean;
  errorText?: string;
  name?: string;
  className?: string;
  tagsLimit?: number;
  hasTags?: boolean;
  allOptionLabel?: string;
  isTransparent?: boolean;
  withCheckmark?: boolean;
  isHighlightedOption?: boolean;
  withOptionIcons?: boolean;
  valueTooltipClassName?: string;
}

const createAllOption = (label: string) => {
  return {
    label,
    value: '*'
  };
};

const controlBorderColor = ({
  isFocused,
  isTransparent,
  isError
}: Partial<Record<'isFocused' | 'isError' | 'isTransparent', boolean>>) => {
  if (isError) return '#DA291C';
  if (isTransparent) return 'transparent';
  if (isFocused) return '#007CB0';
  return '#A7A8AA';
};

const IndicatorSeparator = () => {
  return null;
};

const DropdownIndicator = (args: any) => {
  return (
    <components.DropdownIndicator {...args} className={cs({ 'rotate-icon': args.selectmenuIsOpen })}>
      <DropdownIcon />
    </components.DropdownIndicator>
  );
};

const defaultFilterOption = createFilter({
  ignoreCase: true,
  ignoreAccents: true,
  matchFrom: 'any',
  stringify: (option) => option.data?.searchValue || option.searchValue || `${option.label} ${option.value}`,
  trim: true
});

const HighlightedOption = (props: any) => {
  const userInputText = props?.selectProps?.inputValue || '';
  const selectOptionLabel = props?.data?.label;

  return (
    <components.Option {...props}>
      <div>{userInputText ? <Highlight text={selectOptionLabel} mask={userInputText} /> : selectOptionLabel}</div>
    </components.Option>
  );
};

const IconSelectOption = (props: any) => {
  return (
    <components.Option {...props}>
      <div className="d-inline-flex align-items-center">
        {props.data.icon}
        {props.data.label}
        {props.data.info}
      </div>
    </components.Option>
  );
};

const IconSelectValue = (props: any) => {
  return (
    <div>
      <div className="d-inline-flex align-items-center">
        {props.data.icon}
        {props.data.label}
      </div>
    </div>
  );
};

export const ThemedSelect: FC<ThemedSelectPropsI> = ({
  menuPosition = 'absolute',
  size = 'small',
  isError,
  errorText,
  className = '',
  hasTags = false,
  tagsLimit,
  label,
  isMulti,
  isHighlightedOption = false,
  hideSelectedOptions = false,
  isClearable = false,
  isRequired,
  allOptionLabel,
  options,
  onChange,
  isTransparent = false,
  filterOption,
  withCheckmark,
  withOptionIcons,
  renderValue,
  name,
  valueTooltipClassName,
  ...props
}) => {
  const renderValueRef = useRef(renderValue);
  renderValueRef.current = renderValue;

  const isMedium = size === 'medium';

  const customStyles = useMemo(
    () => ({
      control: (provided, state) => ({
        ...provided,
        borderRadius: 0,
        borderColor: controlBorderColor({ isFocused: state.isFocused, isTransparent, isError }),
        minHeight: isMedium ? 32 : 24,
        boxShadow: 'none',
        fontSize: isMedium ? 14 : 12,
        cursor: state.isDisabled ? 'default' : 'pointer',
        backgroundColor: isError ? '#FCEEED' : isTransparent ? 'transparent' : '#fff',
        maxHeight: 200,
        overflow: 'auto',
        pointerEvents: 'all',
        '&:hover': {
          borderColor: controlBorderColor({ isFocused: !state.isDisabled, isTransparent, isError })
        },
        '&:active': state.isDisabled
          ? {}
          : {
              backgroundColor: isError ? '#FCEEED' : isTransparent ? 'transparent' : '#F5F5F5'
            },
        fontFamily: 'Open Sans, sans-serif'
      }),
      dropdownIndicator: (provided, state) => ({
        ...provided,
        padding: '5px 10px',
        color: isError ? '#DA291C' : isTransparent ? '#fff' : '#A7A8AA',
        svg: {
          color: isTransparent ? '#fff' : state.selectProps.menuIsOpen ? '#007CB0' : 'initial'
        },
        transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : 'initial'
      }),
      valueContainer: (provided) => ({
        ...provided,
        padding: '0 8px',
        paddingRight: '8px',
        boxSizing: 'border-box',
        minHeight: '20px',
        fontFamily: 'Open Sans, sans-serif',
        flexWrap: hasTags ? 'wrap' : 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: isMulti && !hasTags ? 'nowrap' : provided.whiteSpace,
        maxWidth: isMulti && !hasTags ? 'calc(100% - 30px)' : 'none'
      }),
      clearIndicator: (provided) => ({
        ...provided,
        padding: '2px 0',
        paddingRight: 0
      }),
      menu: (provided) => ({
        ...provided,
        position: 'absolute',
        zIndex: 1001,
        textAlign: 'left',
        fontSize: isMedium ? 14 : 12,
        borderRadius: 'none',
        border: '1px solid #EBEBEB',
        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
        fontFamily: 'Open Sans, sans-serif'
      }),
      multiValueRemove: (provided) => ({
        ...provided,
        display: hasTags ? 'block' : 'none'
      }),
      multiValue: (provided, state) => ({
        ...provided,
        backgroundColor: '#EBEBEB',
        maxWidth: 'calc(100% - 10px)',
        pointerEvents: state.isDisabled ? 'none' : 'all'
      }),
      multiValueLabel: (provided) => ({
        ...provided,
        padding: '4px 0',
        fontSize: isMedium ? 12 : 10,
        lineHeight: 1,
        maxWidth: 'auto'
      }),
      singleValue: (provided) => ({
        color: isTransparent ? '#fff' : provided.color,
        fontWeight: 400
      }),
      placeholder: (provided) => {
        return {
          ...provided,
          top: 'calc(50% - 1px)',
          whiteSpace: 'nowrap',
          color: isError ? '#E98078' : '#A7A8AA'
        };
      },
      indicatorsContainer: (provided) => ({
        ...provided,
        minHeight: isMedium ? 30 : 22
      }),
      input: (provided) => ({
        ...provided,
        padding: 0,
        minHeight: 22,
        margin: 0,
        overflow: 'hidden',
        display: isMulti && !hasTags ? 'inline-block' : provided.display,
        verticalAlign: isMulti && !hasTags ? 'middle' : provided.verticalAlign
      }),
      option: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#F1F8FB' : '#fff',
        color: '#000',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        cursor: 'pointer',
        fontSize: withCheckmark ? '12px' : provided.fontSize,
        fontFamily: withCheckmark ? 'Open Sans' : provided.fontFamily,
        fontStyle: 'normal',
        fontWeight: withCheckmark ? 400 : provided.fontWeight
      }),
      groupHeading: (provided, state) => ({
        ...provided,
        backgroundColor:
          Array.isArray(state.selectProps.value) &&
          state.selectProps.value?.some((item: any) => item?.customValue === (state as any).data.value)
            ? '#F1F8FB'
            : '#fff',
        textOverflow: 'ellipsis',
        overflow: 'hidden'
      })
    }),
    [hasTags, isError, isMedium, isMulti, isTransparent, withCheckmark]
  ) as StylesConfig<Option, boolean>;

  const handleChange: NonNullable<ThemedSelectPropsI['onChange']> = useCallback(
    (selected, action) => {
      if (onChange) {
        if (!allOptionLabel) return onChange(selected as Option[], action);
        const allOption = createAllOption(allOptionLabel);
        if (options) {
          if (
            (action as SelectOptionActionMeta<Option>).option?.value === allOption.value &&
            (selected as Option[]).length !== options.length + 1
          ) {
            return onChange(options as Option[], action);
          }

          if (
            (action as SelectOptionActionMeta<Option>).option?.value === allOption.value &&
            (selected as Option[]).length === options.length + 1
          ) {
            return onChange([], action);
          }
        }

        return onChange(selected as Option[], action);
      }
      return undefined;
    },
    [allOptionLabel, onChange, options]
  );

  const customComponents: NonNullable<ThemedSelectPropsI['components']> = useMemo(
    () => ({
      ...components,
      ...props.components,
      IndicatorSeparator,
      DropdownIndicator: DropdownIndicator,
      Option: isMulti
        ? ({ children, ...optionProps }: OptionProps<Option, boolean>) => (
            <OptionWithCheckbox allOptionLabel={allOptionLabel} {...optionProps}>
              {children}
            </OptionWithCheckbox>
          )
        : withOptionIcons
        ? IconSelectOption
        : isHighlightedOption
        ? HighlightedOption
        : DefaultOption,
      MultiValue: hasTags
        ? tagsLimit
          ? (props) => <LimitedTagMultiValue {...props} tagsLimit={tagsLimit} />
          : TagMultiValue
        : DefaultMultiValue,
      ValueContainer:
        isMulti && !hasTags
          ? (props) => (
              <ValueContainer
                {...props}
                renderValue={renderValueRef.current}
                valueTooltipClassName={valueTooltipClassName}
              />
            )
          : components.ValueContainer,
      SingleValue: withOptionIcons ? IconSelectValue : components.SingleValue
    }),
    [
      props.components,
      isMulti,
      withOptionIcons,
      isHighlightedOption,
      hasTags,
      tagsLimit,
      allOptionLabel,
      valueTooltipClassName
    ]
  );

  const customOptions = useMemo(
    () => (allOptionLabel && options ? [createAllOption(allOptionLabel), ...options] : options),
    [allOptionLabel, options]
  );

  return (
    <div className={classNames('themed-select-wrapper', className)}>
      <ThemedFieldLabel text={label} isRequired={isRequired} />

      <Select
        {...props}
        components={customComponents}
        isMulti={isMulti}
        filterOption={filterOption || defaultFilterOption}
        styles={customStyles}
        isClearable={isClearable}
        hideSelectedOptions={hideSelectedOptions}
        menuPosition={menuPosition}
        onChange={isMulti ? handleChange : onChange}
        options={customOptions}
        customStyles={customStyles}
        name={name}
        classNamePrefix={cs('themed-select', props.classNamePrefix, {
          'themed-select-error': isError,
          'themed-select-checkmark': withCheckmark
        })}
      />
      {isError && !!errorText && <div className="field-error-text">{errorText}</div>}
    </div>
  );
};
