import axios from 'axios';
import { ApiEntityE } from '../shared/enums';
import { QuestionnaireResultDTO } from '../shared/interfaces';
import { MainApiService } from './main-api-service';
import SessionStorageService from './session-storage.service';

class IPOQuestionnaireResultService extends MainApiService {
  constructor() {
    super({ apiEntity: ApiEntityE.QuestionnaireResult, useMemberFirmInUrl: false });
  }

  private get currentDefaultRegionApi() {
    const requestId = SessionStorageService.requestId;
    return `${this.defaultNewRegionApi}/${requestId}`;
  }

  fetchQuestionnaireResult = async (): Promise<QuestionnaireResultDTO> => {
    const { data } = await axios.get<QuestionnaireResultDTO>(`${this.currentDefaultRegionApi}`);
    return data;
  };
}

export default new IPOQuestionnaireResultService();
