import { withTimeZoneOffset } from './services.helpers';
import axios from 'axios';
import { ROUTE } from '../routes';
import { ApiEntityE, AuthRedirectionE } from '../shared/enums';
import { AuthenticationDataDTO, RefreshTokenDataDTO } from '../shared/interfaces';
import { RouteParamsT } from './../routes/types/route-params';
import { MainApiService } from './main-api-service';
import IPOConfigService from './config.service';

const tokenKey = 'IPOAccessToken';
interface AccessTokenStorageI {
  token: string;
  expiresAt: string;
}

const tokenStorage = sessionStorage;

const setTokenToStorage = (data: AccessTokenStorageI) => {
  if (data.token && data.expiresAt) {
    tokenStorage.setItem(tokenKey, JSON.stringify(data));
  } else {
    tokenStorage.removeItem(tokenKey);
  }
};

const getTokenFromStorage = (): AccessTokenStorageI | null => {
  const tokenStr = tokenStorage.getItem(tokenKey);

  if (!tokenStr) {
    return null;
  }

  return JSON.parse(tokenStr);
};

class IPOAuthenticationService extends MainApiService {
  private AccessToken = '';
  private accessTokenExpiresAt = '';
  private timer: NodeJS.Timeout | null = null;
  private expiredCb = () => {};

  constructor() {
    super({ useMemberFirmInUrl: false });
    const token = getTokenFromStorage();
    if (token) {
      this.token = token;
    }
  }

  get tokenExpired() {
    return this.secondsToExpired <= 0;
  }

  get accessToken() {
    return this.AccessToken;
  }

  get isAuthenticated() {
    return !!this.accessToken && !this.tokenExpired;
  }

  stopTokenAutoRefresh = () => {
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = null;
    }
  };

  authenticate = async (params: RouteParamsT<ROUTE.SecureCode>, cb: VoidFunction): Promise<AuthenticationDataDTO> => {
    const { data } = await axios.post<AuthenticationDataDTO>(
      `${this.defaultRegionApi}/${ApiEntityE.Authenticate}`,
      withTimeZoneOffset({
        ...params,
        memberFirm: IPOConfigService.countryCode
      })
    );
    this.expiredCb = cb;
    if (
      data.ActionCode === AuthRedirectionE.ShowQuestionnaire ||
      data.ActionCode === AuthRedirectionE.ShowQuestionnaireResult
    ) {
      this.token = {
        token: data.AccessToken,
        expiresAt: data.ExpiresAt
      };
    } else if (data.DemoEnvironment && data.ActionCode === AuthRedirectionE.ShowCheckEmail) {
      return this.authenticate(
        {
          requestId: `${data.RequestId}`,
          scrWord: data.ScrWord,
          countryCode: params.countryCode,
          assetName: params.assetName
        },
        cb
      );
    }
    return data;
  };

  private get secondsToExpired() {
    if (!this.AccessToken || !this.accessTokenExpiresAt) return 0;
    const now = new Date().getTime();
    const expiresAt = new Date(this.accessTokenExpiresAt).getTime();
    return Math.floor((expiresAt - now) / 1000);
  }

  private get canAutoRefreshToken() {
    // INFO: refresh token 5 minutes earlier
    return !this.tokenExpired && this.secondsToExpired <= 5 * 60;
  }

  private runTokenAutoRefresh = () => {
    this.stopTokenAutoRefresh();
    this.timer = setInterval(() => {
      if (this.tokenExpired) {
        this.stopTokenAutoRefresh();
        this.expiredCb();
      } else if (this.canAutoRefreshToken) {
        this.refreshToken();
      }
    }, 60000);
  };

  private refreshToken = async () => {
    if (this.accessToken) {
      try {
        const { data } = await axios.post<RefreshTokenDataDTO>(`${this.defaultRegionApi}/${ApiEntityE.RefreshToken}`);
        this.token = {
          token: data.AccessToken,
          expiresAt: data.ExpiresAt
        };
      } catch {}
    }
  };

  private set token(data: AccessTokenStorageI) {
    setTokenToStorage(data);
    this.AccessToken = data.token;
    this.accessTokenExpiresAt = data.expiresAt;
    if (!this.tokenExpired) {
      this.runTokenAutoRefresh();
    }
  }
}

export default new IPOAuthenticationService();
