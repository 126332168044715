import { Reducer } from 'redux';
import { GET_SCORECARD_REPORT, RE_SET_REDUCER, reportState } from './Scorecardreport.types';

const initialState: reportState = {
  NewScoreCardReport: []
};

export const scorecardReportReducer: Reducer<any, any> = (state: reportState = initialState, action: any) => {
  switch (action.type) {
    case GET_SCORECARD_REPORT:
      return { ...state, NewScoreCardReport: action.data };
    case RE_SET_REDUCER:
      return { ...initialState };
    default:
      return state;
  }
};
