import axios from 'axios';
import ApiUrlsService from './api-urls.service';
import type { UserRole } from '../../models/enums.dl';
import type { AdminListItemDto, AdminOPMListItemDto, AdminOPMListItemWithRoleDto } from '../../models/models.dl';
import { ContentAdminI } from '../../store/admins';

/* const ADMIN_USERS_DATA: AdminListItemDto[] = Array.from(Array(11), (_, idx) => {
  const uid = idx + 1;
  return {
    uid: `${uid}`,
    email: `test-initial${uid}@init.com`,
    firstName: `InitialFirstName ${uid}`,
    lastName: `InitialLastName ${uid}`,
    createdDate: `${2000 + uid}-10-26T13:44:22.723`,
    role: idx % 2 === 0 ? UserRole.GlobalAdmin : UserRole.Admin
  };
});

const OPM_ADMIN_USER_DATA: AdminOPMListItemDto[] = Array.from(Array(20), (_, idx) => {
  const uid = ADMIN_USERS_DATA.length + 1 + idx;
  const firstName = `AddedFirstName ${uid}`;
  const lastName = `AddedLastName ${uid}`;
  return {
    email: `test-added${uid}@added.com`,
    firstName,
    lastName,
    preferredFullName: `${lastName}, ${firstName}`,
    globalPersonUid: `${uid}`,
    globalPersonId: uid,
    jobTitle: 'Coordinator',
    memberFirmCode: 'AU',
    countryCode: 'AU'
    // role: idx % 3 === 0 ? undefined : idx % 2 === 0 ? UserRole.GlobalAdmin : UserRole.Admin
  };
}); */

export default class UserAdministrationService {
  static fetchAllAdmins = () => axios.get<AdminListItemDto[]>(ApiUrlsService.getAdminList()).then(({ data }) => data);

  static deleteAdmin = (email: AdminListItemDto['EmailAddress']) =>
    axios
      .delete<AdminListItemDto['EmailAddress']>(ApiUrlsService.deleteAdmin(), {
        data: email
      })
      .then(({ data }) => data);

  static updateAdminRole = (email: AdminListItemDto['EmailAddress'], role: UserRole) =>
    axios
      .put<AdminListItemDto>(ApiUrlsService.updateAdminRole(), {
        emailAddress: email,
        userRole: role
      })
      .then(({ data }) => data);

  static fetchOPMAdmins = (search: string) =>
    axios
      .get<AdminOPMListItemDto[]>(ApiUrlsService.getOPMAdminList(), {
        params: { search }
      })
      .then(({ data }) => data);

  static addAdmins = (items: AdminOPMListItemWithRoleDto[]) =>
    axios
      .post<AdminListItemDto[]>(
        ApiUrlsService.addAdmins(),
        items.map(({ email, role }) => ({
          emailAddress: email,
          userRole: role
        }))
      )
      .then(({ data }) => data);

  static getContentAdmins = () =>
    axios.get<ContentAdminI[]>(ApiUrlsService.getContentAdmins()).then(({ data }) => data);
}
