import { ThunkAction } from 'redux-thunk';
import axios from 'axios';
import Services from '../../shared.services';
import { setTelemetryEnvironment } from './telemetry.action';

export const fetchTelemetryEnvironment = (): ThunkAction<any, any, any, any> => {
  return (dispatch) => {
    return axios.get(Services.BL.ApiUrlsService.getMultiGeoEnvironments()).then((response) => {
      dispatch(setTelemetryEnvironment(response.data));
    });
  };
};
