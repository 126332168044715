import { GetSettingsFGAFilesListResponse, SettingsFile } from '../../interface';

export interface SettingsState {
  filesList: SettingsFile[];
  filesListTotalRecords: number;
  filesListLoading: boolean;
  fileLoading: boolean;
}

export const GET_SETTINGS_FILES_LIST_PENDING = 'GET_SETTINGS_FILES_LIST_PENDING';
export const GET_SETTINGS_FILES_LIST_FULFILLED = 'GET_SETTINGS_FILES_LIST_FULFILLED';
export const GET_SETTINGS_FILES_LIST_REJECTED = 'GET_SETTINGS_FILES_LIST_REJECTED';

export const GET_SETTINGS_FGA_FILE_BY_ID_PENDING = 'GET_SETTINGS_FGA_FILE_BY_ID_PENDING';
export const GET_SETTINGS_FGA_FILE_BY_ID_FULFILLED = 'GET_SETTINGS_FGA_FILE_BY_ID_FULFILLED';
export const GET_SETTINGS_FGA_FILE_BY_ID_REJECTED = 'GET_SETTINGS_FGA_FILE_BY_ID_REJECTED';

export type GetSettingsFGAFilesAction = {
  type: string;
  payload: GetSettingsFGAFilesListResponse;
};

export type SettingAction = GetSettingsFGAFilesAction;
