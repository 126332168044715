import { Option } from '../../../../components/ThemedSelect/ThemedSelect';
import { SelfAssessTypeDTO } from '../../../interface';
import { SetAssetsListAction } from '../self-assessment-assets.actions';
import { SelfAssessmentAssetsReducer, SelfAssessmentAssetsState } from '../self-assessment-assets.interfaces';
import { setAssetsListFetchingReducer } from './setPendingState.reducer';

export const getSelfAssessmentAssetOption = (asset: SelfAssessTypeDTO): Option => {
  return {
    value: `${asset.Id}`,
    label: asset.Name,
    customValue: asset,
    searchValue: `${asset.Name} ${asset.ShortName}`
  };
};

const normalizeList = (list: SelfAssessTypeDTO[]) =>
  list.reduce<SelfAssessmentAssetsState['assets']>(
    (acc, item) => {
      acc.ids.push(item.Id);
      acc.data[item.Id] = item;
      acc.selectorOptions.push(getSelfAssessmentAssetOption(item));
      return acc;
    },
    { ids: [], data: {}, selectorOptions: [] }
  );

export const setAssetListReducer: SelfAssessmentAssetsReducer<SetAssetsListAction> = (state, payload) => {
  let newState = setAssetsListFetchingReducer(state, false);

  if (newState.assets.ids.length || payload.length) {
    if (newState === state) {
      newState = { ...state };
    }
    newState.assets = normalizeList(payload);
  }

  return newState;
};
