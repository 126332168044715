import { Component, ReactNode } from 'react';
import { Provider } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Services from './shared/shared.services';
import { MsalAuthProvider, MsalAuthService } from './msal-auth';

import { ROUTES } from './shared/constants';
import GlobalInterceptor from './shared/interceptors/global.interceptor';
import { AppProps, AppState } from './shared/interface';
import { oneTrustService } from './shared/services/dl/oneTrust.service';
import { store } from './shared/store';
import AppInsightsProvider from './telemetry/appInsights/AppInsightsProvider';

import './assets/styles/custom.css';
import Routes from './Routes';
import Footer from './shared/components/layout/footer/footer';
import Header from './shared/components/layout/header/header';
import Loader from './shared/components/loader/loader';

import 'react-toastify/dist/ReactToastify.css';
import './assets/styles/index.css';
import './assets/styles/style.css';
import './assets/styles/toast.scss';

class App extends Component<AppProps, AppState> {
  private isRedirectAllowed = false;

  constructor(props: AppProps) {
    super(props);
    GlobalInterceptor.init();
    this.state = {
      redirectRoute: '',
      isLoading: false
    };

    store.subscribe(() => {
      this.setState({
        isLoading: store.getState().loader.isLoading && !store.getState().loader.isCustomLoadingLogic
      });
      const redirectToRoute = store.getState().redirectTo;

      if (redirectToRoute !== '') {
        this.redirect(redirectToRoute);
        Services.BL.RouteService.setRoute('');
      }
    });
  }

  private listenUserLoadedEvent(): void {
    MsalAuthService.listenUserLoadedEvent((user) => {
      localStorage.removeItem('defaultRegion');

      Services.BL.AuthorizationService.resolveToken(
        user,
        (route) => {
          this.redirect(route);
        },
        () => {
          this.redirect(ROUTES.unAuthorized);
        }
      );
    });
  }

  private redirect(route: string): void {
    this.isRedirectAllowed = true;
    this.setState({ redirectRoute: route });
  }

  componentDidMount(): void {
    this.listenUserLoadedEvent();
    oneTrustService.init();
  }

  render(): ReactNode {
    let redirectRoute: ReactNode = null;

    if (this.state.redirectRoute && this.isRedirectAllowed) {
      redirectRoute = <Redirect to={this.state.redirectRoute} />;
      this.isRedirectAllowed = false;
    }

    return (
      <Provider store={store}>
        <MsalAuthProvider>
          <AppInsightsProvider>
            <div className="app-wrapper">
              <Header />
              <main className="app-main">
                {this.state.isLoading && <Loader />}
                {redirectRoute}
                <Routes />
              </main>
              <Footer />
            </div>
          </AppInsightsProvider>
        </MsalAuthProvider>
      </Provider>
    );
  }
}

export default App;
