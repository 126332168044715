import { matchPath } from 'react-router-dom';
import { ROUTE } from '../constants';
import { RouteParamsT } from '../types/route-params';

export interface CheckPathnameIsRouteI<T extends ROUTE> {
  pathname: string;
  route: T | readonly T[] | undefined;
  exact?: boolean;
}

export const checkPathnameIsRoute = <T extends ROUTE>({ pathname, route, exact = true }: CheckPathnameIsRouteI<T>) => {
  // @ts-ignore
  return matchPath<RouteParamsT<T>>(pathname, { path: route, exact });
};
