import { ACTION_TYPES, languageUnavailStoreType } from '../store';

export function languageUnavailReducer(state = '', action: languageUnavailStoreType): string {
  switch (action.type) {
    case ACTION_TYPES.NO_LANGUAGE:
      return action.languageUnavail;
    default:
      return state;
  }
}

export default languageUnavailReducer;
