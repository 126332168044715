import {
  SetAssetCreatingAction,
  SetAssetDeletingAction,
  SetAssetUpdatingAction,
  SetAssetsCaseTypePrimarySectorsFetchingAction,
  SetAssetsCountriesFetchingAction,
  SetAssetsCountryStatesFetchingAction,
  SetAssetsListFetchingAction
} from '../self-assessment-assets.actions';
import { SelfAssessmentAssetsReducer, SelfAssessmentAssetsState } from '../self-assessment-assets.interfaces';

type PendingKeys = keyof SelfAssessmentAssetsState['pendingState'];
type PendingAction =
  | SetAssetsListFetchingAction
  | SetAssetsCountriesFetchingAction
  | SetAssetsCountryStatesFetchingAction
  | SetAssetCreatingAction
  | SetAssetUpdatingAction
  | SetAssetDeletingAction
  | SetAssetsCaseTypePrimarySectorsFetchingAction;

const createPendingReducer =
  <T extends PendingAction>(key: PendingKeys): SelfAssessmentAssetsReducer<T> =>
  (state, payload) =>
    state.pendingState[key] === payload
      ? state
      : {
          ...state,
          pendingState: {
            ...state.pendingState,
            [key]: payload
          }
        };

export const setAssetsListFetchingReducer = createPendingReducer<SetAssetsListFetchingAction>('assetsFetching');
export const setAssetsCountriesFetchingReducer =
  createPendingReducer<SetAssetsCountriesFetchingAction>('countriesFetching');
export const setAssetsCountryStatesFetchingReducer =
  createPendingReducer<SetAssetsCountryStatesFetchingAction>('countryStatesFetching');
export const setAssetCreatingReducer = createPendingReducer<SetAssetCreatingAction>('assetCreating');
export const setAssetUpdatingReducer = createPendingReducer<SetAssetUpdatingAction>('assetUpdating');
export const setAssetDeletingReducer = createPendingReducer<SetAssetDeletingAction>('assetDeleting');
export const setAssetsCaseTypePrimarySectorsFetchingReducer =
  createPendingReducer<SetAssetsCaseTypePrimarySectorsFetchingAction>('primarySectorsFetching');
