import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import reducer from './combineReducers/index';

// const dev = process.env.NODE_ENV === `development` ? applyMiddleware(logger) : undefined;
// const dev = undefined;

const enhancers: Array<any> = [];
const windowIfDefined = window as any;

if (windowIfDefined?.__REDUX_DEVTOOLS_EXTENSION__ && process.env.NODE_ENV === 'development') {
  enhancers.push(windowIfDefined.__REDUX_DEVTOOLS_EXTENSION__());
}

export const store = createStore(reducer, compose(applyMiddleware(thunk), ...enhancers));
