import { store } from './../../store';
import { onCurrentUserSetToken, onSetCurrentUser } from '../../store/actions/user-profile.actions';

import * as Models from '../../shared.models';
import { AuthServiceUser } from '../../models/models.bl';

export default class AuthenticationService {
  static logOut(): void {
    store.dispatch(onSetCurrentUser(null as unknown as Models.DM.UserGetDto));
    this.clearToken();
  }

  static setToken(tokenObj: AuthServiceUser): void {
    store.dispatch(onCurrentUserSetToken(tokenObj));
  }

  static clearToken(): void {
    // @ts-ignore
    store.dispatch(onCurrentUserSetToken(null));
  }

  static isAuthenticated(tokenObj: AuthServiceUser): boolean {
    return Boolean(tokenObj || store.getState().currentUser.tokenObj);
  }

  static getToken(): string {
    const storeCurrentUser: Models.BM.UserProfile = store.getState().currentUser;
    const token: string =
      storeCurrentUser && storeCurrentUser.tokenObj && storeCurrentUser.tokenObj.accessToken
        ? storeCurrentUser.tokenObj.accessToken
        : '';

    return token;
  }
}
