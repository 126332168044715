import { useCallback, useEffect } from 'react';
import { useAppInsightsContext } from './appInsights.context';
import type { AppInsightsEventTelemetryTypeE, AppInsightsScreenTelemetryTypeE } from './appInsights.enums';
import { getEventTelemetry, getScreenTelemetry } from './appInsights.helpers';
import type { ReactPlugin } from './appInsights.types';

export const useAppInsights = () => useAppInsightsContext();

export const useAppInsightsTrackScreen = (screen: AppInsightsScreenTelemetryTypeE): ReactPlugin => {
  const appInsights = useAppInsights();
  useEffect(() => {
    appInsights.trackPageView(getScreenTelemetry(screen));
  }, [appInsights, screen]);

  return appInsights;
};

// INFO: use it for modals - so you don't have to keep track of every button click that opens same modal
export const useAppInsightsTrackEventAsScreen = (
  eventType: AppInsightsEventTelemetryTypeE,
  // INFO: to trigger event when modal in opened mode only
  track: boolean
): ReactPlugin => {
  const appInsights = useAppInsights();
  useEffect(() => {
    if (track) {
      appInsights.trackEvent(getEventTelemetry(eventType));
    }
  }, [appInsights, eventType, track]);

  return appInsights;
};

export const useAppInsightsTrackEvent = (reactPlugin: ReactPlugin, eventType: AppInsightsEventTelemetryTypeE) =>
  useCallback(() => reactPlugin.trackEvent(getEventTelemetry(eventType)), [reactPlugin, eventType]);
