export enum HttpStatusE {
  BadRequest = 400,
  Unauthorized = 401,
  Forbidden = 403,
  NotFound = 404,
  Conflict = 409,
  PreconditionFailed = 412,
  ServerError = 500,
  ServiceUnavailable = 503
}
