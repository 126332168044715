import { useEffect } from 'react';
import { useTypedDispatch, useTypedSelector } from '../hooks';
import {
  selectLandingSettings,
  selectSettings,
  selectSettingsCountryStates,
  selectSettingsLoading,
  selectSettingsShowStates
} from './settings.selector';
import { getSettings } from './settings.thunk';

export const useSelectSettingsLoading = () => useTypedSelector(selectSettingsLoading);
export const useSelectSettings = () => useTypedSelector(selectSettings);
export const useSelectLandingSettings = () => useTypedSelector(selectLandingSettings);

export const useLoadSettingsOnMount = () => {
  const dispatch = useTypedDispatch();

  useEffect(() => {
    dispatch(getSettings()).catch(() => {});
  }, [dispatch]);
};

export const useLoadLandingSettingsOnMount = () => {
  const dispatch = useTypedDispatch();

  useEffect(() => {
    dispatch(getSettings()).catch(() => {});
  }, [dispatch]);
};

export const useSelectSettingsCountryStates = () => useTypedSelector(selectSettingsCountryStates) || [];
export const useSelectSettingsShowStates = () => useTypedSelector(selectSettingsShowStates);
