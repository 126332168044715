import React, { FC } from 'react';

export const ShieldIcon: FC = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 0C24.8366 0 32 7.16344 32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0ZM16.4209 6.23005C17.4252 7.79588 18.4978 8.71528 19.6288 9.01688C20.3067 9.19765 21.2703 9.09219 22.3802 8.76805C23.0072 8.58495 23.5832 8.36423 24.0347 8.16371L24.285 8.0486C24.6168 7.89054 25 8.13247 25 8.5C25 18.7048 22.0583 24.5882 16.1145 25.9867C16.0392 26.0044 15.9608 26.0044 15.8855 25.9867C13.5437 25.4357 11.6679 24.1885 10.2544 22.2551L10.1464 22.1464L10.1637 22.1292C8.05028 19.1523 7 14.5976 7 8.5C7 8.1569 7.33329 7.92452 7.64605 8.02154L7.72361 8.05279L7.7675 8.07429L8.07432 8.21412C8.35848 8.33844 8.67159 8.46297 9.00337 8.57909C9.67354 8.81365 10.3306 8.98275 10.937 9.05855C11.4897 9.12763 11.9766 9.11546 12.3787 9.01493C13.5692 8.71731 14.6343 7.80439 15.5713 6.24275C15.7621 5.9247 16.2206 5.91785 16.4209 6.23005ZM23.984 9.722L11.4972 22.209C12.5838 23.4857 13.9225 24.3646 15.5115 24.8505L15.8558 24.9487L16 24.984L16.1442 24.9487C21.0201 23.6587 23.643 18.8321 23.966 10.3416L23.984 9.722ZM22.5277 9.766C21.4442 10.07 20.4712 10.1876 19.6607 10.0465L9.94366 19.763C10.2266 20.3603 10.5404 20.9089 10.8849 21.409L22.5277 9.766ZM16.011 7.4L15.8801 7.58589C14.9372 8.87136 13.8514 9.67753 12.6213 9.98507C12.0859 10.1189 11.4791 10.1341 10.813 10.0508C10.2421 9.97947 9.64493 9.83837 9.03812 9.64506L8.67302 9.52295L8.32327 9.39538L8.006 9.27L8.0085 9.43437C8.07874 13.2369 8.58384 16.3463 9.51997 18.7729L18.596 9.69798C17.8034 9.32327 17.0594 8.70706 16.362 7.85513L16.1541 7.59248L16.011 7.4Z"
      fill="#DA291C"
    />
  </svg>
);
