import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { generatePath } from 'react-router-dom';
import { toastError } from '../../shared/utils/toast.utils';
import { ROUTE } from '../routes';
import { IPOAuthenticationService, IPOConfigService } from '../services';
import { ApiEntityE, HttpStatusE } from '../shared/enums';

class IPOGlobalInterceptor {
  static init(): void {
    this.request();
    this.response();
  }

  static request(): any {
    return axios.interceptors.request.use(
      (request: AxiosRequestConfig) => {
        this.requestHandler(request);
        return request;
      },
      (err: AxiosError) => {
        return this.errorResponseHandler(err);
      }
    );
  }

  static response(): any {
    return axios.interceptors.response.use(
      (response: AxiosResponse) => response,
      (err: AxiosError) => {
        return this.errorResponseHandler(err);
      }
    );
  }

  private static requestHandler(request: AxiosRequestConfig): AxiosRequestConfig {
    const token = IPOAuthenticationService.accessToken;

    if (!request.headers) {
      request.headers = {};
    }

    if (token) {
      request.headers.Authorization = `Bearer ${token}`;
    }

    if (request.headers && !request.headers.Accept) {
      request.headers.Accept = 'application/json';
    }

    if (request.headers && !request.headers['Content-Type']) {
      request.headers['Content-Type'] = 'application/json';
    }

    return request;
  }

  private static errorResponseHandler(error: AxiosError): Promise<void> {
    if (error.config.url) {
      const url = new URL(error.config.url);
      if (
        url.pathname.endsWith(ApiEntityE.Telemetry) ||
        url.pathname.endsWith(ApiEntityE.Geo) ||
        url.pathname.endsWith(`${ApiEntityE.Types}/id`) ||
        /\/(file|photo)\/\d+$/i.test(url.pathname)
      ) {
        return Promise.reject(error);
      }
    }

    const status = error.response?.status || 0;

    if (status === HttpStatusE.NotFound) {
      window.location.replace(
        generatePath(ROUTE.NotFound, {
          assetName: IPOConfigService.assetName,
          countryCode: IPOConfigService.countryCode
        })
      );
    }

    if (!status || status >= HttpStatusE.ServerError) {
      toastError('Something went wrong');
    }

    return Promise.reject(error);
  }
}

export default IPOGlobalInterceptor;
