import React from 'react';
import { components, MultiValueProps, GroupTypeBase } from 'react-select';

import { Option } from '../ThemedSelect';
import './LimitedTagMultiValue.scss';

type CommonProps = MultiValueProps<Option, GroupTypeBase<Option>>;

interface TagMultiValuePropsI extends CommonProps {
  index?: number;
  tagsLimit: number;
}

const LimitedTagMultiValue = (props: TagMultiValuePropsI) => {
  const { index = -1, getValue, tagsLimit } = props;
  const items = getValue()
    .slice(tagsLimit)
    .map((item: { label: string }) => item.label);

  if (index < tagsLimit) {
    return <components.MultiValue {...props} className="tag-multi-value" />;
  }

  if (index === tagsLimit) {
    return (
      <components.MultiValue {...props} className="tag-multi-value tag-selected-count">
        +{items.length} selected
      </components.MultiValue>
    );
  }

  return null;
};

export default LimitedTagMultiValue;
