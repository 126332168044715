export enum AppInsightsScreenTelemetryTypeE {
  landingPageView = 'Navigate Navigator Landing Page',
  dashboardPageView = 'Engagement List',
  createCasePageView = 'Create Engagement',
  createEngagementType = 'Create Engagement Type',
  editEngagementType = 'Edit Engagement Type',
  copyFromEngagementType = 'Copy Engagement Type',
  workFlowPageView = 'Engagement Details',
  manageEngagementVisibility = 'Manage Engagement Visibility',
  engagementQuestionnaireDetails = 'Engagement Questionnaire Details',
  scorecardViewReport = 'Scorecard View Report',
  roadmapViewReport = 'Roadmap View Report',
  financialGapAnalysisViewReport = 'Financial Gap Analysis View Report',
  illustrativeFinancialImpactViewReport = 'Illustrative Financial Impact  View Report',
  financeMaturityModelViewReport = 'Finance Maturity Model View Report',
  sapViewReport = 'SAP View Report',
  engagementTypeDetails = 'Engagement Type Details',
  userList = 'User List',
  settingsList = 'Settings List',
  recycleBin = 'Recycle Bin',
  engagementTypeList = 'Engagement Type List'
}

export enum AppInsightsEventTelemetryTypeE {
  disruptiveEventsSelect = 'Navigate Disruptive Events Advisory',
  accountingOperationsSelect = 'Navigate Accounting Operations Advisory',
  accountingAndReportingSelect = 'Navigate Accounting Reporting Advisory',
  businessAssuranceSelect = 'Navigate Business Assurance',
  cancelCreateCaseModalConfirm = 'Cancel Add Engagement',
  addEngagement = 'Add Engagement',
  confirmEngagementPolicies = 'Confirm Engagement Policies',
  teamManageModalOpen = 'Manage Team Members',
  teamManageModalConfirm = 'Add Team Member',
  shareEngagement = 'Share Engagement',
  confirmShareEngagement = 'Confirm Share Engagement',
  cancelShareEngagement = 'Cancel Share Engagement',
  deleteEngagement = 'Delete Engagement',
  confirmDeleteEngagement = 'Confirm Delete Engagement',
  cancelDeleteEngagement = 'Cancel Delete Engagement',
  editEngagement = 'Edit Engagement',
  updateEngagement = 'Update Engagement',
  cancelUpdateEngagement = 'Cancel Update Engagement',
  saveInputVisibility = 'Save Input Visibility',
  cancelSaveInputVisibility = 'Cancel Save Input Visibility',
  createQuestionNote = 'Create Question Note',
  saveQuestionNote = 'Save Question Note',
  cancelQuestionNote = 'Cancel Question Note',
  saveAndContinueQuestionnaire = 'Save And Continue Questionnaire',
  completeQuestionnaire = 'Complete Questionnaire',
  scorecardEditInputs = 'Scorecard Edit Inputs',
  scorecardExportReport = 'Scorecard Export Report',
  roadmapCreateNote = 'Roadmap Create Note',
  roadmapAddNote = 'Roadmap Add Note',
  adminTool = 'Admin Tool',
  roadmapCancelNote = 'Roadmap Cancel Note',
  roadmapManuallySave = 'Roadmap Manually Save',
  roadmapExportReport = 'Roadmap Export Report',
  summaryReportCreateQuestionnaire = 'Summary Report Create Questionnaire',
  summaryReportAddQuestionnaire = 'Summary Report Add Questionnaire',
  summaryReportDownload = 'Summary Report Download',
  financialGapAnalysisEditInputs = 'Financial Gap Analysis Edit Inputs',
  financialGapAnalysisDownloadReport = 'Financial Gap Analysis Download Report',
  illustrativeFinancialImpactEditInputs = 'Illustrative Financial Impact  Edit Inputs',
  illustrativeFinancialImpactDownloadReport = 'Illustrative Financial Impact  Download Report',
  financeMaturityModelEditInputs = 'Finance Maturity Model Edit Inputs',
  financeMaturityModelDownloadReport = 'Finance Maturity Model Download Report',
  sapDownload = 'SAP Download',
  addEngagementType = 'Add Engagement Type',
  cancelAddEngagementType = 'Cancel Add Engagement Type',
  addCopyEngagementType = 'Add Copy Engagement Type',
  cancelCopyEngagementType = 'Cancel Copy Engagement Type',
  deleteEngagementType = 'Delete Engagement Type',
  createModule = 'Create Module',
  addModule = 'Add Module',
  cancelAddModule = 'Cancel Add Module',
  updateEngagementType = 'Update Engagement Type',
  cancelEditEngagementType = 'Cancel Edit Engagement Type',
  unpublishEngagementType = 'Unpublish Engagement Type',
  confirmUnpublishEngagementType = 'Confirm Unpublish Engagement Type',
  cancelUnpublishEngagementType = 'Cancel Unpublish Engagement Type',
  publishEngagementType = 'Publish Engagement Type',
  confirmPublishEngagementType = 'Confirm Publish Engagement Type',
  cancelPublishEngagementType = 'Cancel Publish Engagement Type',
  confirmRepublishEngagementType = 'Confirm Republish Engagement Type',
  renameModule = 'Rename Module',
  saveRenameModule = 'Save Rename Module',
  cancelRenameModule = 'Cancel Rename Module',
  importQuestionnaire = 'Import Questionnaire',
  saveImportQuestionnaire = 'Save Import Questionnaire',
  cancelImportQuestionnaire = 'Cancel Import Questionnaire',
  downloadQuestionnaireTemplate = 'Download Questionnaire Template',
  deleteModule = 'Delete Module',
  confirmDeleteModule = 'Confirm Delete Module',
  cancelDeleteModule = 'Cancel Delete Module',
  renameQuestionnaire = 'Rename Questionnaire',
  saveRenameQuestionnaire = 'Save Rename Questionnaire',
  cancelRenameQuestionnaire = 'Cancel Rename Questionnaire',
  exportQuestionnaire = 'Export Questionnaire',
  removeQuestionnaire = 'Remove Questionnaire',
  confirmRemoveQuestionnaire = 'Confirm Remove Questionnaire',
  cancelRemoveQuestionnaire = 'Cancel Remove Questionnaire',
  createQuestionnaire = 'Create Questionnaire',
  addQuestionnaire = 'Add Questionnaire',
  cancelAddQuestionnaire = 'Cancel Add Questionnaire',
  confirmSaveChanges = 'Confirm Save Changes',
  exportStatisticReport = 'Export Statistic Report',
  cancelStatisticReport = 'Cancel Statistic Report',
  createUser = 'Create User',
  addUser = 'Add User',
  cancelAddUser = 'Cancel Add User',
  changeUserRole = 'Change User Role',
  removeUser = 'Remove User',
  confirmRemoveUser = 'Confirm Remove User',
  cancelRemoveUser = 'Cancel Remove User',
  saveSettings = 'Save Settings',
  statisticReport = 'Statistic Report'
}
