import { Reducer } from 'redux';
import { QuestionnaireStateActionT, QuestionnaireStateI } from './questionnaire.interface';
import {
  SELECT_ANSWER,
  SET_QUESTIONNAIRE,
  SET_LOADING,
  CLEAR_STATE,
  SET_QUESTIONNAIRE_GLOSSARY_SETTINGS
} from './questionnaire.types';
import { TemplateTypes } from '../../../../shared/models/enums.bl';

const initialState: QuestionnaireStateI = {
  questionnaire: null,
  questionnaireNotSaved: false,
  loading: true,
  glossarySettings: []
};

export const questionnaireReducer: Reducer<QuestionnaireStateI, QuestionnaireStateActionT> = (
  state = initialState,
  action
): QuestionnaireStateI => {
  switch (action.type) {
    case SET_QUESTIONNAIRE: {
      return {
        ...state,
        questionnaire: action.payload,
        questionnaireNotSaved: false
      };
    }

    case SET_QUESTIONNAIRE_GLOSSARY_SETTINGS: {
      return {
        ...state,
        glossarySettings: action.payload
      };
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case CLEAR_STATE: {
      return initialState;
    }

    case SELECT_ANSWER: {
      const { questionnaire } = state;
      if (!questionnaire) return state;

      const { questionSectionOrder, questionUid, answerUid, TextBoxValue } = action.payload;
      let isStepAnswered = true;
      const selectedAnswers: Record<string, boolean> = {};
      const visibleQuestions: Record<string, boolean> = {};

      const newQuestions = questionnaire.Question.QuestionGroups.map((question) => {
        visibleQuestions[question.QuestionId] =
          !question.ActivationAnswerOptionUids.length ||
          question?.ActivationAnswerOptionUids.every((item) => {
            return item.AnswerOptionUids.some((answerUid) => selectedAnswers[answerUid]);
          });

        if (question.QuestionId !== questionUid) {
          return {
            ...question,
            QuestionSections: question.QuestionSections.map((section) => ({
              ...section,
              Questions: section.Questions.map((answer) => {
                const newAnswer = !visibleQuestions[question.QuestionId]
                  ? {
                      ...answer,
                      TextBoxValue: '',
                      IsSelected: false
                    }
                  : answer;

                if (newAnswer.IsSelected || !!newAnswer.TextBoxValue) {
                  selectedAnswers[newAnswer.AnswerId] = true;
                }

                return newAnswer;
              })
            }))
          };
        }

        return {
          ...question,
          QuestionSections: question.QuestionSections.map((questionSection) =>
            questionSection.Order !== questionSectionOrder
              ? questionSection
              : {
                  ...questionSection,
                  Questions: questionSection.Questions.map((answer) => {
                    const newAnswer = {
                      ...answer
                    };

                    if (questionSection.QuestionType === TemplateTypes.SingleSelect) {
                      newAnswer.IsSelected = answer.AnswerId === answerUid;
                    }

                    if (questionSection.QuestionType === TemplateTypes.TextField) {
                      newAnswer.TextBoxValue = TextBoxValue as string;
                    }

                    if (newAnswer.IsSelected || !!TextBoxValue?.trim()) {
                      selectedAnswers[newAnswer.AnswerId] = true;
                    }

                    return newAnswer;
                  })
                }
          )
        };
      });

      const newQuestionAnswerDetails = questionnaire.QuestionAnswerDetails.map((item) => {
        const question = newQuestions.find((question) => question.QuestionId === item.QuestionId);
        const isActivated = question?.ActivationAnswerOptionUids.every((item) => {
          return item.AnswerOptionUids.some((answerUid) => selectedAnswers[answerUid]);
        });

        const IsAnswered =
          question && question.ActivationAnswerOptionUids.length > 0 && !isActivated
            ? true
            : (question?.QuestionSections.every((questionSection) =>
                questionSection.Questions.some((answer) => {
                  if (questionSection.QuestionType === TemplateTypes.SingleSelect) {
                    return answer.IsSelected;
                  }

                  if (questionSection.QuestionType === TemplateTypes.TextField) {
                    return !!answer.TextBoxValue?.trim();
                  }
                })
              ) as boolean);

        if (!IsAnswered) {
          isStepAnswered = false;
        }

        return {
          ...item,
          IsAnswered
        };
      });

      const newSteppers = questionnaire.Steppers.map((stepper) => {
        if (stepper.Order === questionnaire.ActualStepNumber) {
          stepper.IsSkipped = false;

          if (stepper.IsAnswered !== isStepAnswered) {
            stepper.IsAnswered = isStepAnswered;
          }
        }

        return stepper;
      });

      const newQuestionnaire = {
        ...questionnaire,
        QuestionAnswerDetails: newQuestionAnswerDetails,
        Question: {
          ...questionnaire.Question,
          QuestionGroups: newQuestions
        },
        Steppers: newSteppers
      };

      return {
        ...state,
        questionnaire: newQuestionnaire,
        questionnaireNotSaved: true
      };
    }

    default: {
      return state;
    }
  }
};
