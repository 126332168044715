import React, { FC, ReactNode } from 'react';
import cs from 'classnames';

import './ThemedFieldLabel.scss';

interface Props {
  text?: string;
  isRequired?: boolean;
  htmlFor?: string;
  requiredIcon?: ReactNode;
  infoIcon?: ReactNode;
}

const ThemedFieldLabel: FC<Props> = ({ text, isRequired, htmlFor, requiredIcon, infoIcon }) => {
  if (!text) return null;
  return (
    <label
      className={cs('field-label', {
        'field-label--required': isRequired && !requiredIcon
      })}
      htmlFor={htmlFor}
    >
      {text}
      {requiredIcon}
      {infoIcon}
    </label>
  );
};

export default ThemedFieldLabel;
