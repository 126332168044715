export const GET_WORKFLOW_REPORTS = 'GET_WORKFLOW_REPORTS';
export const GET_WORKFLOW_QUESTIONS = 'GET_WORKFLOW_QUESTIONS';
export const SET_CURRENT_QUESTION = 'SET_CURRENT_QUESTION';
export const GO_NEXT_AND_PREVIOUS_QUESTION = 'GO_NEXT_AND_PREVIOUS_QUESTION';
export const ON_ANSWER_CHANGE = 'ON_ANSWER_CHANGE';
export const RE_SET_REDUCER = 'RE_SET_REDUCER';
export const GOTO_STEP = 'GOTO_STEP';

export type state = Readonly<{
  steppers: steppers | Array<[]>;
  Question: questions | Array<[]>;
  currentQus: questions | Array<[]>;
  questionInfo: Array<[]>;
  enableSave: boolean;
  unsavedStep: boolean;
}>;

export type workFlowReports = Readonly<
  Array<{
    Order?: number;
    Questions: Array<questions>;
  }>
>;

export type questions = Readonly<
  Array<{
    Order: number;
    SerialNumber: number;
    QuestionText: string;
    QuestionType?: number;
    IsMultipleWorkFlowQuestion?: boolean;
    QuestionSections: Array<QuestionSections>;
  }>
>;

export type QuestionSections = Readonly<
  Array<{
    Description: string;
    IsCheckBoxRequired: boolean;
    IsTextBoxRequired: boolean;
    QuestionHeader: {
      Title: string;
      QuestionOptions: Array<[]>;
    };
    TextBoxText?: boolean | null;
    CheckBoxText?: boolean | null;
    QuestionOptions?: Array<{
      Text: string;
      OptionId: number | null;
      Order: number;
      TextBoxPlaceholderText: string;
    }>;
    RowHeaders?: Array<[]>;
    ColumnHeaders?: Array<[]>;
  }>
>;

export type steppers = Readonly<
  Array<{
    Order: number;
    SerialNumber: number;
    Title: string;
  }>
>;
