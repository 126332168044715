import { Reducer } from 'redux';
import { checkIsTopicDisabled } from './questionnaire-result.helpers';
import { QuestionnaireResultStateActionT, QuestionnaireResultStateI } from './questionnaire-result.interface';
import {
  SET_QUESTIONNAIRE_RESULT,
  SET_LOADING,
  CLEAR_STATE,
  SET_PHOTO,
  SELECT_TOPIC
} from './questionnaire-result.types';

const initialState: QuestionnaireResultStateI = {
  data: null,
  selectedTopic: 0,
  photo: {},
  loading: true
};

export const questionnaireResultReducer: Reducer<QuestionnaireResultStateI, QuestionnaireResultStateActionT> = (
  state = initialState,
  action
): QuestionnaireResultStateI => {
  switch (action.type) {
    case SET_QUESTIONNAIRE_RESULT: {
      return {
        ...state,
        data: {
          ...action.payload,
          QuestionnaireResult: action.payload.Topics.length ? action.payload.QuestionnaireResult : null
        },
        selectedTopic: action.payload.Topics.find((item) => !checkIsTopicDisabled(item))?.OrderNumber || 0
      };
    }

    case SET_LOADING: {
      return {
        ...state,
        loading: action.payload
      };
    }

    case SET_PHOTO: {
      return {
        ...state,
        photo: {
          ...state.photo,
          ...action.payload
        }
      };
    }

    case SELECT_TOPIC: {
      return {
        ...state,
        selectedTopic: action.payload
      };
    }

    case CLEAR_STATE: {
      return initialState;
    }

    default: {
      return state;
    }
  }
};
