import React from 'react';

const DropdownIcon = () => {
  return (
    <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.853553 0.646447C0.658291 0.451185 0.341709 0.451185 0.146447 0.646447C-0.0488155 0.841709 -0.0488155 1.15829 0.146447 1.35355L4.64645 5.85355C4.84171 6.04882 5.15829 6.04882 5.35355 5.85355L9.85355 1.35355C10.0488 1.15829 10.0488 0.841709 9.85355 0.646447C9.65829 0.451185 9.34171 0.451185 9.14645 0.646447L5 4.79289L0.853553 0.646447Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default DropdownIcon;
