import { ACTION_TYPES, languageStorage, ActiveLanguageStoreType, StoreName } from '../store';
import * as Models from '../../shared.models';

const initialState: Models.BM.ActiveLanguage = JSON.parse(
  languageStorage.getItem(StoreName.CurrentLanguage) || JSON.stringify(Models.BM.ActiveLanguage.setActiveLanguage())
);

export default function activeLanguageReducer(
  state: Models.BM.ActiveLanguage = initialState,
  action: ActiveLanguageStoreType
): Models.BM.ActiveLanguage {
  let result = {};

  switch (action.type) {
    case ACTION_TYPES.ACTIVE_LANGUAGE:
      result = {
        ...state,
        ...action.activeLanguage
      };
      break;
    default:
      result = state;
  }

  if (action.type === ACTION_TYPES.ACTIVE_LANGUAGE) {
    languageStorage.setItem(StoreName.CurrentLanguage, JSON.stringify(result));
  }

  return result;
}
