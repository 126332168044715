import { Reducer } from 'redux';
import {
  casetypeDashboardState,
  GET_CASES,
  COUNTRY_STATUS,
  SET_CASETYPE,
  SET_COUNTRYLIST,
  SET_UPDATEDBY,
  APPLY_SORTING,
  APPLY_PAGINATION
} from './casetype-dashboard.types';

const initialState: casetypeDashboardState = {
  cases: {
    CaseTypeDetails: [],
    TotalRecords: 0
  },
  currentCountry: {
    CurrentCountry: []
  },
  typeAhead: {
    caseType: [],
    updatedBy: [],
    countries: []
  },
  payLoad: {
    pageDetail: {
      pageNo: 1,
      pageSize: 10
    },
    filterDetails: {
      caseTypeName: [],
      lastUpdatedBy: [],
      country: [],
      status: [],
      CaseTypeCategories: [],
      lastUpdatedDate: new Date(),
      lastPublishedDate: new Date()
    },
    sortDetails: {
      sortColumn: 'LastModified',
      sortDirection: 'desc'
    }
  }
};

export const casetypeDashboardReducer: Reducer<any, any> = (
  state: casetypeDashboardState = initialState,
  action: any
) => {
  switch (action.type) {
    case GET_CASES: {
      const cases = { ...state.cases, CaseTypeDetails: action.data.Data, TotalRecords: action.data.TotalRecords };

      return { ...state, cases };
    }

    case COUNTRY_STATUS: {
      const currentCountry = { ...state.currentCountry, CurrentCountry: action.payload };

      return {
        ...state,
        currentCountry
      };
    }

    case SET_CASETYPE: {
      return { ...state, typeAhead: { ...state.typeAhead, caseType: action.data } };
    }

    case SET_COUNTRYLIST: {
      return { ...state, typeAhead: { ...state.typeAhead, countries: action.data } };
    }
    case SET_UPDATEDBY: {
      return { ...state, typeAhead: { ...state.typeAhead, updatedBy: action.data } };
    }

    case APPLY_SORTING: {
      return {
        ...state,
        payLoad: {
          ...state.payLoad,
          sortDetails: {
            sortColumn: action.data.sortColumn,
            sortDirection:
              action.data.sortDirection || state.payLoad.sortDetails.sortDirection === 'desc' ? 'asc' : 'desc'
          }
        }
      };
    }

    case APPLY_PAGINATION: {
      return {
        ...state,
        payLoad: {
          ...state.payLoad,
          pageDetail: {
            pageNo: action.data.pageNo,
            pageSize: action.data.pageSize
          }
        }
      };
    }

    default:
      return state;
  }
};
