import classNames from 'classnames';
import { FC } from 'react';
import { useSelectAssetFullName } from '../../shared/store/app-core/app-core.hooks';
import './header.scss';

const Header: FC<{ className?: string }> = ({ className }) => {
  const assetFullName = useSelectAssetFullName();
  return (
    <header className={classNames('ipo-scanner__header', className)}>
      <img
        className="ipo-scanner__header__deloitte_logo"
        src="./images/Deloitte-Logo-For_IPO.svg"
        alt="Deloitte Logo"
      />
      {assetFullName ? (
        <>
          <div className="ipo-scanner__header__divider" />
          <span className="ipo-scanner__header__ipo_scanner_logo">{assetFullName}</span>
        </>
      ) : null}
    </header>
  );
};

export default Header;
