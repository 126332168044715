import { SET_IS_ADMIN_TOOL } from './is-admin-tool.types';

export const setIsAdminToolAC = (payload: boolean) => {
  return {
    type: SET_IS_ADMIN_TOOL,
    payload
  } as const;
};

export type SetIsAdminToolACReturnType = ReturnType<typeof setIsAdminToolAC>;
