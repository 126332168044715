import { CLEAR_SAP_REPORT_DATA, GET_SAP_REPORT_DATA, sapReportState } from './SapReport.types';
import { Reducer } from 'redux';

const initialState: sapReportState = {
  sapReportData: []
};

export const sapReportReducer: Reducer<any, any> = (state: sapReportState = initialState, action: any) => {
  switch (action.type) {
    case GET_SAP_REPORT_DATA:
      return {
        ...state,
        sapReportData: action.data
      };

    case CLEAR_SAP_REPORT_DATA:
      return initialState;

    default:
      return state;
  }
};
