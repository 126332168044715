import axios, { AxiosPromise } from 'axios';
import ApiUrlsService from '../../services/bl/api-urls.service';
import * as Models from '../../shared.models';

export default class UserDataService {
  static getUserProfile(url: string): AxiosPromise<Models.DM.UserGetDto> {
    return axios.get(url);
  }

  static logout = async () => {
    try {
      await axios.post(ApiUrlsService.logout());
    } catch {}
  };
}
