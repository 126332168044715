const browserInfoKey = 'browserInfo';

export default class CommonService {
  static LikeSomeInList(list: Array<string>, subj: string): boolean {
    return list.some((listItem: string) => subj.indexOf(listItem) > -1);
  }

  static getBrowserInfo(): any {
    return JSON.parse(window.localStorage.getItem(browserInfoKey) || '{}');
  }

  static setBrowserInfo(shouldShow: boolean): void {
    window.localStorage.setItem(browserInfoKey, JSON.stringify({ shouldShow }));
  }

  static showUnsupportedBrowserBlock(): boolean {
    const unsupportedBrowsersList: Array<string> = ['Internet Explorer', 'Edge'];
    const usrAg = window.navigator.userAgent;

    const browserInfo = this.getBrowserInfo();

    if (!('shouldShow' in browserInfo)) {
      for (const browser of unsupportedBrowsersList) {
        if (usrAg.indexOf(browser) > -1) {
          this.setBrowserInfo(true);

          return true;
        }
      }
    } else {
      return browserInfo.shouldShow;
    }

    return false;
  }
}
