import { FC } from 'react';
import { CommonIconPropsI } from './common-icon-props.type';

type PathSize = 'sm' | 'md';

const pathSizeMap: Record<PathSize, string> = {
  sm: 'M8.08859 8.21569L8.14645 8.14645C8.32001 7.97288 8.58944 7.9536 8.78431 8.08859L8.85355 8.14645L12 11.293L15.1464 8.14645C15.32 7.97288 15.5894 7.9536 15.7843 8.08859L15.8536 8.14645C16.0271 8.32001 16.0464 8.58944 15.9114 8.78431L15.8536 8.85355L12.707 12L15.8536 15.1464C16.0271 15.32 16.0464 15.5894 15.9114 15.7843L15.8536 15.8536C15.68 16.0271 15.4106 16.0464 15.2157 15.9114L15.1464 15.8536L12 12.707L8.85355 15.8536C8.67999 16.0271 8.41056 16.0464 8.21569 15.9114L8.14645 15.8536C7.97288 15.68 7.9536 15.4106 8.08859 15.2157L8.14645 15.1464L11.293 12L8.14645 8.85355C7.97288 8.67999 7.9536 8.41056 8.08859 8.21569Z',
  md: 'M6.08859 6.21569L6.14645 6.14645C6.32001 5.97288 6.58944 5.9536 6.78431 6.08859L6.85355 6.14645L12 11.293L17.1464 6.14645C17.32 5.97288 17.5894 5.9536 17.7843 6.08859L17.8536 6.14645C18.0271 6.32001 18.0464 6.58944 17.9114 6.78431L17.8536 6.85355L12.707 12L17.8536 17.1464C18.0271 17.32 18.0464 17.5894 17.9114 17.7843L17.8536 17.8536C17.68 18.0271 17.4106 18.0464 17.2157 17.9114L17.1464 17.8536L12 12.707L6.85355 17.8536C6.67999 18.0271 6.41056 18.0464 6.21569 17.9114L6.14645 17.8536C5.97288 17.68 5.9536 17.4106 6.08859 17.2157L6.14645 17.1464L11.293 12L6.14645 6.85355C5.97288 6.67999 5.9536 6.41056 6.08859 6.21569Z'
};

export const CrossIcon: FC<CommonIconPropsI & { pathSize?: 'sm' | 'md' }> = ({
  size = 24,
  pathSize = 'sm',
  ...props
}) => {
  return (
    <svg {...props} width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d={pathSizeMap[pathSize]} fill="currentColor" />
    </svg>
  );
};
