import { Reducer } from 'redux';
import {
  ManageVisibilityState,
  SET_VISIBILITY_QUESTIONNAIRE,
  RE_SET_REDUCER,
  UPDATE_CHECKED_STATE,
  RE_SET_CHECKEDSTATE
} from './ManageVisibility.types';
const initialState: any = {
  Nodes: [],
  Checked: [],
  Expanded: [],
  Stepper: [],
  VisibilityCount: {
    HiddenQuestionnaires: 0,
    HiddenSteps: 0,
    HiddenQuestions: 0
  },
  PreviousCheckedState: [],
  CurrentModuleUID: ''
};

export const ManageVisibilityReducer: Reducer<any, any> = (state: any = initialState, action: any) => {
  switch (action.type) {
    case SET_VISIBILITY_QUESTIONNAIRE:
      const { Nodes, Checked, Expanded, VisibilityCount, Stepper, CurrentModuleUID } = action.data;

      Nodes.forEach((questionnaire: any) => {
        questionnaire.children.forEach((step: any) => {
          step.children.forEach((question: any) => {
            question.children = null;
          });
        });
      });

      return {
        ...state,
        Nodes,
        Checked,
        Expanded,
        Stepper,
        VisibilityCount: {
          ...state.VisibilityCount,
          HiddenQuestionnaires: VisibilityCount.HiddenQuestionnaires,
          HiddenSteps: VisibilityCount.HiddenSteps,
          HiddenQuestions: VisibilityCount.HiddenQuestions
        },
        PreviousCheckedState: Checked,
        CurrentModuleUID
      };
    case RE_SET_REDUCER:
      return { ...initialState };
    case UPDATE_CHECKED_STATE:
      const val = action.checked;

      return {
        ...state,
        Checked: val
      };
    case RE_SET_CHECKEDSTATE:
      return {
        ...state,
        Checked: state.PreviousCheckedState
      };
    default:
      return state;
  }
};
