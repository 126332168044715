export const GET_CASES = 'GET_CASES';
export const COUNTRY_STATUS = 'COUNTRY_STATUS';
export const SET_ISLOADING = 'SET_ISLOADING';
export const SET_CASETYPE = 'SET_CLIENTNAME';
export const SET_COUNTRYLIST = 'SET_COUNTRYLIST';
export const APPLY_SORTING = 'APPLY_SORTING';
export const SET_UPDATEDBY = 'SET_UPDATEDBY';
export const APPLY_PAGINATION = 'APPLY_PAGINATION';

export type dashBoardFilter = Readonly<{
  caseTypeName: Array<string>;
  lastUpdatedBy: Array<string>;
  country: Array<string>;
  status: Array<string>;
  CaseTypeCategories: string[];
  lastUpdatedDate: Date;
  lastPublishedDate: Date;
}>;

export type sortCol = Readonly<{
  sortColumn: string;
  sortDirection: string;
}>;

export type CaseTypeDetails = Readonly<{
  CaseTypeDetails: Array<string>;
  TotalRecords: number;
}>;
export type CurrentCountry = Readonly<{
  CurrentCountry: Array<string>;
}>;
export type typeAhead = Readonly<{
  caseType: Array<string>;
  countries: Array<string>;
  updatedBy: Array<string>;
}>;

export type pageInfo = Readonly<{
  pageNo: number;
  pageSize: number;
}>;

export type payLoad = Readonly<{
  filterDetails: dashBoardFilter;
  sortDetails: sortCol;
  pageDetail: pageInfo;
}>;

export type casetypeDashboardState = Readonly<{
  cases: CaseTypeDetails;
  currentCountry: CurrentCountry;
  typeAhead: typeAhead;
  payLoad: payLoad;
}>;

export type CaseTypeDashboardKey =
  | 'Name'
  | 'CaseTypeCategoryId'
  | 'Countries'
  | 'ContentAdmins'
  | 'Status'
  | 'LastModified'
  | 'FullName';
