export type ValidatorT = (data: { value: string; label: string; requiredLabel?: string }) => string;

export const removeTagsAndAttributes = (value: string) => value.replace(/(<([^>]+)>)/g, '');

export const htmlInjectionValidator: ValidatorT = ({ value, label }) => {
  const sanitizeValue = removeTagsAndAttributes(value);
  if (value !== sanitizeValue) return getInvalidMsg(label);

  return '';
};

export const getInvalidMsg = (label: string) => `Please enter a valid ${label.toLowerCase()}`;

export const lengthValidator = (value: string, length: number) =>
  value.length > length ? 'Max characters number is exceeded' : '';

export const requiredValidator: ValidatorT = ({ value, label }) =>
  !value.trim() ? `${label || 'This field'} is required` : '';

export const uniqValidator = ({ value, allValues, label }: { value: string; allValues: string[]; label?: string }) => {
  if (allValues.findIndex((v) => v.trim().toLowerCase() === value.trim().toLowerCase()) >= 0) {
    return label || 'Value is not unique';
  }

  return '';
};

export const emailValidator: ValidatorT = ({ value, label, requiredLabel }) => {
  const error = requiredValidator({ value, label: requiredLabel || label });
  if (error) return error;

  const emailRegex = /^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  if (!emailRegex.test(value)) return getInvalidMsg(label);

  return '';
};

export const linkValidator: ValidatorT = ({ value }) => {
  const linkRegex =
    /^(https:\/\/www\.|http:\/\/www\.|https:\/\/www2\.|http:\/\/www2\.|https:\/\/|http:\/\/)+([a-zA-Z0-9\-\._\~]{2,})+(\.[a-zA-Z0-9\-\._\~]{2,})(\/[a-zA-Z0-9\-\._\~\?\&\=\[\]\#\@\:\%\=\,\:\;\'\(\)\+]{2,})*\/?$/;
  if (!linkRegex.test(value)) return 'Entered value is not an URL';

  return '';
};

export const phoneValidator: ValidatorT = ({ value, label, requiredLabel }) => {
  const error = requiredValidator({ value, label: requiredLabel || label });
  if (error) return error;

  const phoneRegex = /^(\+)?(\d[\s-]?){6,14}\d$/;

  if (!phoneRegex.test(value)) return getInvalidMsg(label);

  return '';
};
